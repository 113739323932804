import React, { useContext, useEffect, useMemo, useState } from "react";
import styles from "./Subject.module.scss";
import Text from "../../UI/atoms/Text";
import { ReactComponent as Dropdown } from "../../../assets/icons/dropdown.svg";
import { ReactComponent as CardDecor1 } from "../../../assets/icons/subjectCardDecor1.svg";
import { ReactComponent as CardDecor2 } from "../../../assets/icons/subjectCardDecor2.svg";
import { ReactComponent as CardDecor3 } from "../../../assets/icons/subjectCard3.svg";
import { ReactComponent as Icon1 } from "../../../assets/icons/subjectCardIcon1.svg";
import { ReactComponent as Icon2 } from "../../../assets/icons/subjectCardIcon2.svg";
import { ReactComponent as Icon3 } from "../../../assets/icons/subjectCardIcon3.svg";
import EnglishIcon from "../../../assets/icons/englishIcon.svg";
import MathIcon from "../../../assets/icons/mathIcon.svg";
import ScienceIcon from "../../../assets/icons/scienceIcon.svg";
import { StringHelper } from "../../../utils/stringHelper";
import { useGetNumberOfTopicsQuery } from "../../../redux/features/lessons/lessonsApiSlice";
import { useGetProfilesQuery } from "../../../redux/features/profile/profileApiSlice";
import { useNavigate } from "react-router-dom";
import { useGetUserQuery } from "../../../redux/features/user/userApiSlice";
import {
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import LoaderContext from "../../../context/loader.context";
import { grades, subjects } from "../../../constants/values";
import Tag from "../../UI/atoms/Tag";
import Button from "../../UI/atoms/Button";
import Star from "../../../assets/icons/Star";
import ViewTopics from "../ViewTopics";

function Subject() {
  const { data: user } = useGetUserQuery(undefined);

  const [subjectId, setSubjectId] = useState<number>(1);

  const { setLoader: setLoading } = useContext(LoaderContext);

  const navigate = useNavigate();

  const [gradeValue, setGradeValue] = useState<{
    value: number;
    label: string;
  }>({ value: 1, label: "Primary 1" });

  const { data: profiles } = useGetProfilesQuery(undefined);

  const { data: topicsNum, isLoading: isTopicsNumLoading } =
    useGetNumberOfTopicsQuery({
      subjectId: subjectId,
      grade: !!user
        ? StringHelper.gradeNumberToString(Number(gradeValue.value))
        : "first",
    });

  useEffect(() => {
    setLoading(isTopicsNumLoading);

    return () => {
      setLoading(false);
    };
  }, [isTopicsNumLoading]);

  console.log(topicsNum);

  const {
    isOpen: isProfileLimitModalOpen,
    onOpen: onProfileLimitOpen,
    onClose: onProfileLimitClose,
  } = useDisclosure();

  const {
    isOpen: isGradeOpen,
    onOpen: onGradeOpen,
    onClose: onGradeClose,
  } = useDisclosure();
  const {
    isOpen: isSubjectsOpen,
    onOpen: onSubjectsOpen,
    onClose: onSubjectsClose,
  } = useDisclosure();

  const {
    isOpen: isViewTopicsOpen,
    onOpen: onViewTopicsOpen,
    onClose: onViewTopicsClose,
  } = useDisclosure();

  let subjectIcon;

  const subjectName = useMemo(
    () =>
      subjects.find((subject) => subject.id === Number(subjectId))?.name ||
      "english",
    [subjectId]
  );

  switch (subjectName) {
    case "english":
      subjectIcon = <img src={EnglishIcon} alt="" />;
      break;
    case "mathematics":
      subjectIcon = <img src={MathIcon} alt="" />;
      break;
    case "science":
      subjectIcon = <img src={ScienceIcon} alt="" />;
      break;
    default:
      subjectIcon = <img src={EnglishIcon} alt="" />;
  }
  const handleCreateProfile = () => {
    if (!!profiles) {
      if (profiles?.length < 3) {
        navigate("/app/guardian/create-profile");
      } else {
        onProfileLimitOpen();
      }
    }
  };

  return (
    <>
      <div className={styles.Subject}>
        <div className={styles.SubjectHeader}>
          <div onClick={onSubjectsOpen} className={styles.HeaderWrapper}>
            {subjectIcon}
            <Text
              variant="heading 3"
              fontWeight="light"
              style={{ textTransform: "capitalize" }}
            >
              {subjectName}
            </Text>
            <Dropdown />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "2px",
              cursor: "pointer",
              width: "100px",
            }}
            onClick={onGradeOpen}
          >
            <Text
              style={{
                fontSize: "12px",
                fontWeight: 500,
                textAlign: "center",
              }}
            >
              {gradeValue.label}
            </Text>
            <Dropdown />
          </div>
        </div>
        <div className={styles.Content}>
          <Text>This Course Incudes</Text>
          <div className={styles.ContentCard}>
            <CardDecor1 id={styles.cardDecor1} />
            <CardDecor2 id={styles.cardDecor2} />
            <CardDecor3 id={styles.cardDecor3} />
            <div className={styles.ContentItem}>
              <Icon1 />
              <div className={styles.ContentTextWrapper}>
                <Text fontWeight="medium" style={{ marginBottom: "4px" }}>
                  {StringHelper.pluralize(
                    topicsNum?.data.number_of_lessons || 0,
                    "Topic"
                  )}
                </Text>
                <Text variant="caption">
                  Unlock a wealth of learning through our extensive lesson
                  library
                </Text>
              </div>
            </div>
            <div className={styles.ContentItem}>
              <Icon2 />
              <div className={styles.ContentTextWrapper}>
                <Text fontWeight="medium" style={{ marginBottom: "4px" }}>
                  {StringHelper.pluralize(
                    topicsNum?.data.number_of_topics || 0,
                    "Lesson"
                  )}
                </Text>
                <Text variant="caption">
                  Access a range of educational videos to deepen your
                  child&apos;s understanding
                </Text>
              </div>
            </div>
            <div className={styles.ContentItem}>
              <Icon3 />
              <div className={styles.ContentTextWrapper}>
                <Text fontWeight="medium" style={{ marginBottom: "4px" }}>
                  After Lesson Quiz
                </Text>
                <Text variant="caption">
                  Knowledge-check quizzes after each lesson. to reinforce
                  concepts.
                </Text>
              </div>
            </div>
          </div>
        </div>
        <Button
          buttonStyles={{ marginTop: "50px" }}
          disabled={!profiles}
          onClick={onViewTopicsOpen}
        >
          View Topics
        </Button>
      </div>
      <Modal isOpen={isProfileLimitModalOpen} onClose={onProfileLimitClose}>
        <ModalOverlay />
        <ModalContent borderRadius="0px" marginLeft="20px" marginRight="20px">
          <div className={styles.ProfileLimitModal}>
            <Text style={{ textAlign: "center" }}>
              Your profile creation limit has been reached.
            </Text>
          </div>
        </ModalContent>
      </Modal>
      <Modal isOpen={isGradeOpen} onClose={onGradeClose}>
        <ModalOverlay />
        <ModalContent
          style={{ maxWidth: "500px", marginLeft: "30px", marginRight: "30px" }}
        >
          <div className={styles.GradeWrapper}>
            {grades.map((grade, index) => (
              <button
                onClick={() => {
                  setGradeValue({ value: grade.value, label: grade.label });
                  onGradeClose();
                }}
                key={index}
                className={styles.GradeItem}
              >
                <Star color={grade.color} />
                <Text>{grade.label}</Text>
              </button>
            ))}
          </div>
        </ModalContent>
      </Modal>
      <Modal isOpen={isSubjectsOpen} onClose={onSubjectsClose}>
        <ModalOverlay />
        <ModalContent
          style={{ maxWidth: "500px", marginLeft: "30px", marginRight: "30px" }}
        >
          <div className={styles.GradeWrapper}>
            {subjects.map((subject, index) => (
              <button
                onClick={() => {
                  setSubjectId(subject.id);
                  onSubjectsClose();
                }}
                key={index}
                className={styles.GradeItem}
              >
                <Text style={{ textTransform: "capitalize" }}>
                  {subject.name}
                </Text>
              </button>
            ))}
          </div>
        </ModalContent>
      </Modal>
      <Drawer
        isOpen={isViewTopicsOpen}
        onClose={onViewTopicsClose}
        placement="bottom"
        size="lg"
      >
        <DrawerOverlay />
        <DrawerContent>
          <ViewTopics
            info={{ subjectId, grade: gradeValue.value }}
            onClose={onViewTopicsClose}
          />
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default Subject;
