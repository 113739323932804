import React from "react";
import { colors } from "../../../sdk/colors";
import { Spinner } from "@chakra-ui/react";

const LoadingScreen = ({ animating }: { animating: boolean }) => {
  console.log(animating);
  return animating ? (
    <div
      style={{
        position: "fixed",
        display: "flex",
        flex: 1,
        backgroundColor: "#00000030",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100vh",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          padding: "10px 15px",
          backgroundColor: colors.primary,
          borderRadius: 10,
        }}
      >
        <Spinner color="#fff" />
      </div>
    </div>
  ) : null;
};

export default LoadingScreen;
