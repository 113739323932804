import React, { useEffect } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import styles from "./Dashboard.module.scss";
import Sidebar from "../../components/organisms/Sidebar";
import { useGetUserQuery } from "../../redux/features/user/userApiSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import { updateUserRole } from "../../redux/features/auth/authSlice";
import Decor1 from "../../assets/icons/dashboardDecor1.svg";
import Decor2 from "../../assets/icons/dashboardDecor2.svg";
import Decor3 from "../../assets/icons/dashboardDecor3.svg";
import Triangle1 from "../../assets/icons/triangle1.svg";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/react";
import { useNetworkState } from "react-use";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { mobileBreakPoint } from "../../constants/values";

function Dashboard() {
  const state = useNetworkState();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { width } = useWindowDimensions();
  const isMobileBreakpoint = width < mobileBreakPoint;

  const userRole = useAppSelector((state: RootState) => state.auth.userRole);
  const { data: user, error } = useGetUserQuery(undefined);

  const handleSwitchToGuardianFailSafe = () => {
    console.log("fail safe");
    if (user && user.role === "guardian") {
      console.log("fail safe", userRole, user.role);
      dispatch(updateUserRole("child"));
      window.location.reload();
    }
  };

  console.log(userRole, "dashboard user role", user);

  const videoRoutes =
    location.pathname === "/app/learn/downloads" ||
    location.pathname.includes("/app/learn/lessons");

  useEffect(() => {
    dispatch(updateUserRole("child"));
    handleSwitchToGuardianFailSafe();
  }, [location]);

  const isPoorConnection =
    state.effectiveType === "2g" ||
    state.effectiveType === "slow-2g" ||
    (state?.downlink && state?.downlink < 0.3);

  console.log(state);

  return (
    <div className={styles.Dashboard}>
      {(!state.online || isPoorConnection) && !videoRoutes && (
        <Alert
          status="error"
          variant="solid"
          position="fixed"
          bottom={isMobileBreakpoint ? 20 : 0}
          width="100%"
          zIndex={100}
        >
          <AlertIcon />
          <AlertTitle mr={2} style={{ textAlign: "start" }}>
            {state.online ? "Poor connection" : "You're offline"}
          </AlertTitle>
          <AlertDescription style={{ textAlign: "end" }}>
            <Link
              style={{ textDecorationLine: "underline" }}
              to="/app/learn/downloads"
            >
              Watch downloaded videos
            </Link>
          </AlertDescription>
        </Alert>
      )}
      <Sidebar />
      <section>
        <img src={Decor1} id={styles.decor1} />
        <img src={Decor2} id={styles.decor2} />
        <img src={Decor3} id={styles.decor3} />
        <div id={styles.ShapeItemDecoration} />
        <div id={styles.ShapeItemDecoration} />
        <div id={styles.ShapeItemDecoration} />
        <div id={styles.ShapeItemDecoration} />
        <div id={styles.ShapeItemDecoration} />
        <img src={Triangle1} id={styles.triangle1} />
        <div id={styles.ShapeItemDecoration} />
        <div className={styles.PageWrapper}>
          <Outlet />
        </div>
      </section>
    </div>
  );
}

export default Dashboard;
