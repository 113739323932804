import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useGetTopicQuery } from "../../redux/features/lessons/lessonsApiSlice";
import styles from "./Topic.module.scss";
import PageHeader from "../../components/UI/molecules/PageHeader";
import { ReactComponent as SortIcon } from "../../assets/icons/sortIcon.svg";
import Text from "../../components/UI/atoms/Text";
import { colors } from "../../sdk/colors";
import Tag from "../../components/UI/atoms/Tag";
import { StringHelper } from "../../utils/stringHelper";
import LessonItem from "../../components/UI/molecules/LessonItem";
import { Skeleton } from "@chakra-ui/react";

function Topic() {
  const { topicId } = useParams<{ topicId: string }>();
  const [searchParams] = useSearchParams();
  const { isLoading, data } = useGetTopicQuery(Number(topicId));
  console.log(data);
  return (
    <div className={styles.Topic}>
      <PageHeader pageTitle="" />
      <div className={styles.Container}>
        <div className={styles.TopicHeader}>
          <div className={styles.TextWrapper}>
            <Text color={colors.grey3} variant="heading 6">
              Topic
            </Text>
            <Text style={{textAlign: "left"}} fontWeight="light" variant="heading 3">
              {data?.data.name}
            </Text>
          </div>
          {data?.data?.topics && <Tag>{`${StringHelper.pluralize(
            Number(data?.data?.topics?.length),
            "video"
          )}`}</Tag>}
        </div>
        <div className={styles.Wrapper}>
          {data?.data.topics.map((lesson, index) => (
            <LessonItem subjectId={Number(searchParams.get("subjectId"))} key={lesson.id} lesson={lesson} index={index + 1} />
          ))}
          
        </div>
        {isLoading && (
            <>
              <Skeleton
                isLoaded={false}
                borderRadius="10px"
                className={styles.TopicSkeleton}
                startColor={colors.grey5}
                endColor={colors.grey4}
              />
              <Skeleton
                isLoaded={false}
                borderRadius="10px"
                className={styles.TopicSkeleton}
                startColor={colors.grey5}
                endColor={colors.grey4}
              />
              <Skeleton
                isLoaded={false}
                borderRadius="10px"
                className={styles.TopicSkeleton}
                startColor={colors.grey5}
                endColor={colors.grey4}
              />
              <Skeleton
                isLoaded={false}
                borderRadius="10px"
                className={styles.TopicSkeleton}
                startColor={colors.grey5}
                endColor={colors.grey4}
              />
              <Skeleton
                isLoaded={false}
                borderRadius="10px"
                className={styles.TopicSkeleton}
                startColor={colors.grey5}
                endColor={colors.grey4}
              />
              <Skeleton
                isLoaded={false}
                borderRadius="10px"
                className={styles.TopicSkeleton}
                startColor={colors.grey5}
                endColor={colors.grey4}
              />
            </>
          )}
      </div>
    </div>
  );
}

export default Topic;
