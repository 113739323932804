import React, {
  HTMLInputTypeAttribute,
} from "react";
import styles from "./Input.module.scss";

function Input({
  label,
  onChange,
  onBlur,
  value,
  error,
  placeholder,
  inputAdornment,
  name,
  type,
  id,
  onClickAdornment,
  variant = "default",
  disabled,
  onClick,
  defaultValue,
  readOnly = false,
  maxLength,
  className,
}: {
  label?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  value?: string;
  error?: boolean;
  placeholder?: string;
  inputAdornment?: JSX.Element;
  type?: HTMLInputTypeAttribute;
  name?: string;
  id?: string;
  onClickAdornment?: () => void;
  variant?: "default" | "auth";
  disabled?: boolean;
  onClick?: () => void;
  defaultValue?: string;
  readOnly?: boolean;
  maxLength?: number;
  className?: React.HTMLAttributes<HTMLInputElement>["className"];
}) {
  return (
    <div className={styles.InputContainer}>
      {label && <label htmlFor={name}>{label}</label>}
      <div
        onClick={onClick}
        className={`${styles.InputWrapper} ${error ? styles.Error : null}`}
      >
        <input
          className={className}
          readOnly={readOnly}
          onClick={onClick}
          defaultValue={defaultValue}
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          maxLength={maxLength}
          onChange={onChange}
          onBlur={onBlur}
          type={type}
          name={name}
          id={id}
          style={{
            fontSize: variant === "default" ? "14px" : "20px",
            padding: variant === "default" ? "20px 30px" : "29px 54px",
          }}
        />
        {inputAdornment && (
          <button disabled={disabled} type="button" onClick={onClickAdornment}>
            {inputAdornment}
          </button>
        )}
      </div>
    </div>
  );
}

export default Input;
