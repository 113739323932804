import { TopicsAnalytics } from "../../../models/analytics";
import { Lesson } from "../../../models/lessons";
import { apiSlice } from "../../apiSlice";

const analyticsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTopicsActivity: builder.query<{ data: TopicsAnalytics }, number>({
      query: (profileId) => ({
        url: "/analytics/lesson",
        method: "get",
        params: { profileId },
      }),
      providesTags: ["Analytics"],
    }),
    getLessonsActivity: builder.query<{data: Lesson[];}, number>({
      query: (profileId) => ({
        url: `/analytics/topics`,
        method: "get",
        params: { profileId },
      }),
      providesTags: ["Analytics"],
    }),
    getChampionshipActivity: builder.query<any, number>({
      query: (profileId) => ({
        url: "/analytics/championship",
        method: "get",
        params: { profileId },
      }),
      providesTags: ["Analytics"],
    }),
    getProfileActivity: builder.query<{ data: TopicsAnalytics }, number>({
      query: (profileId) => ({
        url: "/analytics/activity",
        method: "get",
        params: { profileId },
      }),
      providesTags: ["Analytics"],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetTopicsActivityQuery,
  useGetLessonsActivityQuery,
  useGetChampionshipActivityQuery,
  useGetProfileActivityQuery,
} = analyticsApiSlice;
