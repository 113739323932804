import React, { useEffect, useRef, useState } from "react";
import Text from "../../atoms/Text";
import styles from "./OTPInput.module.scss";
import { colors } from "../../../../sdk/colors";
import { NumericFormat } from "react-number-format";

const OTPInput = ({
  otpCode = "",
  setOTPCode,
  onCodeComplete,
  isAlphaNumeric = false,
}: {
  otpCode: string;
  setOTPCode: (value: string) => void;
  onCodeComplete?: () => void;
  isAlphaNumeric?: boolean;
}) => {
  const MAX_CODE_LENGTH: number = 6;
  const inputRef = useRef<HTMLInputElement>(null);
  const boxArray = new Array(MAX_CODE_LENGTH).fill(0);

  const [isInputBoxFocused, setIsInputBoxFocused] = useState(false);

  useEffect(() => {
    setIsPinReady(otpCode.length === MAX_CODE_LENGTH);
  }, [otpCode]);

  const handleOnPress = () => {
    setIsInputBoxFocused(true);
    inputRef?.current?.focus();
  };
  const handleOnBlur = () => {
    setIsInputBoxFocused(false);
  };
  const isCodeComplete = otpCode.length === MAX_CODE_LENGTH;

  useEffect(() => {
    if (isCodeComplete) {
      onCodeComplete && onCodeComplete();
    }
  }, [isCodeComplete]);

  const boxDigit = (_: any, index: number) => {
    const emptyInput = "";
    const digit = otpCode[index] || emptyInput;

    const isLastValue = index === MAX_CODE_LENGTH - 1;
    const isCurrentValue = index === otpCode.length;

    const isValueFocused = isCurrentValue || (isLastValue && isCodeComplete);

    return (
      <div
        className={styles.splitBoxes}
        style={{
          border:
            isValueFocused && isInputBoxFocused
              ? `1px solid ${colors.primary}`
              : `0.5px solid ${colors.primary}`,
        }}
        key={index}
      >
        <Text variant="body">{digit}</Text>
      </div>
    );
  };

  const [isPinReady, setIsPinReady] = useState<boolean>(false);

  return (
    <div className={styles.OTPInput}>
      <div className={styles.splitOTPBoxesContainer} onClick={handleOnPress}>
        {boxArray.map(boxDigit)}
      </div>
      {isAlphaNumeric ? (
        <input
          onChange={(e) => setOTPCode(e.target.value)}
          onBlur={handleOnBlur}
          maxLength={MAX_CODE_LENGTH}
          value={otpCode}
          className={styles.hiddenTextInput}
          ref={inputRef}
        />
      ) : (
        <NumericFormat
          inputMode="numeric"
          className={styles.hiddenTextInput}
          value={otpCode}
          onChange={(e) => setOTPCode(e.target.value)}
          maxLength={MAX_CODE_LENGTH}
          getInputRef={inputRef}
          onBlur={handleOnBlur}
        />
      )}
    </div>
  );
};

export default OTPInput;
