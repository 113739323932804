import { Profile } from "../models/profile";
import { SubPlans } from "../models/subscription";


export class ProfileHelper {
  static getProfileSubscriptionPlan(subStatus?: {
    plan: {
      amount: number;
      name: string;
      interval: string;
    };
  } | null): string {
    let subscriptionPlan: string = "Free Account";
    if (subStatus) {
      return subStatus.plan.name
    } else {
      subscriptionPlan = "Free Account";
    }
    return subscriptionPlan;
  }

  static getFullName(profile: Profile | undefined): string {
    return !!profile ? `${profile.firstName} ${profile.lastName}` : "";
  }
}
