import React from "react";
import styles from "./LessonLibraryItem.module.scss";
import { ReactComponent as EnglishIcon } from "../../../../assets/icons/bookOpenIcon.svg";
import { ReactComponent as MathIcon } from "../../../../assets/icons/bookStackIcon.svg";
import { ReactComponent as ScienceIcon } from "../../../../assets/icons/scienceThingyIcon.svg";
import { colors } from "../../../../sdk/colors";
import { Skeleton } from "@chakra-ui/react";

function LessonLibraryItem({
  subject,
  isLoading,
  onClick
}: {
  subject: "english" | "mathematics" | "science";
  isLoading: boolean;
  onClick?: () => void
}) {
  let itemColor: React.CSSProperties["color"];
  let itemName: string;
  let itemIcon: JSX.Element;

  switch (subject) {
    case "english":
      itemColor = colors.primary;
      itemName = subject;
      itemIcon = <EnglishIcon />;
      break;
    case "mathematics":
      itemColor = colors.math;
      itemName = subject;
      itemIcon = <MathIcon />;
      break;
    case "science":
      itemColor = colors.sci;
      itemName = subject;
      itemIcon = <ScienceIcon />;
      break;
    default:
      itemColor = colors.primary;
      itemName = subject;
      itemIcon = <EnglishIcon />;
  }

  return (
    <Skeleton
      startColor={colors.grey5}
      endColor={colors.grey4}
      borderRadius="9.382px"
      isLoaded={!isLoading}
    >
      <button
        onClick={onClick}
        style={{ backgroundColor: itemColor }}
        className={styles.LessonLibraryItem}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="22"
          viewBox="0 0 18 22"
          fill="none"
          style={{ position: "absolute", top: 0, left: 0 }}
        >
          <ellipse
            cx="5.49326"
            cy="10.3196"
            rx="11.7272"
            ry="11.2581"
            fill={"#00000020"}
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="23"
          viewBox="0 0 13 23"
          fill="none"
          style={{ position: "absolute", top: 50, right: 0 }}
        >
          <path
            d="M23.8843 14.8365C23.203 17.2648 21.6921 19.3844 19.5939 20.8556C17.4957 22.3268 14.9316 23.0643 12.3128 22.95C9.69401 22.8357 7.17213 21.8761 5.15148 20.225C3.13084 18.574 1.72846 16.3272 1.16918 13.8448C0.609907 11.3624 0.926123 8.78821 2.06714 6.5349C3.20815 4.28159 5.10787 2.47969 7.46174 1.41808C9.81561 0.356468 12.4873 0.096634 15.0485 0.680234C17.6096 1.26383 19.9119 2.65706 21.5863 4.63656L12.5931 11.6686L23.8843 14.8365Z"
            fill={"#ffffff80"}
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="22"
          viewBox="0 0 24 22"
          fill="none"
          style={{ position: "absolute", bottom: 0, left: 20 }}
        >
          <path
            d="M0.191874 10.3528C0.4095 7.84021 1.49964 5.4765 3.28751 3.64062C5.07539 1.80474 7.45745 0.603036 10.0518 0.228155C12.6462 -0.146728 15.3025 0.326929 17.5951 1.57319C19.8876 2.81945 21.6835 4.76613 22.6949 7.10114C23.7062 9.43614 23.8745 12.0242 23.1726 14.4505C22.4707 16.8767 20.9394 19.0006 18.8242 20.4816C16.7089 21.9626 14.1322 22.715 11.5072 22.6181C8.88216 22.5212 6.36084 21.5806 4.34746 19.9472L11.8753 11.3648L0.191874 10.3528Z"
            fill="#EEE8E7"
            fillOpacity="0.32"
          />
        </svg>
        {itemIcon}
        <p
          id={styles.text}
          style={{
            fontWeight: 600,
            color: colors.white,
            textTransform: "capitalize",
          }}
        >
          {itemName}
        </p>
      </button>
    </Skeleton>
  );
}

export default LessonLibraryItem;
