import React from "react";
import styles from "./DeleteYourData.module.scss";
import { Helmet } from "react-helmet";
import Header from "../../components/UI/molecules/LandingPageHeader";
import Footer from "../../components/UI/molecules/Footer";

const DeleteYourData: React.FC = () => {
  return (
    <div className={styles.Container}>
      <Helmet>
        <title>UBELA - Privacy Policy</title>
      </Helmet>
      <Header />
      <div className={styles.HeroSection}>
        <div className={styles.deleteYourData}>
          <h1>Delete Your Account</h1>
          <p>
            We're sorry to see you go! If you'd like to delete your account,
            follow these steps carefully. Please note that once your account is
            deleted, all associated data will be permanently removed.
          </p>

          <ol>
            <li>
              <h2>Step 1: Go to Guardian Dashboard</h2>
              <p>
                On either the mobile or web app, start by navigating to the
                Guardian Dashboard. You can get there from the "Choose Your
                Profile" screen, which is the first screen you see when logged
                in. Look for the "Continue to Guardian" button at the bottom of
                that screen and tap on it.
              </p>
              {/* Placeholder for image */}
              <div className={styles.imagePlaceholder}>[Image for Step 1]</div>
            </li>

            <li>
              <h2>Step 2: Open the Menu</h2>
              <p>
                Once in the Guardian Dashboard, open the menu:
                <ul>
                  <li>
                    On mobile: Tap your profile image located at the top right.
                  </li>
                  <li>On web: Tap the settings icon on the header.</li>
                </ul>
              </p>
              {/* Placeholder for image */}
              <div className={styles.imagePlaceholder}>[Image for Step 2]</div>
            </li>

            <li>
              <h2>Step 3: Edit Your Account</h2>
              <p>
                In the menu, tap the "Edit Account" button. This will take you
                to a screen where you can make changes to your account settings.
              </p>
              {/* Placeholder for image */}
              <div className={styles.imagePlaceholder}>[Image for Step 3]</div>
            </li>

            <li>
              <h2>Step 4: Delete Your Account</h2>
              <p>
                Scroll to the bottom of the Edit Account screen, and you'll find
                a "Delete Account" button. Tap this button, and a confirmation
                prompt will appear.
              </p>
              {/* Placeholder for image */}
              <div className={styles.imagePlaceholder}>[Image for Step 4]</div>
            </li>

            <li>
              <h2>Step 5: Confirm Deletion</h2>
              <p>
                Confirm that you want to delete your account by following the
                prompt. Once confirmed, your account will be permanently
                deleted.
              </p>
              {/* Placeholder for image */}
              <div className={styles.imagePlaceholder}>[Image for Step 5]</div>
            </li>
          </ol>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DeleteYourData;
