import React from "react";
import styles from "./AuthContainer.module.scss";
import logo from "../../assets/images/logo-primary.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as GoBackIcon } from "../../assets/icons/chevron-left.svg";

function AuthContainer({
  children,
  canGoBack = false,
}: {
  children: React.ReactNode;
  canGoBack?: boolean;
}) {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className={styles.AuthContainer}>
      <div className={styles.Wrapper}>
        <div className={styles.Header}>
          {location.key && canGoBack && (
            <button
              onClick={() => navigate(-1)}
              className={styles.GoBackButton}
            >
              <GoBackIcon />
            </button>
          )}
          <img src={logo} alt="Logo" />
        </div>
        {children}
      </div>
    </div>
  );
}

export default AuthContainer;
