import React from "react";

function Star({ color = "#636363" }: { color?: React.CSSProperties["color"] }) {
  return (
    <svg
      width="23"
      height="20"
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.58 8H0L6.17 12.41L3.83 20L10 15.31L16.18 20L13.83 12.41L20 8H12.42L10 0L7.58 8ZM20.36 20L18.5 13.99L22.68 11H19.24L16.16 13.2L17.62 17.92L20.36 20ZM16 6L14.18 0L13.14 3.45L13.91 6H16Z"
        fill={color}
      />
    </svg>
  );
}

export default Star;
