import React from 'react';
import styles from "./Footer.module.scss";
import Logo from "../../../../assets/images/LogoPrimary.svg";
import { Link } from 'react-router-dom';
import twitterIcon from "../../../../assets/images/twitter.svg";
import instagramIcon from "../../../../assets/images/instagram.svg";
import linkedinIcon from "../../../../assets/images/linkedin.svg";
import facebookIcon from "../../../../assets/images/facebook.svg";
import mobiletwitterIcon from "../../../../assets/images/mobiletwitter.svg";
import mobileinstagramIcon from "../../../../assets/images/mobileinstagram.svg";
import mobilelinkedinIcon from "../../../../assets/images/mobilelinkedin.svg";
import mobilefacebookIcon from "../../../../assets/images/mobilefacebook.svg";

function Footer() {
  return (
    <div className={styles.Footer}>
        <img src={Logo} alt="" id={styles.Logo} />
        <div className={styles.Wrapper}>
            <ul>
                <Link to="/privacy-policy"><li>Privacy Policy</li></Link>
                <Link to="/terms-of-use"><li>Terms of service</li></Link>
                <Link to="/faq"><li>Help/FAQ</li></Link>
            </ul>
            <div className={styles.Socials}>
                <a href=''><img src={twitterIcon} alt="" /></a>
                <a href=''><img src={instagramIcon} alt="" /></a>
                <a href=''><img src={linkedinIcon} alt="" /></a>
                <a href=''><img src={facebookIcon} alt="" /></a>
            </div>
            <div className={styles.MobileSocials}>
                <a href=''><img src={mobiletwitterIcon} alt="" /></a>
                <a href=''><img src={mobileinstagramIcon} alt="" /></a>
                <a href=''><img src={mobilelinkedinIcon} alt="" /></a>
                <a href=''><img src={mobilefacebookIcon} alt="" /></a>
            </div>
        </div>
    </div>
  )
}

export default Footer