import { useState, useEffect } from "react";
import { openDB } from "idb";
import { Question } from "../models/championship";

type Video = {
  id?: number;
  data: Blob;
  name: string;
  videoId: string;
  thumbnailUrl: string;
  description: string;
  quizzes: Question[];
  profileId: number;
  subjectId: number;
};

type FetchVideosResult = {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  videos: Video[];
};

const useFetchVideos = (): FetchVideosResult => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [videos, setVideos] = useState<Video[]>([]);

  useEffect(() => {
    let isMounted = true;

    const fetchVideos = async () => {
      setIsLoading(true);
      setIsError(false);
      setIsSuccess(false);

      try {
        const db = await openDB("video-download", 11, {
          upgrade(db) {
            if (!db.objectStoreNames.contains("videos")) {
              db.createObjectStore("videos", { keyPath: "videoId" });
            }
          },
        });

        const transaction = db.transaction("videos", "readonly");
        const store = transaction.objectStore("videos");
        const videos: Video[] = await store.getAll();

        if (isMounted) {
          setVideos(videos);
          setIsSuccess(true);
        }
      } catch (error) {
        if (isMounted) {
          setIsError(true);
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchVideos();

    return () => {
      isMounted = false;
    };
  }, []);

  return {
    isLoading,
    isError,
    isSuccess,
    videos,
  };
};

export default useFetchVideos;
