import {
  CreateProfileBody,
  GetProfilesResponse,
  Profile,
  SwitchProfileResponse,
  UpdateProfileAvatarBody,
} from "../../../models/profile";
import { apiSlice } from "../../apiSlice";

const profileApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProfiles: builder.query<GetProfilesResponse, undefined>({
      query: () => ({
        url: "/account/profiles",
        method: "get",
      }),
      providesTags: ["User", "Profile"],
    }),
    switchProfile: builder.mutation<SwitchProfileResponse, number>({
      query: (id) => ({
        url: `/account/profile/login/${id}`,
        method: "post",
      }),
      invalidatesTags: ["User", "Profile"],
    }),
    createProfile: builder.mutation<
      { message: string; data: Profile; success: boolean },
      CreateProfileBody
    >({
      query: (body) => ({
        url: `/profiles`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["User", "Profile"],
    }),
    updateProfileAvatar: builder.mutation<
      { message: string },
      { body: FormData; profileId: number }
    >({
      query: ({ body, profileId }) => ({
        url: `/account/profile/avatar`,
        method: "PUT",
        body: body,
        params: { profileId },
      }),
      invalidatesTags: ["User"],
    }),
    editProfile: builder.mutation<
      { message: string },
      { id: string; body: { firstName: string; lastName: string } }
    >({
      query: ({ id, body }) => ({
        url: `/profiles/${id}`,
        method: "PATCH",
        body: { ...body },
      }),
      invalidatesTags: ["Profile"],
    }),
    deleteProfile: builder.mutation<{ message: string }, string>({
      query: (id) => ({
        url: `/profiles/${id}`,
        method: "delete",
      }),
      invalidatesTags: ["Profile"],
    }),
    changeGrade: builder.mutation<
      { message: string },
      { profileId: number; grade: number }
    >({
      query: ({ profileId, grade }) => ({
        method: "POST",
        url: `/account/grade/change`,
        params: { profileId, grade },
      }),
    }),
  }),
});

export const {
  useGetProfilesQuery,
  useSwitchProfileMutation,
  useCreateProfileMutation,
  useUpdateProfileAvatarMutation,
  useEditProfileMutation,
  useDeleteProfileMutation,
  useChangeGradeMutation,
} = profileApiSlice;
