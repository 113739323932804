import React, { useContext, useEffect } from "react";
import styles from "./GuardianHeader.module.scss";
import { ReactComponent as Logo } from "../../../../assets/images/logo-primary.svg";
import { ReactComponent as SettingsIcon } from "../../../../assets/icons/settingsIcon.svg";
import { useGetUserQuery } from "../../../../redux/features/user/userApiSlice";
import { ReactComponent as DropdownIcon } from "../../../../assets/icons/dropdown.svg";
import Text from "../../atoms/Text";
import {
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import {
  useGetProfilesQuery,
  useSwitchProfileMutation,
} from "../../../../redux/features/profile/profileApiSlice";
import ProfileAvatar from "../../atoms/ProfileAvatar";
import { useNavigate } from "react-router-dom";
import LoaderContext from "../../../../context/loader.context";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  saveProfileId,
  setProfileTokens,
  updateUserRole,
} from "../../../../redux/features/auth/authSlice";
import Button from "../../atoms/Button";
import { handleSaveUser } from "../../../../redux/features/user/userSlice";

function GuardianHeader({ onClick }: { onClick: () => void }) {
  const { setLoader: setLoading } = useContext(LoaderContext);

  const dispatch = useAppDispatch();

  const savedName = useAppSelector((state) => state.user.name);
  const savedAvatar = useAppSelector((state) => state.user.avatar);

  const { data: user } = useGetUserQuery(undefined);

  const { data: profiles, isLoading: isProfilesLoading } =
    useGetProfilesQuery(undefined);

  const navigate = useNavigate();

  const [switchProfile] = useSwitchProfileMutation();

  const handleSwitchProfile = async (id: number) => {
    try {
      setLoading(true);
      onSwitchProfileClose();
      const response = await switchProfile(id).unwrap();
      setLoading(false);
      dispatch(setProfileTokens(response.token));
      dispatch(updateUserRole("child"));
      dispatch(saveProfileId(id));
      navigate("/app/learn", { replace: true });
      window.location.reload();
      console.log(response);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const {
    isOpen: isSwitchProfileOpen,
    onOpen: onSwitchProfileOpen,
    onClose: onSwitchProfileClose,
  } = useDisclosure();
  return (
    <>
      <div className={styles.GuardianHeader}>
        <button onClick={onClick}>
          <Logo />
        </button>
        <div style={{ flexDirection: "row", display: "flex", gap: "30px" }}>
          <button onClick={onClick}>
            <SettingsIcon />
          </button>
          <div className={styles.UserWrapper}>
            <button onClick={onSwitchProfileOpen} className={styles.User}>
              <img
                src={!!savedAvatar ? savedAvatar : user?.avatar?.url}
                alt=""
              />
              <Text variant="heading 4" fontWeight="light">
                {!!savedName ? savedName : user?.firstName}
              </Text>
            </button>
            <DropdownIcon />
          </div>
        </div>
      </div>
      <Modal isOpen={isSwitchProfileOpen} onClose={onSwitchProfileClose}>
        <ModalOverlay />
        <ModalContent borderRadius="0px" marginLeft="20px" marginRight="20px">
          <div className={styles.ManageProfileModal}>
            <Text>Select profile to switch</Text>
            <div className={styles.ProfilesAvatarsList}>
              {profiles && profiles.length > 0 ? (
                profiles?.map((profile, index) => (
                  <div
                    key={profile.id}
                    className={styles.ManageProfileModalItem}
                  >
                    <button
                      onClick={() => {
                        onSwitchProfileClose();
                        handleSwitchProfile(profile.id);
                      }}
                      className={styles.ManageProfileItemAvatarWrapper}
                    >
                      <ProfileAvatar
                        variant={index === 1 ? "curve" : "default"}
                        image={`${profile.avatar?.url}`}
                        isEdit
                        isLoading={isProfilesLoading}
                        index={index}
                      />
                    </button>
                    <Text
                      style={{
                        fontSize: 16,
                        width: "100px",
                        textAlign: "center",
                      }}
                    >{`${profile.firstName} ${profile.lastName}`}</Text>
                  </div>
                ))
              ) : (
                <div
                  style={{ flexDirection: "column" }}
                  className={styles.EmptyUnpaidProfilesMessage}
                >
                  <Text style={{ textAlign: "center" }}>
                    You have no profiles to manage.
                  </Text>
                  <Button
                    onClick={() => navigate("/app/guardian/create-profile")}
                    buttonStyles={{ marginTop: "20px" }}
                  >
                    Create Profile
                  </Button>
                </div>
              )}
            </div>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
}

export default GuardianHeader;
