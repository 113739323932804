import { Lesson } from "../models/lessons";
import { StoredProfile } from "../models/profile";

type Items = { data: any[]; hasNextPage: boolean; count: number };

/**
 * Updates the current cache with new items, ensuring no duplicates.
 * @param currentItems - The current cache of items.
 * @param newItems - The new items to be added to the cache.
 * @returns The updated cache with merged items.
 */
export function updateCache(currentItems: Items, newItems: Items): Items {
  const existingIds = new Set(
    currentItems.data.map((item) => item?.id ?? item.id)
  );

  newItems.data.forEach((newItem) => {
    const newItemId = newItem?.id ?? newItem.id;
    if (!existingIds.has(newItemId)) {
      currentItems.data.push(newItem);
      existingIds.add(newItemId);
    }
  });

  currentItems.hasNextPage = newItems.hasNextPage;
  currentItems.count = newItems.count;

  return currentItems;
}

export function addItemIfNotExists(currentItems: any[], newItem: any): any[] {
  const itemMap: { [lessonId: string]: any } = {};
  for (const item of currentItems) {
    itemMap[item.lessonId] = item;
  }

  itemMap[newItem.lessonId] = newItem;

  return Object.values(itemMap);
}

export function removeItemIfExists(currentItems: any[], newItem: any): any[] {
  return currentItems.filter((item) => item.lessonId !== newItem.lessonId);
}

export function mergeAndRemoveDuplicates(
  array1: Lesson[],
  array2: Lesson[]
): Lesson[] {
  const mergedArray = [...array1, ...array2];

  const uniqueLessonsMap = new Map<number, Lesson>();

  mergedArray.forEach((lesson) => {
    uniqueLessonsMap.set(lesson.id, {
      id: Number(lesson?.id),
      lessonId: Number(lesson?.id) + Number(lesson.profileId),
      title: `${lesson?.title}`,
      thumbnail: `${lesson?.thumbnail}`,
      profileId: lesson?.profileId,
      progress: lesson?.progress || 0,
      subjectId: lesson?.subjectId,
    });
  });

  const uniqueLessonsArray = Array.from(uniqueLessonsMap.values());

  return uniqueLessonsArray;
}

export function mergeAndFilterArrays(
  arr1: { id: number }[] = [],
  arr2: { id: number }[] = [],
  arr3: { id: number }[] = []
) {
  // Create a map from arr2 for quick lookup
  const map2 = new Map();
  arr2.forEach((item) => map2.set(item.id, item));

  // Create a map from arr3 for quick lookup
  const map3 = new Map();
  arr3.forEach((item) => map3.set(item.id, item));

  // Merge arr1 and arr2
  const mergedMap = new Map();
  arr1.forEach((item) => {
    const matchingItem = map2.get(item.id);
    mergedMap.set(item.id, {
      ...item,
      ...matchingItem,
    });
  });

  arr2.forEach((item) => {
    if (!mergedMap.has(item.id)) {
      mergedMap.set(item.id, item);
    }
  });

  // Filter the merged array to exclude items present in arr3
  const mergedAndFilteredArray: any = [];
  mergedMap.forEach((value: any, key) => {
    if (!map3.has(key)) {
      mergedAndFilteredArray.push(value);
    }
  });

  return mergedAndFilteredArray;
}


export function mergeProfiles(existingProfiles: StoredProfile[], newProfiles: StoredProfile[]): StoredProfile[] {
  const merged = [...existingProfiles, ...newProfiles];  // Combine both arrays
  const uniqueProfiles: StoredProfile[] = [];

  const ids = new Set<number>();  // A Set to track seen ids for uniqueness

  for (const profile of merged) {
    if (!ids.has(profile.id)) {  // Check if id has not been processed
      uniqueProfiles.push(profile);  // If unique, add to the result array
      ids.add(profile.id);  // Mark this id as seen
    }
  }

  return uniqueProfiles;
}