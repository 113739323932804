import React, { useEffect, useState } from "react";
import { useGetUserQuery } from "../redux/features/user/userApiSlice";
import { SubPlansTypes } from "../models/subscription";
import { RootState } from "../redux/store";
import { useAppSelector } from "../redux/hooks";

function useCheckSubscription() {
  const token = useAppSelector(
    (state: RootState) => state.auth.activeProfileToken
  );
  const { data, isLoading } = useGetUserQuery(`${token}`);
  const [subscriptionPlans, setSubscriptionPlans] =
    useState<SubPlansTypes>("free");

  useEffect(() => {
    if (data?.subscription === null) {
      setSubscriptionPlans("free");
    }
  }, [subscriptionPlans]);

  return subscriptionPlans;
}

export default useCheckSubscription;
