import { Id } from "@reduxjs/toolkit/dist/tsHelpers";
import { apiSlice } from "../../apiSlice";
import { GetPlansResponse } from "../../../models/subscription";
import {
  PayWithVoucherBody,
  PayWithVoucherResponse,
  PaymentBody,
  PaymentResponse,
} from "../../../models/profile";

const subscriptionApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProfileSubscription: builder.query<any, number>({
      query: (id) => ({
        url: `/payments/profile/${id}`,
        method: "get",
      }),
      providesTags: ["Subscription"],
    }),
    getPaymentKey: builder.query<{data: string}, undefined>({
      query: (id) => ({
        url: `/payments/paystack/pkey`,
        method: "get",
      }),
    }),
    getPlans: builder.query<GetPlansResponse, undefined>({
      query: (id) => ({
        url: `/plan/`,
        method: "get",
      }),
    }),
    payWithVoucher: builder.mutation<
      PayWithVoucherResponse,
      PayWithVoucherBody
    >({
      query: (payload) => ({
        url: `/payments/voucher-pay`,
        method: "post",
        body: { ...payload },
      }),
    }),
    payment: builder.mutation<PaymentResponse, PaymentBody>({
      query: (payload) => ({
        url: `/payments/subscription/generate-reference?source=web`,
        method: "post",
        body: { ...payload },
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetProfileSubscriptionQuery,
  useGetPlansQuery,
  usePayWithVoucherMutation,
  usePaymentMutation,
  useGetPaymentKeyQuery
} = subscriptionApiSlice;
