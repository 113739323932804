import {
  GetChampionshipQuestionsResponse,
  GetChampionshipsResponse,
  GetLeaderBoardResponse,
} from "../../../models/championship";
import { updateCache } from "../../../utils/removeCopies";
import { apiSlice } from "../../apiSlice";

const championshipApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLeaderBoard: builder.query<GetLeaderBoardResponse, string | undefined>({
      query: (id) => ({
        url: "/championships/leaderboard",
        method: "get",
        params: { limit: 50, type: "junior" },
      }),
      providesTags: ["Championship"],
    }),
    getChampionships: builder.query<GetChampionshipsResponse, undefined>({
      query: () => ({
        url: "/championships",
        method: "get",
      }),
      providesTags: ["Championship"],
    }),
    getChampionshipQuestions: builder.query<
      GetChampionshipQuestionsResponse,
      {
        type: "senior" | "junior";
        page?: number;
        limit?: number;
        order?: "ASC" | "DESC";
      }
    >({
      query: (query) => ({
        url: "/championships/questions/random",
        method: "get",
        params: query,
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems) => {
        console.log(newItems, "from api slice");
        return updateCache(currentCache, newItems);
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: 0,
    }),
    submitAnswers: builder.mutation<
      any,
      { questionId: number; answer: number }[]
    >({
      query: (body) => ({
        url: "/championships/submit",
        method: "post",
        body: { answers: body },
      }),
      invalidatesTags: ["Championship"],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetLeaderBoardQuery,
  useGetChampionshipsQuery,
  useGetChampionshipQuestionsQuery,
  useSubmitAnswersMutation,
} = championshipApiSlice;
