import React from "react";

function Trophy({
  color = "#C8B6C6",
}: {
  color?: React.CSSProperties["color"];
}) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 5.83333C10 5.61232 10.0878 5.40036 10.2441 5.24408C10.4004 5.0878 10.6123 5 10.8333 5H29.1667C29.3877 5 29.5996 5.0878 29.7559 5.24408C29.9122 5.40036 30 5.61232 30 5.83333V6.66667H34.1667C34.3877 6.66667 34.5996 6.75446 34.7559 6.91074C34.9122 7.06702 35 7.27899 35 7.5V12.5C35 13.6051 34.561 14.6649 33.7796 15.4463C32.9982 16.2277 31.9384 16.6667 30.8333 16.6667H29.4308C28.8352 18.3476 27.8015 19.839 26.4365 20.9867C25.0716 22.1344 23.4249 22.8967 21.6667 23.195V28.3333H26.6667C26.8877 28.3333 27.0996 28.4211 27.2559 28.5774C27.4122 28.7337 27.5 28.9457 27.5 29.1667V34.1667C27.5 34.3877 27.4122 34.5996 27.2559 34.7559C27.0996 34.9122 26.8877 35 26.6667 35H13.3333C13.1123 35 12.9004 34.9122 12.7441 34.7559C12.5878 34.5996 12.5 34.3877 12.5 34.1667V29.1667C12.5 28.9457 12.5878 28.7337 12.7441 28.5774C12.9004 28.4211 13.1123 28.3333 13.3333 28.3333H18.3333V23.195C16.5751 22.8967 14.9284 22.1344 13.5635 20.9867C12.1985 19.839 11.1648 18.3476 10.5692 16.6667H9.16667C8.0616 16.6667 7.00179 16.2277 6.22039 15.4463C5.43899 14.6649 5 13.6051 5 12.5V7.5C5 7.27899 5.0878 7.06702 5.24408 6.91074C5.40036 6.75446 5.61232 6.66667 5.83333 6.66667H10V5.83333ZM30 13.3333V8.33333H33.3333V12.5C33.3333 13.163 33.0699 13.7989 32.6011 14.2678C32.1323 14.7366 31.4964 15 30.8333 15H30V13.3333ZM10 8.33333H6.66667V12.5C6.66667 13.163 6.93006 13.7989 7.3989 14.2678C7.86774 14.7366 8.50363 15 9.16667 15H10V8.33333Z"
        fill={color}
      />
    </svg>
  );
}

export default Trophy;
