import React, { createContext, useContext, useEffect, useState } from "react";
import Toast from "../components/UI/molecules/Toast";

interface ToastData {
  message: string;
  variant: "success" | "error" | "info";
}

interface ToastContextProps {
  show: (data: ToastData) => void;
}

const ToastContext = createContext<ToastContextProps | undefined>(undefined);

const ToastContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [toastData, setToastData] = useState<ToastData | null>(null);

  const show = (data: ToastData) => {
    setToastData(data);
  };

  useEffect(() => {
    if (toastData) {
      const timer = setTimeout(() => {
        setToastData(null);
      }, 5000); // Adjust the duration in milliseconds (e.g., 3000 for 3 seconds)
      return () => clearTimeout(timer);
    }
  }, [toastData]);

  return (
    <ToastContext.Provider value={{ show }}>
      {children}
      <Toast
        show={!!toastData}
        message={toastData?.message || ""}
        variant={toastData?.variant || "info"}
      />
    </ToastContext.Provider>
  );
};

const useToast = (): ToastContextProps => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastContextProvider");
  }
  return context;
};

export { ToastContextProvider, useToast };
