/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/prefer-default-export */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { RootState } from "./store";
import { handleLogout, tokenReceived } from "./features/auth/authSlice";

const baseQuery = fetchBaseQuery({
  baseUrl: "https://backend.ubelalearn.com",
  prepareHeaders: (headers, api) => {
    const token = (api.getState() as RootState).auth.authToken;
    const profileToken = (api.getState() as RootState).auth.activeProfileToken;
    const role = (api.getState() as RootState).auth.userRole;
    console.log(token, "usrToken", role, profileToken, "profile");
    if (token) {
      if (role === "child") {
        headers.set("Authorization", `Bearer ${profileToken}`);
      } else {
        // console.log(token);
        headers.set("Authorization", `Bearer ${token}`);
      }
    }
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // console.log("POST RESPONSE: " + JSON.stringify(result));
    // try to get a new token
    const refreshResult: any = await baseQuery(
      {
        url: "/account/refresh",
        method: "POST",
        body: { hash: (api.getState() as RootState).auth.authRefreshToken },
      },
      api,
      extraOptions
    );
    // console.log("REFRESH RESPONSE: " + JSON.stringify(refreshResult));
    if (refreshResult.data) {
      // console.log(
      //   "REFRESH RESPONSE: " + JSON.stringify(refreshResult.data.access)
      // );
      // store the new token
      // console.warn(
      //   "REFRESH RESPONSE: " + JSON.stringify(refreshResult.data.data)
      // );
      api.dispatch(
        tokenReceived({
          access: refreshResult.data.access,
          refresh: refreshResult.data.refresh,
        })
      );
      // retry the initial query
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(handleLogout());
    }
  }
  return result;
};

export const apiSlice = createApi({
  reducerPath: "apiSlice",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
  tagTypes: [
    "User",
    "Profile",
    "Lesson",
    "Championship",
    "Subscription",
    "Analytics",
  ],
});
