import React from "react";
import TextSquiggle from "../../../../assets/icons/textSquiggle.svg";
import Text from "../Text";

function Tag({
  children,
  style,
  onClick,
  disabled,
  isLoading,
}: {
  children: React.ReactNode;
  style?: React.CSSProperties;
  onClick?: () => void;
  disabled?: boolean;
  isLoading?: boolean;
}) {
  return (
    <button
      disabled={disabled || isLoading}
      onClick={onClick}
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        right: "15px",
        width: "79px",
        ...style,
      }}
    >
      <img
        src={TextSquiggle}
        alt=""
        style={{ position: "absolute", width: "79px", height: "37px", objectFit: "cover" }}
      />
      <span
        style={{
          fontSize: "12px",
          fontWeight: 500,
          textAlign: "center",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "7px",
        }}
      >
        {isLoading ? "loading..." : children}
      </span>
    </button>
  );
}

export default Tag;
