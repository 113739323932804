import {
  GetNumOfTopicsResponse,
  Lesson,
  Subject,
  Topic,
} from "../../../models/lessons";
import { apiSlice } from "../../apiSlice";

const lessonsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllSubjects: builder.query<{ data: Subject[] }, undefined>({
      query: () => ({
        url: "/content/subjects",
        method: "get",
      }),
    }),
    getSubject: builder.query<Subject, number>({
      query: (subjectId) => ({
        url: `/content/subjects/${subjectId}`,
        method: "get",
      }),
    }),
    getSubjectLessons: builder.query<Subject, number>({
      query: (subjectId) => ({
        url: `/content/subjects/${subjectId}/lessons`,
        method: "get",
      }),
    }),
    getNumberOfTopics: builder.query<
      GetNumOfTopicsResponse,
      { subjectId: number; grade: string }
    >({
      query: ({ subjectId, grade }) => ({
        url: `/content/lessons/count`,
        method: "get",
        params: { subject_id: subjectId, grade: grade },
      }),
      providesTags: ["Profile", "User", "Lesson"],
    }),
    getTopic: builder.query<{ data: Topic }, number>({
      query: (id) => ({
        url: `/content/lessons/${id}`,
        method: "get",
      }),
      providesTags: ["Lesson"],
    }),
    getLesson: builder.query<{ data: Lesson }, number>({
      query: (id) => ({
        url: `/content/topics/${id}/`,
        method: "get",
      }),
      providesTags: ["Lesson"],
    }),
    getTopicsByGrade: builder.query<
      { data: Topic[] },
      { subjectId: number; grade: string }
    >({
      query: ({ subjectId, grade }) => ({
        url: `/content/subjects/${subjectId}/lessons`,
        method: "get",
        params: { grade },
      }),
      providesTags: ["Profile", "User", "Lesson"],
    }),
    startLesson: builder.mutation<any, { topicId: number; lessonId: number }>({
      query: ({ topicId, lessonId }) => ({
        url: `/content/topics/${lessonId}/start?lessonId=${topicId}`,
        method: "post",
      }),
      invalidatesTags: ["Analytics"]
    }),
    finishLesson: builder.mutation<any, { topicId: number; lessonId: number }>({
      query: ({ topicId, lessonId }) => ({
        url: `/content/topics/${lessonId}/record?lessonId=${topicId}`,
        method: "post",
      }),
      invalidatesTags: ["Analytics"]
    }),
    startTopic: builder.mutation<any, number>({
      query: (topicId) => ({
        url: `/content/lessons/${topicId}/start`,
        method: "post",
      }),
    }),
    recordQuizResponse: builder.mutation<any, number>({
      query: (topicId) => ({
        url: `/content/quizzes/start`,
        method: "post",
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetAllSubjectsQuery,
  useGetSubjectQuery,
  useGetSubjectLessonsQuery,
  useGetNumberOfTopicsQuery,
  useGetTopicsByGradeQuery,
  useGetLessonQuery,
  useStartLessonMutation,
  useStartTopicMutation,
  useGetTopicQuery,
  useFinishLessonMutation
} = lessonsApiSlice;
