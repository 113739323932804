import React from "react";
import styles from "./LeaderBoard.module.scss";
import Text from "../../UI/atoms/Text";
import Tag from "../../UI/atoms/Tag";
import { useGetUserQuery } from "../../../redux/features/user/userApiSlice";
import {
  useGetChampionshipsQuery,
  useGetLeaderBoardQuery,
} from "../../../redux/features/championship/championshipApiSlice";
import LeaderBoardItem from "../../UI/molecules/LeaderBoardItem";
import { Skeleton } from "@chakra-ui/react";
import { colors } from "../../../sdk/colors";
import { useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import { useNavigate } from "react-router-dom";
import { ReactComponent as EmptyImage } from "../../../assets/images/emptyImage.svg";
import { championshipHelper } from "../../../utils/championshipHelper";

function LeaderBoard() {
  const token = useAppSelector(
    (state: RootState) => state.auth.activeProfileToken
  );
  const navigate = useNavigate();
  const { data: user } = useGetUserQuery(`${token}`);
  const { data, isLoading } = useGetLeaderBoardQuery(`${user?.id}`, {
    refetchOnMountOrArgChange: true,
  });
  const { data: championship } = useGetChampionshipsQuery(undefined);
  console.log(championship, "championship");
  console.log(data, "leaderboard");

  const championshipLeague = championshipHelper.getUserType(
    Number(user?.grade),
    championship?.data
  );

  console.log(championshipLeague, "league");

  const filteredLeaderboard = data?.data.filter(
    (item) =>
      championshipHelper.getUserType(
        Number(item?.profile?.grade),
        championship?.data
      ) === championshipLeague
  );
  return (
    <div className={styles.LeaderBoard}>
      <div className={styles.Header}>
        <Text variant="heading 4" fontWeight="light">
          Leaderboard
        </Text>
        <Tag
          disabled={data?.data && data?.data.length < 1}
          onClick={() => navigate("leaderboard")}
        >
          My position
        </Tag>
      </div>
      <div className={styles.Wrapper}>
        {isLoading && (
          <>
            <Skeleton
              isLoaded={false}
              borderRadius="10px"
              className={styles.ItemSkeleton}
              startColor={colors.grey5}
              endColor={colors.grey4}
            />
            <Skeleton
              isLoaded={false}
              borderRadius="10px"
              className={styles.ItemSkeleton}
              startColor={colors.grey5}
              endColor={colors.grey4}
            />
            <Skeleton
              isLoaded={false}
              borderRadius="10px"
              className={styles.ItemSkeleton}
              startColor={colors.grey5}
              endColor={colors.grey4}
            />
            <Skeleton
              isLoaded={false}
              borderRadius="10px"
              className={styles.ItemSkeleton}
              startColor={colors.grey5}
              endColor={colors.grey4}
            />
            <Skeleton
              isLoaded={false}
              borderRadius="10px"
              className={styles.ItemSkeleton}
              startColor={colors.grey5}
              endColor={colors.grey4}
            />
            <Skeleton
              isLoaded={false}
              borderRadius="10px"
              className={styles.ItemSkeleton}
              startColor={colors.grey5}
              endColor={colors.grey4}
            />
            <Skeleton
              isLoaded={false}
              borderRadius="10px"
              className={styles.ItemSkeleton}
              startColor={colors.grey5}
              endColor={colors.grey4}
            />
            <Skeleton
              isLoaded={false}
              borderRadius="10px"
              className={styles.ItemSkeleton}
              startColor={colors.grey5}
              endColor={colors.grey4}
            />
          </>
        )}
        {filteredLeaderboard && filteredLeaderboard.length > 0 ? (
          filteredLeaderboard
            ?.slice(0, 5)
            .map((item, index) => (
              <LeaderBoardItem
                key={item.id}
                index={index + 1}
                score={`${item.score}`}
                profile={item.profile}
              />
            ))
        ) : (
          <div className={styles.Empty}>
            <EmptyImage style={{ height: "250px", width: "250px" }} />
            <Text variant="heading 2">Nothing to see here</Text>
          </div>
        )}
      </div>
    </div>
  );
}

export default LeaderBoard;
