import { Championship } from "../models/championship";


export class championshipHelper {
  static getUserType(
    grade: number,
    championships: Championship[] | undefined
  ): "senior" | "junior" {
    let type: "senior" | "junior" = "junior";
    if (championships)
    for (let i = 0; i < championships.length; i++) {
      if (
        grade >= championships[i].gradeRange.min &&
        grade <= championships[i].gradeRange.max
      ) {
        type = championships[i].type;
        break;
      }
    }
    return type;
  }
  static getActiveState(grade: number, championships: Championship[] | undefined): boolean {
    return (
      championships?.find(
        (championship) =>
          championship.type === this.getUserType(grade, championships)
      )?.isActive || false
    );
  }
}
