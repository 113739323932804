import React, { useEffect, useMemo } from "react";
import { colors } from "../../../../sdk/colors";

function ProfileAvatar({
  image,
  isLoading,
  index,
  variant = "default",
  isEdit = false,
}: {
  image: string;
  isLoading: boolean;
  index?: number;
  variant?: "default" | "curve";
  isEdit?: boolean;
}) {
  console.log(image);
  useEffect(() => {}, [image]);
  const imageUrl = useMemo(() => image, [image]);
  return variant === "default" ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="76"
      height="79"
      viewBox="0 0 31 32"
      fill={isLoading ? colors.grey5 : "none"}
    >
      <path
        d="M22.1577 1.00826C25.1072 2.02351 28.1387 3.44943 29.7817 6.10097C31.3945 8.70362 30.9916 11.9781 30.8507 15.0368C30.7212 17.8506 30.1935 20.5554 28.9987 23.1063C27.7128 25.8518 26.3253 28.7433 23.7114 30.2792C21.0406 31.8486 17.717 32.1743 14.6905 31.5138C11.8252 30.8885 9.77018 28.6134 7.51079 26.7436C5.30496 24.9182 2.87295 23.2981 1.63998 20.714C0.323487 17.9549 -0.540203 14.7287 0.388286 11.816C1.30084 8.95322 4.24974 7.43085 6.50656 5.44723C8.66998 3.54569 10.5612 1.22485 13.3322 0.438931C16.2269 -0.382064 19.3126 0.0289747 22.1577 1.00826Z"
        fill={isLoading ? colors.grey5 : `url(#pattern0${index || 45})`}
      />
      <path
        d="M22.1577 1.00826C25.1072 2.02351 28.1387 3.44943 29.7817 6.10097C31.3945 8.70362 30.9916 11.9781 30.8507 15.0368C30.7212 17.8506 30.1935 20.5554 28.9987 23.1063C27.7128 25.8518 26.3253 28.7433 23.7114 30.2792C21.0406 31.8486 17.717 32.1743 14.6905 31.5138C11.8252 30.8885 9.77018 28.6134 7.51079 26.7436C5.30496 24.9182 2.87295 23.2981 1.63998 20.714C0.323487 17.9549 -0.540203 14.7287 0.388286 11.816C1.30084 8.95322 4.24974 7.43085 6.50656 5.44723C8.66998 3.54569 10.5612 1.22485 13.3322 0.438931C16.2269 -0.382064 19.3126 0.0289747 22.1577 1.00826Z"
        fill="black"
        fillOpacity={isEdit ? 0.6 : 0}
      />
      <defs>
        <pattern
          id={`pattern0${index || 45}`}
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref={`#image0_245_252${index || 45}`}
            transform="matrix(0.00251712 0 0 0.00245098 -0.270238 0)"
          />
        </pattern>
        {!isLoading && (
          <image
            style={{ objectFit: "cover", backgroundSize: "cover"}}
            id={`image0_245_252${index || 45}`}
            width="612"
            height="408"
            xlinkHref={imageUrl}
          />
        )}
      </defs>
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="76"
      height="79"
      viewBox="0 0 76 79"
      fill="none"
    >
      <path
        d="M68.8979 6.37914C76.6991 14.2283 76.546 26.9209 75.4635 38.1077C74.5136 47.9243 70.0946 56.6932 63.3523 63.6887C56.6763 70.6154 48.1902 74.6568 38.8288 76.1181C27.56 77.877 14.4307 80.6629 6.11386 72.6231C-2.25837 64.5298 0.39931 50.812 0.405768 38.975C0.412105 27.358 -1.83632 14.1673 6.15021 5.97844C14.0914 -2.16397 26.8115 0.349573 38.0071 0.421066C48.8967 0.490604 61.1016 -1.4651 68.8979 6.37914Z"
        fill={isLoading ? colors.grey5 : `url(#pattern0${index || 45})`}
      />

      <path
        d="M68.8979 6.37914C76.6991 14.2283 76.546 26.9209 75.4635 38.1077C74.5136 47.9243 70.0946 56.6932 63.3523 63.6887C56.6763 70.6154 48.1902 74.6568 38.8288 76.1181C27.56 77.877 14.4307 80.6629 6.11386 72.6231C-2.25837 64.5298 0.39931 50.812 0.405768 38.975C0.412105 27.358 -1.83632 14.1673 6.15021 5.97844C14.0914 -2.16397 26.8115 0.349573 38.0071 0.421066C48.8967 0.490604 61.1016 -1.4651 68.8979 6.37914Z"
        fill="black"
        fillOpacity={isEdit ? 0.6 : 0}
      />
      <defs>
        <pattern
          id={`pattern0${index || 45}`}
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref={`#image0_245_252${index || 45}`}
            transform="matrix(0.00167806 0 0 0.00163399 -0.0134869 0)"
          />
        </pattern>
        <image
          style={{ objectFit: "cover" }}
          id={`image0_245_252${index || 45}`}
          width="612"
          height="612"
          xlinkHref={imageUrl}
        />
      </defs>
    </svg>
  );
}

export default React.memo(ProfileAvatar);
