import React, { useEffect, useMemo, useState } from "react";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import styles from "./Lessons.module.scss";
import MyLessonsCard from "../../../components/UI/molecules/MyLessonsCard";
import PageHeader from "../../../components/UI/molecules/PageHeader";
import { useNavigate } from "react-router-dom";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/whiteDownloadIcon.svg";
import LearningGif from "../../../assets/images/subject.gif";
import { useGetAllSubjectsQuery } from "../../../redux/features/lessons/lessonsApiSlice";
import { Helmet } from "react-helmet";
import { useNetworkState } from "react-use";
import Button from "../../../components/UI/atoms/Button";
import { useGetUserQuery } from "../../../redux/features/user/userApiSlice";
import { useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";

function Lessons() {
  const profileId = useAppSelector(
    (state: RootState) => state.auth.activeProfileId
  );
  const navigate = useNavigate();

  const { data: user } = useGetUserQuery(undefined, {
    refetchOnReconnect: true,
  });
  const [controlledSwiper, setControlledSwiper] = useState<any>(null);
  const state = useNetworkState();

  console.log(state);

  useEffect(() => {
    if (controlledSwiper === 0) {
      backgroundColorValue = "#59E1BF";
    } else if (controlledSwiper === 1) {
      backgroundColorValue = "#FF8282";
    } else if (controlledSwiper === 2) {
      backgroundColorValue = "#9747FF";
    }
  }, [controlledSwiper]);

  let backgroundColorValue: string = useMemo(
    () =>
      controlledSwiper === 2
        ? "#9747FF"
        : controlledSwiper === 1
        ? "#FF8282"
        : "#59E1BF",
    [controlledSwiper, state]
  );

  const { data } = useGetAllSubjectsQuery(undefined);

  console.log(data, state);

  useEffect(() => {
    if (state.online && user?.info?.profileId) {
      if (user?.info?.profileId !== profileId) {
        navigate("/app");
      }
    }
  }, [state, user]);

  return (
    <div
      style={{ backgroundColor: backgroundColorValue }}
      className={styles.Lessons}
    >
      <Helmet>
        <title>Lessons - UBELA</title>
      </Helmet>
      <div className={styles.Header}>
        <PageHeader
          textColor="#fff"
          pageTitle="My Lessons"
          handleGoBack={() => navigate(-1)}
          rightComponent={
            <DownloadIcon
              onClick={() => navigate("/app/learn/downloads")}
              style={{ cursor: "pointer" }}
            />
          }
        />
      </div>
      <img src={LearningGif} alt="" id={styles.LearningGif} />
      {state.online ? (
        <Swiper
          id={styles.SwiperWrapper}
          pagination={{ clickable: true }}
          modules={[Pagination]}
          className="mySwiper"
          onSwiper={(swiper) => {
            setControlledSwiper(swiper.activeIndex);
            swiper.pagination.bullets.forEach((bullet) => {
              bullet.addEventListener("click", () => {
                setControlledSwiper(swiper.activeIndex);
              });
            });
          }}
          onSlideChange={(swiper) => setControlledSwiper(swiper.activeIndex)}
        >
          {data?.data.map((subject) => (
            <SwiperSlide key={subject.id} id={styles.SwiperItem}>
              <MyLessonsCard subject={subject} />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <div
          style={{
            alignItems: "center",
            padding: "0px 20px",
            textAlign: "center",
            maxWidth: "1000px",
            margin: "0 auto",
          }}
        >
          <Button onClick={() => navigate("/app/learn/downloads")}>
            Go to Downloads
          </Button>
          <p style={{ textAlign: "center", paddingTop: "20px" }}>
            You need to be online to access the full features of UBELA
          </p>
        </div>
      )}
    </div>
  );
}

export default Lessons;
