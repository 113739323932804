import {
  UpdateProfileAvatarBody,
  UploadImageResponse,
} from "../../../models/profile";
import {
  ChangePasswordBody,
  User,
  UserProfileBody,
} from "../../../models/user";
import { apiSlice } from "../../apiSlice";

const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<User, string | undefined>({
      query: (token) => ({
        url: "/account/me",
        method: "get",
      }),
      providesTags: ["User", "Profile"],
    }),
    getReferral: builder.query<{ data: string }, undefined>({
      query: () => ({
        url: "/account/referral-code",
        method: "get",
      }),
      providesTags: ["User"],
    }),
    editUser: builder.mutation<{ message: string }, UserProfileBody>({
      query: (body) => ({
        url: "/account/edit",
        method: "PATCH",
        body: { ...body },
      }),
      invalidatesTags: ["User"],
    }),
    // changePassword: builder.mutation<{message: string}, ChangePasswordBody>({
    //   query: (body) => ({
    //     url: "/account/password/change",
    //     method: "post",
    //     body: {...body}
    //   })
    // }),
    uploadImage: builder.mutation<
      { message: string; data: { url: string } },
      FormData
    >({
      query: (body) => ({
        url: "/account/avatar",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["User"],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetUserQuery,
  useEditUserMutation,
  useUploadImageMutation,
  useGetReferralQuery,
} = userApiSlice;
