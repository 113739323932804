import React, { useContext, useEffect } from "react";
import styles from "./ChooseProfile.module.scss";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import Text from "../../components/UI/atoms/Text";
import {
  useGetProfilesQuery,
  useSwitchProfileMutation,
} from "../../redux/features/profile/profileApiSlice";
import { useToast } from "../../context/toast.context";
import { ErrorResponse } from "../../models/utilityTypes";
import Button from "../../components/UI/atoms/Button";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  saveProfileId,
  setProfileTokens,
  tokenReceived,
  updateUserRole,
} from "../../redux/features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import LoaderContext from "../../context/loader.context";
import { motion } from "framer-motion";
import { RootState } from "../../redux/store";
import { useInstallPrompt } from "../../context/installprompt.context";
import { BeforeInstallPromptEvent } from "../Login";
import { handleStoreProfiles } from "../../redux/features/profile/profileSlice";
import defaultAvatar from "../../assets/images/defaultAvatar.png";
import { useNetworkState } from "react-use";

function ChooseProfile() {
  const activeProfileToken = useAppSelector(
    (state: RootState) => state.auth.activeProfileToken
  );
  const { setLoader: setLoading } = useContext(LoaderContext);
  const {
    isLoading,
    data: profiles,
    isFetching,
    isError,
  } = useGetProfilesQuery(undefined, { refetchOnReconnect: true });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [switchProfile, { isLoading: isSwitchProfileLoading }] =
    useSwitchProfileMutation();

  const { show } = useToast();

  const { deferredPrompt, setDeferredPrompt } = useInstallPrompt();

  useEffect(() => {
    if (profiles && profiles?.length > 0) {
      dispatch(
        handleStoreProfiles(
          profiles.map((item) => {
            return {
              avatar: {
                url: item?.avatar?.url || "",
              },
              firstName: item?.firstName || "",
              lastName: item?.lastName || "",
              id: Number(item?.id),
              subscription: item?.subscription,
            };
          })
        )
      );
    }
  }, [profiles]);

  useEffect(() => {
    if (deferredPrompt) {
      const promptEvent = deferredPrompt as BeforeInstallPromptEvent;
      promptEvent.prompt();
      promptEvent.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
        } else {
          console.log("User dismissed the install prompt");
        }
        setDeferredPrompt(null); // Clear the prompt after use
      });
    }
  }, [deferredPrompt, setDeferredPrompt]);

  useEffect(() => {
    setLoading(isLoading || isFetching);
    return () => {
      setLoading(false);
    };
  }, [isLoading, isFetching]);

  useEffect(() => {
    if (isError) {
      if (activeProfileToken) {
        // navigate("/app/learn/downloads");
        // show({
        //   message:
        //     "You are probably offline. So in the meantime, check out your downloads",
        //   variant: "info",
        // });
      } else {
        show({
          message: "You have no active profile with downloads",
          variant: "error",
        });
      }
    }
  }, [isError]);

  const state = useNetworkState();

  const storedProfiles = useAppSelector(
    (state: RootState) => state.profile.profiles
  );

  const isPoorConnection =
    state.effectiveType === "2g" ||
    state.effectiveType === "slow-2g" ||
    (state?.downlink && state?.downlink < 0.3);

  const handleSwitch = async (id: number) => {
    if (state.online || isPoorConnection) {
      try {
        setLoading(true);
        const response = await switchProfile(id).unwrap();
        setLoading(false);
        console.log(response);
        // show({ message: "ldsldkld", variant: "success" });
        dispatch(setProfileTokens(response.token));
        dispatch(updateUserRole("child"));
        dispatch(saveProfileId(id));
        navigate("/app/learn", { replace: true });
      } catch (e: any) {
        setLoading(false);
        console.log(e);
        const error: ErrorResponse = e;
        show({ message: `${error.data.message}`, variant: "error" });
      }
    } else {
      dispatch(updateUserRole("child"));
      dispatch(saveProfileId(id));
      navigate("/app/learn");
    }
  };

  console.log(profiles);
  return (
    <div className={styles.ChooseProfile}>
      <div className={styles.Container}>
        <Logo />
        <Text
          variant="heading 4"
          fontWeight="medium"
          color="#fff"
          style={{ marginTop: "35px" }}
        >
          Who Wants To Learn?
        </Text>
        <div className={styles.ContentWrapper}>
          <div className={styles.Wrapper}>
            {(profiles || storedProfiles)?.map((item, index) => (
              <motion.button
                initial={{ opacity: 0, transform: "scale(2)" }}
                animate={{ transform: "scale(1)", opacity: 1 }}
                transition={{ delay: index / 15 }}
                key={item.id}
                onClick={() => handleSwitch(item.id)}
                className={styles.ProfileItem}
              >
                <img
                  className={styles.ProfileImage}
                  src={item?.avatar?.url || defaultAvatar}
                />
                <Text color="#fff" variant="heading 5" fontWeight="medium">
                  {`${item.firstName} ${item.lastName}`}
                </Text>
              </motion.button>
            ))}
          </div>
          <div style={{ marginBottom: "3%" }}>
            <Button
              onClick={() => {
                dispatch(updateUserRole("guardian"));
                navigate("/app/guardian");
              }}
              textColor="#fff"
              variant="outlined"
            >
              Continue as a Guardian
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChooseProfile;
