export const colors = {
  primary: "#22B14C",
  secondary: "#D0ED76",
  info: "#2F80ED",
  success: "#51E38F",
  warning: "#E2B93B",
  error: "#E22C2C",
  black1: "#111111",
  black2: "#1D1D1D",
  black3: "#282828",
  white: "#FFFFFF",
  math: "#FF8282",
  sci: "#9747FF",
  grey1: "#333333",
  grey2: "#4F4F4F",
  grey3: "#828282",
  grey4: "#BDBDBD",
  grey5: "#E0E0E0",
  gray: "#737373"
};
