import React, { useEffect } from "react";
import styles from "./Profile.module.scss";
import PageHeader from "../../../components/UI/molecules/PageHeader";
import Tag from "../../../components/UI/atoms/Tag";
import { useNavigate } from "react-router-dom";
import ProfileAvatar from "../../../components/UI/atoms/ProfileAvatar";
import { useGetUserQuery } from "../../../redux/features/user/userApiSlice";
import Text from "../../../components/UI/atoms/Text";
import { useGetProfileSubscriptionQuery } from "../../../redux/features/subscription/subscriptionApiSlice";
import { ReactComponent as ProfileNotificationsIcon } from "../../../assets/icons/profileNotificationsIcon.svg";
import { ReactComponent as ProfileAnalysisIcon } from "../../../assets/icons/profileAnalysisIcon.svg";
import { ReactComponent as ProfileAccountIcon } from "../../../assets/icons/profileAccountIcon.svg";
import { ReactComponent as ProfileTermsAndConditionsIcon } from "../../../assets/icons/profileTermsAndConditionsIcon.svg";
import { ReactComponent as ProfileDownloadsIcon } from "../../../assets/icons/profileDownloadsIcon.svg";
import { ReactComponent as ProfileShareIcon } from "../../../assets/icons/profileShareIcon.svg";
import { ReactComponent as ProfilePrivacyIcon } from "../../../assets/icons/profilePrivacyIcon.svg";
import { ReactComponent as ProfileLogoutIcon } from "../../../assets/icons/profileLogoutIcon.svg";
import ProfileItem from "../../../components/UI/molecules/ProfileItem";
import { useLogoutMutation } from "../../../redux/features/auth/authApiSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import {
  Modal,
  ModalContent,
  ModalOverlay,
  SkeletonText,
  useDisclosure,
  useFocusEffect,
} from "@chakra-ui/react";
import { MetaData } from "../../../models/auth";
import { osName } from "react-device-detect";
import { handleLogout } from "../../../redux/features/auth/authSlice";
import { colors } from "../../../sdk/colors";
import Button from "../../../components/UI/atoms/Button";
import useCheckSubscription from "../../../hooks/useCheckSubscription";
import { Helmet } from "react-helmet";
import { ProfileHelper } from "../../../utils/profileHelper";
import defaultAvatar from "../../../assets/images/defaultAvatar.png";
import { useNetworkState } from "react-use";

function Profile() {
  const userPlan = useCheckSubscription();
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const token = useAppSelector(
    (state: RootState) => state.auth.activeProfileToken
  );
  const { data: user, isLoading } = useGetUserQuery(undefined, {
    refetchOnReconnect: true,
  });

  const { isLoading: isSubscriptionLoading, data: subscription } =
    useGetProfileSubscriptionQuery(Number(user?.id), {
      refetchOnMountOrArgChange: true,
    });

  const profileId = useAppSelector(
    (state: RootState) => state.auth.activeProfileId
  );

  const storedProfiles = useAppSelector(
    (state: RootState) => state.profile.profiles
  );

  const storedActiveProfile = storedProfiles.find(
    (item) => item.id === profileId
  );

  console.log(subscription, "subs", user);

  const state = useNetworkState();

  useEffect(() => {
    if (state.online && user?.info?.profileId) {
      if (user?.info?.profileId !== profileId) {
        navigate("/app");
      }
    }
  }, [state, user]);
  return (
    <>
      <div className={styles.Profile}>
        <Helmet>
          <title>Profile - UBELA</title>
        </Helmet>
        <PageHeader
          pageTitle="Student Profile"
          rightComponent={<div></div>}
          handleGoBack={() => navigate(-1)}
        />
        <div className={styles.Container}>
          <div className={styles.ProfileWrapper}>
            <img
              src={
                user?.avatar?.url ||
                storedActiveProfile?.avatar?.url ||
                defaultAvatar
              }
              style={{
                borderRadius: "22px",
                height: "76px",
                width: "76px",
                border: `2px solid ${colors.primary}`,
                objectFit: "cover",
                backgroundColor: "#fff",
              }}
            />
            <SkeletonText
              noOfLines={1}
              isLoaded={!isLoading}
              startColor={colors.grey5}
              endColor={colors.grey4}
            >
              <Text style={{ marginTop: "6px" }} fontWeight="medium">
                {user?.firstName || storedActiveProfile?.firstName}
              </Text>
            </SkeletonText>
            <Text
              style={{
                color: colors.primary,
                fontSize: 14,
                fontWeight: 500,
                textTransform: "capitalize",
                marginTop: "6px",
              }}
            >
              {ProfileHelper.getProfileSubscriptionPlan(
                user?.subscription || storedActiveProfile?.subscription
              )}
            </Text>
          </div>
          <div className={styles.Wrapper}>
            <ProfileItem
              icon={<ProfileAnalysisIcon />}
              title="Learning Analysis"
              onClick={() => navigate("/app/learn/analytics")}
            />
            <ProfileItem
              icon={<ProfileDownloadsIcon />}
              title="My Downloads"
              onClick={() => navigate("/app/learn/downloads")}
            />
            <ProfileItem
              icon={<ProfileShareIcon />}
              title="Share App / Refer"
              onClick={() => navigate("/app/guardian/share")}
            />
            <a href="https://ubelalearn.com/terms-of-use" target="__blank">
              <ProfileItem
                icon={<ProfileTermsAndConditionsIcon />}
                title="Terms and Conditions"
              />
            </a>
            <a href="https://ubelalearn.com/privacy-policy" target="__blank">
              <ProfileItem
                icon={<ProfilePrivacyIcon />}
                title="Privacy policy"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
