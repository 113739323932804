import "./App.css";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppSelector } from "./redux/hooks";
import { RootState } from "./redux/store";
import ChooseProfile from "./pages/ChooseProfile";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import VerifyEmail from "./pages/VerifyEmail";
import PrivateRoutes from "./PrivateRoutes";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Dashboard from "./containers/Dashboard";
import Home from "./pages/Dashboard/Home";
import Championship from "./pages/Dashboard/Championship";
import Lessons from "./pages/Dashboard/Lessons";
import Profile from "./pages/Dashboard/Profile";
import Guardian from "./pages/Guardian";
import Subject from "./pages/Subject";
import Quest from "./pages/Quest";
import Lesson from "./pages/Lesson";
import Quiz from "./pages/Quiz";
import Analytics from "./pages/Analytics";
import Downloads from "./pages/Downloads";
import Topic from "./pages/Topic";
import Share from "./pages/Share";
import CreateProfile from "./pages/CreateProfile";
import EditProfile from "./pages/EditProfile";
import ChangePassword from "./pages/ChangePassword";
import Payment from "./pages/Payment";
import GuardianDashboard from "./containers/GuardianDashboard";
import Help from "./pages/Help";
import SubjectPreview from "./pages/SubjectPreview";
import FullLeaderboard from "./pages/FullLeaderboard";
import Landing from "./pages/Landing";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Terms from "./pages/Terms";
import FAQ from "./pages/FAQ";
import Blog from "./pages/Blog";
import ContinueLearning from "./pages/ContinueLearning";
import { InstallPromptProvider } from "./context/installprompt.context";
import DeleteYourData from "./pages/DeleteYourData";

function App() {
  const token = useAppSelector((state: RootState) => state.auth.authToken);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (
      !token &&
      (location.pathname === "/app" || location.pathname === "/app/")
    ) {
      navigate("/app/login");
    }
  }, [token, location, navigate]);

  return (
    <div className="App">
      <InstallPromptProvider>
        <Routes>
          <Route path="/app">
            <Route element={<PrivateRoutes />}>
              <Route
                path="guardian/analytics"
                element={<Analytics isGuardian />}
              />
              <Route path="guardian" element={<GuardianDashboard />}>
                <Route index element={<Guardian />} />
                <Route path="share" element={<Share />} />
                <Route path="create-profile" element={<CreateProfile />} />
                <Route path="subject/:subjectId" element={<SubjectPreview />} />
                <Route path="payment" element={<Payment />} />
                <Route path="change-password" element={<ChangePassword />} />
                <Route
                  path="manage-profiles/:profileId"
                  element={<EditProfile />}
                />
              </Route>
              <Route path="learn" element={<Dashboard />}>
                <Route index element={<Home />} />
                <Route path="championship" element={<Championship />} />
                <Route
                  path="championship/leaderboard"
                  element={<FullLeaderboard />}
                />
                <Route
                  path="continue-learning"
                  element={<ContinueLearning />}
                />
                <Route path="lessons/:lessonId" element={<Lesson />} />
                <Route path="topics/:topicId" element={<Topic />} />
                <Route
                  path="subjects/lessons/:topicId/quiz"
                  element={<Quiz />}
                />
                <Route path="analytics" element={<Analytics />} />
                <Route path="downloads" element={<Downloads />} />
                <Route path="championship/quest" element={<Quest />} />
                <Route path="subjects/:subjectId" element={<Subject />} />
                <Route path="lessons" element={<Lessons />} />
                <Route path="profile" element={<Profile />} />
              </Route>
            </Route>
            <Route index element={!token ? <Landing /> : <ChooseProfile />} />
            <Route path="login" element={<Login />} />
            <Route path="sign-up" element={<Signup />} />
            <Route path="verify-email" element={<VerifyEmail />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="reset-password" element={<ResetPassword />} />
          </Route>
          <Route path="/" element={<Landing />} />
          <Route path="/help" element={<Help />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/terms-of-use" element={<Terms />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/delete-data" element={<DeleteYourData />} />
        </Routes>
      </InstallPromptProvider>
    </div>
  );
}

export default App;
