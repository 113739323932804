import React, { useEffect, useMemo, useState } from "react";
import { openDB } from "idb";
import styles from "./Downloads.module.scss";
import { Video } from "../../hooks/useVideoStorage";
import PageHeader from "../../components/UI/molecules/PageHeader";
import { ReactComponent as EmptyImage } from "../../assets/images/emptyImage.svg";
import Text from "../../components/UI/atoms/Text";
import defaultImage from "../../assets/images/LearningImage.svg";
import LessonItem from "../../components/UI/molecules/LessonItem";
import useFetchVideos from "../../hooks/useFetchVideos";
import Tag from "../../components/UI/atoms/Tag";
import { ReactComponent as DropdownIcon } from "../../assets/icons/dropdown.svg";
import {
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { subjects } from "../../constants/values";
import { useGetUserQuery } from "../../redux/features/user/userApiSlice";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";

function Downloads() {
  const { data: user } = useGetUserQuery(undefined);
  const profileId = useAppSelector(
    (state: RootState) => state.auth.activeProfileId
  );
  const { videos } = useFetchVideos();
  const [subjectId, setSubjectId] = useState<number | undefined>(undefined);
  console.log(videos, profileId);
  const lessonDownloads = videos.filter(
    (video) => video.profileId === (user?.id || profileId)
  );

  const [isDeleteLoading, setIsDeleteLoading] = useState<number | undefined>();

  const downloads = useMemo(
    () =>
      subjectId !== undefined
        ? lessonDownloads.filter((lesson) => lesson.subjectId === subjectId)
        : lessonDownloads,
    [subjectId, lessonDownloads, videos, isDeleteLoading]
  );

  console.log(subjectId, downloads, videos);

  const {
    isOpen: isSubjectsOpen,
    onOpen: onSubjectsOpen,
    onClose: onSubjectsClose,
  } = useDisclosure();

  const downloadSubjects = [
    { id: undefined, name: "All Subjects", color: "" },
    ...subjects,
  ];

  async function deleteVideo(id: number): Promise<void> {
    setIsDeleteLoading(id);
    const db = await openDB("video-download", 11);
    const transaction = db.transaction("videos", "readwrite");
    const store = transaction.objectStore("videos");

    await store.delete(id);

    await transaction.done;
    db.close();
    window.location.reload();
    setIsDeleteLoading(undefined);
  }
  console.log(downloads[0]);
  return (
    <>
      <div className={styles.Downloads}>
        <PageHeader pageTitle="My Downloads" />
        <div className={styles.Container}>
          <div className={styles.HeaderWrapper}>
            <Tag style={{ fontSize: "10px" }} onClick={onSubjectsOpen}>
              <Text
                variant="caption"
                style={{ textTransform: "capitalize", fontSize: "10px" }}
              >
                {
                  downloadSubjects.find((subject) => subject.id === subjectId)
                    ?.name
                }
              </Text>
              <DropdownIcon />
            </Tag>
          </div>
          {downloads.length > 0 ? (
            <ul className={styles.DownloadsWrapper}>
              {downloads.map((video, index) => (
                <LessonItem
                  key={index + Number(video.id)}
                  lesson={{
                    thumbnail: video.thumbnailUrl,
                    title: video.name,
                    id: Number(Number(video.videoId) - Number(profileId)),
                    quizzes: video?.quizzes,
                    description: video.description,
                    video: { url: `video.data` },
                    subjectId: video.subjectId,
                  }}
                  subjectId={video.subjectId}
                  index={index}
                  isDownload
                  isLoading={Number(video?.id) === isDeleteLoading}
                  onClick={() => deleteVideo(Number(video.id))}
                />
              ))}
            </ul>
          ) : (
            <div className={styles.Empty}>
              <EmptyImage style={{ height: "250px", width: "250px" }} />
              <Text variant="heading 2">Nothing to see here</Text>
            </div>
          )}
        </div>
      </div>
      <Modal isOpen={isSubjectsOpen} onClose={onSubjectsClose}>
        <ModalOverlay />
        <ModalContent
          style={{ maxWidth: "500px", marginLeft: "30px", marginRight: "30px" }}
        >
          <div className={styles.GradeWrapper}>
            {downloadSubjects.map((subject, index) => (
              <button
                onClick={() => {
                  setSubjectId(subject.id);
                  onSubjectsClose();
                }}
                key={index}
                className={styles.GradeItem}
              >
                <Text style={{ textTransform: "capitalize" }}>
                  {subject.name}
                </Text>
              </button>
            ))}
          </div>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Downloads;
