import React, { useRef } from "react";
import styles from "./Testimonials.module.scss";
import Quotes from "../../../assets/images/quotes.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import SwiperCore from "swiper";
import { Navigation } from "swiper/modules";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import PrevButton from "../../../assets/images/prevButton.svg";
import NextButton from "../../../assets/images/nextButton.svg";
import { useSwiper } from "swiper/react";
import avatar from "../../../assets/icons/avatarTestimonial.svg";

SwiperCore.use([Navigation]); // Initialize Swiper core components

interface TestimonialsProps {
  testimonials: { quote: string; author: string; image?: any }[];
}

const testimonials: {
  quote: string;
  author: string;
  role: string;
  image?: any;
}[] = [
  {
    quote:
      "I strongly recommend UBELA to parents who are keenly desirous about helping their children succeed. I’m a school owner and I can tell you for free that children do better when they have opportunity to explore and learn in their own space and at their own pace. UBELAs structured, detailed and fun platform provides that unique opportunity.",
    author: "Dr. Adeola Akinwande (PhD)",
    role: "School Proprietor",
    image: require("../../../assets/images/dradeola.jpeg"),
  },
  {
    quote:
      "UBELA has greatly impacted my students’ attitude to learning and speed of assimilation! It caters to each student's individual learning pace. The structured and simple platform makes learning engaging and enjoyable for my pupils.",
    role: "Primary 5 Teacher",
    author: "Mr. Olawale Moses",
  },
  {
    quote:
      "I really like UBELA because it's fun to learn! We can learn about lots of different things whenever we want. It feels like having our very own teacher helping us with everything. And the quiz competition is super fun because I like competition!",
    role: "Primary 5 Student",
    author: "Shem Akinwande",
    image: require("../../../assets/images/shemakinwade.jpeg"),
  },
  {
    quote:
      "UBELA is my best friend for studying! I feel so smart in class because I can practice my lessons and homework anytime, anywhere. It's like having a magic tool to do really well on tests.",
    role: "Primary 5 Student",
    author: "Janel Oladipo James",
    image: require("../../../assets/images/janeloladipupo.jpeg"),
  },
  {
    quote:
      "As a parent, I couldn't be happier with UBELA. It's amazing to see my child excited about learning. The structured approach ensures they stay on track, while the detailed feedback helps me track their progress. It's truly a game-changer!",
    role: "Parent",
    author: "Amechi Chuba",
  },
  {
    quote:
      "UBELA has been a lifesaver for our family! With busy schedules, it's challenging to keep up with our child's education. Thanks to UBELA, we can support their learning journey from home. The platform's user-friendly interface makes it easy for us to stay involved in our child's education.",
    role: "Parent",
    author: "Barr. Ado Hassan",
  },
];

const Testimonials = () => {
  const { width } = useWindowDimensions();
  const isBreakpoint = width <= 1300;
  const isMobileBreakpoint = width <= 600;
  const isMobile = width <= 600;
  const swiperRef = useRef<any>(null);
  const swiper = useSwiper();

  const slideNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  const slidePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  return (
    <div className={styles.Testimonials}>
      <Swiper
        spaceBetween={50}
        slidesPerView={"auto"}
        navigation={false}
        loop={true}
        ref={swiperRef}
        className={styles.Swiper}
        style={{
          paddingTop: "80px",
          paddingBottom: isMobile ? "70px" : "130px",
          paddingLeft: isMobile ? "0px" : "120px",
          paddingRight: isMobile ? "0px" : "100px",
        }}
      >
        <SwiperButtonPrevious />
        {!isBreakpoint && testimonials.length <= 2 ? undefined : (
          <SwiperButtonNext />
        )}
        {testimonials.map((testimonial, index) => (
          <SwiperSlide style={{width: "auto"}} key={index}>
            <div className={styles.TestimonialItem}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <img src={Quotes} alt="" />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "24px",
                  }}
                >
                  <div>
                    <h5>{testimonial.author}</h5>
                    <h6>{testimonial.role}</h6>
                  </div>
                  {testimonial?.image ? (
                    <img
                      src={testimonial.image}
                      alt=""
                      style={{
                        height: isMobileBreakpoint ? "40px" : "73px",
                        width: isMobileBreakpoint ? "40px" : "73px",
                        objectFit: "cover",
                        borderRadius: "50%",
                        objectPosition: "center",
                      }}
                    />
                  ) : (
                    <img
                      src={avatar}
                      alt=""
                      style={{
                        height: isMobileBreakpoint ? "50px" : "90px",
                        width: isMobileBreakpoint ? "50px" : "90px",
                        objectFit: "cover",
                        objectPosition: "center",
                      }}
                    />
                  )}
                </div>
              </div>
              <p>{testimonial.quote}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

const SwiperButtonPrevious = () => {
  const swiper = useSwiper();
  return (
    <button onClick={() => swiper.slidePrev()} id={styles.PrevButton}>
      <img src={PrevButton} alt="" />
    </button>
  );
};
const SwiperButtonNext = () => {
  const swiper = useSwiper();
  return (
    <button onClick={() => swiper.slideNext()} id={styles.NextButton}>
      <img src={NextButton} alt="" />
    </button>
  );
};

export default Testimonials;
