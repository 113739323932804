import React, { useState } from "react";
import * as Yup from "yup";
import styles from "./ForgotPassword.module.scss";
import AuthContainer from "../../containers/AuthContainer";
import Text from "../../components/UI/atoms/Text";
import { ReactComponent as ForgotPasswordImage } from "../../assets/images/forgotPasswordImage.svg";
import Input from "../../components/UI/atoms/Input";
import Button from "../../components/UI/atoms/Button";
import { ForgotPasswordBody, ResetPasswordBody } from "../../models/auth";
import { useForgotPasswordMutation } from "../../redux/features/auth/authApiSlice";
import { useToast } from "../../context/toast.context";
import { useAppDispatch } from "../../redux/hooks";
import { updateNumberToReceiveCode } from "../../redux/features/auth/authSlice";
import { ErrorResponse } from "../../models/utilityTypes";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import { colors } from "../../sdk/colors";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";

function ForgotPassword() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { show } = useToast();

  const initialValues: ForgotPasswordBody = {
    phoneNumber: "",
  };

  const validationSchema = Yup.object({
    phoneNumber: Yup.string()
      .matches(
        /^(\+234|0)(701|702|703|704|705|706|707|708|709|802|803|804|805|806|807|808|809|810|811|812|813|814|815|816|817|818|819|909|908|901|902|903|904|905|906|907)([0-9]{7})$/,
        "Invalid phone number"
      )
      .required("This is a required field"),
  });

  const [open, setOpen] = useState<boolean>(false);

  const onSubmit = async (values: ForgotPasswordBody) => {
    const phone = values.phoneNumber.match(/^([0]{1})[0-9]{10}$/)
      ? values.phoneNumber.replace(/\D|^0+/g, "+234")
      : values.phoneNumber;
    const submitValues: ForgotPasswordBody = {
      phoneNumber: phone,
    };
    console.log(phone, submitValues);
    try {
      const response = await forgotPassword(submitValues).unwrap();
      dispatch(updateNumberToReceiveCode(phone));
      show({ message: `${response.message}`, variant: "info" });
      
      navigate("/app/reset-password");
    } catch (e: any) {
      const error: ErrorResponse = e;
      show({ message: `${error.data.message}`, variant: "error" });
    }
  };

  return (
    <AuthContainer canGoBack>
      <div className={styles.ForgotPassword}>
        <div className={styles.AuthHeader}>
          <Text variant="heading 4" fontWeight="light">
            Forgot Your Password?
          </Text>
          <Text variant="heading 5" style={{ marginTop: "6px" }}>
            Enter your phone number to recover password.
          </Text>
        </div>
        <ForgotPasswordImage style={{ marginTop: "20px" }} />
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <Form style={{ width: "100%" }}>
              <div className={styles.Wrapper}>
                <div className={styles.InputWrapper}>
                  <Input
                    name="phoneNumber"
                    placeholder="Your Phone Number"
                    label="Enter Phone Number"
                    id="phoneNumber"
                    value={values.phoneNumber}
                    error={!!(touched.phoneNumber && errors.phoneNumber)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {touched.phoneNumber && errors.phoneNumber && (
                    <Text variant="caption" color={colors.error}>
                      {errors.phoneNumber}
                    </Text>
                  )}
                </div>
                <Button
                  isLoading={isLoading}
                  onClick={handleSubmit}
                  buttonStyles={{ marginTop: "50px" }}
                >
                  Send
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        {/* <Button onClick={onOpen}>open</Button> */}
      </div>
      <Modal isOpen={open} onClose={() => setOpen(false)}>
        <ModalOverlay />
        <ModalContent style={{height: "400px"}}>
          fkwfflksf
        </ModalContent>
      </Modal>
    </AuthContainer>
  );
}

export default ForgotPassword;
