import React from "react";
import {
  useGetAllSubjectsQuery,
  useGetNumberOfTopicsQuery,
  useGetSubjectLessonsQuery,
  useGetSubjectQuery,
  useGetTopicsByGradeQuery,
} from "../../redux/features/lessons/lessonsApiSlice";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./Subject.module.scss";
import { ReactComponent as GoBackIcon } from "../../assets/icons/chevron-left.svg";
import { ReactComponent as EnglishIcon } from "../../assets/icons/bookOpenIcon.svg";
import { ReactComponent as MathIcon } from "../../assets/icons/bookStackIcon.svg";
import { ReactComponent as ScienceIcon } from "../../assets/icons/scienceThingyIcon.svg";
import { ReactComponent as SortIcon } from "../../assets/icons/sortIcon.svg";
import Text from "../../components/UI/atoms/Text";
import { StringHelper } from "../../utils/stringHelper";
import { subjects } from "../../constants/values";
import { Skeleton, SkeletonCircle, SkeletonText } from "@chakra-ui/react";
import { colors } from "../../sdk/colors";
import TopicItem from "../../components/UI/molecules/TopicItem";
import { ReactComponent as EmptyImage } from "../../assets/images/emptyImage.svg";
import { useGetUserQuery } from "../../redux/features/user/userApiSlice";

function Subject() {
  const { data: user } = useGetUserQuery(undefined);
  const params = useParams<{ subjectId: string }>();
  const { isLoading, data } = useGetSubjectQuery(Number(params.subjectId));
  const { isLoading: isSubjectLessonsLoading, data: subjectLessonsData } =
    useGetSubjectLessonsQuery(Number(params.subjectId));

  console.log(subjectLessonsData);

  const { data: topicsNum, isLoading: isTopicsNumLoading } =
    useGetNumberOfTopicsQuery(
      {
        subjectId: Number(params.subjectId),
        grade: !!user ? StringHelper.gradeNumberToString(user.grade) : "first",
      },
      { refetchOnMountOrArgChange: true }
    );

  console.log(topicsNum);

  const {
    data: topics,
    error: errorTbg,
    isLoading: isTopicsLoading,
    isSuccess: isGetTopicsSuccess,
  } = useGetTopicsByGradeQuery(
    {
      subjectId: Number(params.subjectId),
      grade: !!user ? StringHelper.gradeNumberToString(user.grade) : "first",
    },
    { refetchOnMountOrArgChange: true }
  );
  console.log(topics, "tbg", errorTbg);

  const navigate = useNavigate();
  console.log(data);
  let itemIcon: JSX.Element | undefined;

  switch (data?.name) {
    case "english":
      itemIcon = <EnglishIcon />;
      break;
    case "Mathematics":
      itemIcon = <MathIcon />;
      break;
    case "Sciences":
      itemIcon = <ScienceIcon />;
      break;
    default:
      itemIcon = undefined;
  }
  return (
    <div className={styles.Subject}>
      <div className={styles.Header}>
        <button onClick={() => navigate(-1)} className={styles.GoBackButton}>
          <GoBackIcon />
        </button>
      </div>
      <div className={styles.Container}>
        <div className={styles.SubHeader}>
          <div className={styles.TitleWrapper}>
            <SkeletonCircle
              size={"50px"}
              style={{ marginRight: "20px" }}
              isLoaded={!isLoading}
              startColor={colors.grey5}
              endColor={colors.grey4}
            >
              <div
                style={{
                  background: subjects.find(
                    (subject) => subject.id === (data?.id || 1)
                  )?.color,
                }}
                className={styles.IconWrapper}
              >
                {itemIcon}
              </div>
            </SkeletonCircle>
            <SkeletonText
              noOfLines={2}
              isLoaded={!isLoading || !isTopicsNumLoading}
              startColor={colors.grey5}
              endColor={colors.grey4}
            >
              <span>
                <Text
                  fontWeight="light"
                  style={{ textTransform: "capitalize" }}
                  variant="heading 3"
                >
                  {data?.name}
                </Text>
                <p id={styles.LessonsCount}>
                  {StringHelper.pluralize(
                    Number(topicsNum?.data?.number_of_lessons || 0),
                    "topic"
                  )}
                </p>
              </span>
            </SkeletonText>
          </div>
          {/* {!isLoading && (
            <button>
              <SortIcon />
            </button>
          )} */}
        </div>
        <div className={styles.TopicsWrapper}>
          {topics?.data.map((topic, index) => (
            <TopicItem
              subjectId={Number(params.subjectId)}
              index={index + 1}
              key={topic.id}
              topic={topic}
              count={Number(topicsNum?.data.number_of_topics)}
            />
          ))}
          {isTopicsLoading && (
            <>
              <Skeleton
                isLoaded={false}
                borderRadius="10px"
                className={styles.TopicSkeleton}
                startColor={colors.grey5}
                endColor={colors.grey4}
              />
              <Skeleton
                isLoaded={false}
                borderRadius="10px"
                className={styles.TopicSkeleton}
                startColor={colors.grey5}
                endColor={colors.grey4}
              />
              <Skeleton
                isLoaded={false}
                borderRadius="10px"
                className={styles.TopicSkeleton}
                startColor={colors.grey5}
                endColor={colors.grey4}
              />
              <Skeleton
                isLoaded={false}
                borderRadius="10px"
                className={styles.TopicSkeleton}
                startColor={colors.grey5}
                endColor={colors.grey4}
              />
              <Skeleton
                isLoaded={false}
                borderRadius="10px"
                className={styles.TopicSkeleton}
                startColor={colors.grey5}
                endColor={colors.grey4}
              />
              <Skeleton
                isLoaded={false}
                borderRadius="10px"
                className={styles.TopicSkeleton}
                startColor={colors.grey5}
                endColor={colors.grey4}
              />
            </>
          )}
        </div>
        {isGetTopicsSuccess && topics?.data?.length === 0 ? (
          <div className={styles.Empty}>
            <EmptyImage style={{ height: "250px", width: "250px" }} />
            <Text variant="heading 2">Nothing to see here</Text>
          </div>
        ) : undefined}
      </div>
    </div>
  );
}

export default Subject;
