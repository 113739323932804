import React, { useContext, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import styles from "./CreateProfile.module.scss";
import PageHeader from "../../components/UI/molecules/PageHeader";
import Input from "../../components/UI/atoms/Input";
import Button from "../../components/UI/atoms/Button";
import {
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { ReactComponent as MenuDown } from "../../assets/icons/menuDrown.svg";
import { grades, subscriptionPlans } from "../../constants/values";
import Star from "../../assets/icons/Star";
import Text from "../../components/UI/atoms/Text";
import { useCreateProfileMutation } from "../../redux/features/profile/profileApiSlice";
import { CreateProfileBody } from "../../models/profile";
import { colors } from "../../sdk/colors";
import { useToast } from "../../context/toast.context";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CreateProfileImage } from "../../assets/images/createProfileImage.svg";
import { ErrorResponse } from "../../models/utilityTypes";
import { SubPlansTypes } from "../../models/subscription";
import SubscriptionPlan from "../../components/organisms/SubscriptionPlan";
import LoaderContext from "../../context/loader.context";
import { useGetPlansQuery } from "../../redux/features/subscription/subscriptionApiSlice";

function CreateProfile() {
  const initialValues: CreateProfileBody = {
    firstName: "",
    lastName: "",
  };

  const { data: plans } = useGetPlansQuery(undefined);

  const { show } = useToast();
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    firstName: Yup.string().required("This is a required field."),
    lastName: Yup.string().required("This is a required field."),
  });

  const [createProfile, { isLoading }] = useCreateProfileMutation();
  const {
    isOpen: isGradeOpen,
    onOpen: onGradeOpen,
    onClose: onGradeClose,
  } = useDisclosure();
  const {
    isOpen: isPlanOpen,
    onOpen: onPlanOpen,
    onClose: onPlanClose,
  } = useDisclosure();
  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure();

  const [gradeValue, setGradeValue] = useState<
    { value: number; label: string } | undefined
  >();
  const [createProfileInfo, setCreateProfileInfo] = useState<
    CreateProfileBody | undefined
  >();


  const [plan, setPlan] = useState<SubPlansTypes>("free");

  const onSubmit = async (values: CreateProfileBody) => {
    const submitValues: CreateProfileBody = {
      firstName: values.firstName.trim(),
      lastName: values.lastName.trim(),
      grade: Number(gradeValue?.value),
    };
    setCreateProfileInfo(submitValues);
    onDrawerOpen();
  };

  // const handleCreateProfile = async (values: CreateProfileBody) => {
  //   const submitValues: CreateProfileBody = {
  //     firstName: values.firstName.trim(),
  //     lastName: values.lastName.trim(),
  //     grade: Number(gradeValue?.value),
  //   };
  //   try {
  //     const response = await createProfile(submitValues).unwrap();
  //     console.log(response);
  //     navigate("/");
  //     show({ message: `${response.message}`, variant: "success" });
  //   } catch (e: any) {
  //     console.log(e);
  //     const error: ErrorResponse = e;
  //     show({ message: `${error.data.message}`, variant: "error" });
  //   }
  // };

  return (
    <>
      <div className={styles.CreateProfile}>
        <PageHeader pageTitle="Setup Student Profile" />
        <div className={styles.Container}>
          <CreateProfileImage
            style={{ margin: "auto", marginBottom: "50px" }}
          />
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <Form>
                <div className={styles.Form}>
                  <div className={styles.Row}>
                    <div className={styles.InputWrapper}>
                      <Input
                        label="First Name"
                        name="firstName"
                        id="firstName"
                        value={values.firstName}
                        error={!!(touched.firstName && errors.firstName)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {touched.firstName && errors.firstName && (
                        <Text variant="caption" color={colors.error}>
                          {errors.firstName}
                        </Text>
                      )}
                    </div>
                    <div className={styles.InputWrapper}>
                      <Input
                        label="Last Name"
                        name="lastName"
                        id="lastName"
                        value={values.lastName}
                        error={!!(touched.lastName && errors.lastName)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {touched.lastName && errors.lastName && (
                        <Text variant="caption" color={colors.error}>
                          {errors.lastName}
                        </Text>
                      )}
                    </div>
                  </div>
                  <div className={styles.InputWrapper}>
                    <Input
                      readOnly
                      label="Grade"
                      value={gradeValue?.label || ""}
                      onClick={onGradeOpen}
                      inputAdornment={<MenuDown />}
                    />
                  </div>
                  <div className={styles.InputWrapper}>
                    <Input
                      readOnly
                      label="Plan"
                      onClick={onPlanOpen}
                      inputAdornment={<MenuDown />}
                      value={plan}
                    />
                  </div>
                  <Button
                    isLoading={isLoading}
                    disabled={!gradeValue || !plan}
                    onClick={handleSubmit}
                    buttonStyles={{ marginTop: "70px" }}
                  >
                    Next
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <Modal isOpen={isGradeOpen} onClose={onGradeClose}>
        <ModalOverlay />
        <ModalContent
          style={{ maxWidth: "500px", marginLeft: "30px", marginRight: "30px" }}
        >
          <div className={styles.GradeWrapper}>
            {grades.map((grade, index) => (
              <button
                onClick={() => {
                  setGradeValue({ value: grade.value, label: grade.label });
                  onGradeClose();
                }}
                key={index}
                className={styles.GradeItem}
              >
                <Star color={grade.color} />
                <Text>{grade.label}</Text>
              </button>
            ))}
          </div>
        </ModalContent>
      </Modal>
      <Modal isOpen={isPlanOpen} onClose={onPlanClose}>
        <ModalOverlay />
        <ModalContent
          style={{ maxWidth: "500px", marginLeft: "30px", marginRight: "30px" }}
        >
          <div className={styles.GradeWrapper}>
            {subscriptionPlans.map((plan, index) => (
              <button
                onClick={() => {
                  setPlan(plan);
                  onPlanClose();
                }}
                key={index}
                className={styles.GradeItem}
              >
                <Text>{plan}</Text>
              </button>
            ))}
          </div>
        </ModalContent>
      </Modal>

      <Drawer
        placement="bottom"
        size="full"
        isOpen={isDrawerOpen}
        onClose={onDrawerClose}
      >
        <DrawerOverlay />
        <DrawerContent>
          <SubscriptionPlan
            type="profile-creation"
            info={createProfileInfo}
            plan={plan}
            onClose={onDrawerClose}
          />
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default CreateProfile;
