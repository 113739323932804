import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import styles from "./SubscriptionPlan.module.scss";
import PageHeader from "../../UI/molecules/PageHeader";
import WelcomeImge from "../../../assets/images/welcomeImage.svg";
import { CreateProfileBody } from "../../../models/profile";
import { Plan, SubPlansTypes } from "../../../models/subscription";
import Text from "../../UI/atoms/Text";
import Tag from "../../UI/atoms/Tag";
import { useGetPlansQuery } from "../../../redux/features/subscription/subscriptionApiSlice";
import { Swiper, SwiperRef, SwiperSlide, useSwiper } from "swiper/react";
import { Pagination } from "swiper/modules";

import PrevButton from "../../../assets/images/prevButton.svg";
import NextButton from "../../../assets/images/nextButton.svg";
import SubscriptionPlanItem from "../../UI/molecules/PageHeader/SubscriptionPlanItem";
import SubPlanBook from "../../../assets/icons/subbPlanBook.svg";
import SubPlanVideo from "../../../assets/icons/subPlanVideo.svg";
import SubPlanChampionship from "../../../assets/icons/subPlanChampion.svg";
import SubPlanAward from "../../../assets/icons/subPlanAward.svg";
import Button from "../../UI/atoms/Button";
import { StringHelper } from "../../../utils/stringHelper";
import {
  useCreateProfileMutation,
  useSwitchProfileMutation,
} from "../../../redux/features/profile/profileApiSlice";
import { ErrorResponse } from "../../../models/utilityTypes";
import { useToast } from "../../../context/toast.context";
import { createSearchParams, useNavigate } from "react-router-dom";
import { colors } from "../../../sdk/colors";
import LoaderContext from "../../../context/loader.context";
import { useAppDispatch } from "../../../redux/hooks";
import {
  saveProfileId,
  setProfileTokens,
  updateUserRole,
} from "../../../redux/features/auth/authSlice";

function SubscriptionPlan({
  onClose,
  info,
  plan,
  type,
}: {
  onClose: () => void;
  info: CreateProfileBody | undefined;
  plan: SubPlansTypes;
  type: "profile-creation" | "subscription-renewal";
}) {
  const { isLoading, data, isSuccess } = useGetPlansQuery(undefined);

  const swiperRef = useRef<SwiperRef>(null);

  const goToSlide = (index: number) => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(index);
    }
  };

  const { show } = useToast();
  const { setLoader: setLoading } = useContext(LoaderContext);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [createProfile, { isLoading: isCreateProfileLoading }] =
    useCreateProfileMutation();

  const [switchProfile, { isLoading: isSwitchProfileLoading }] =
    useSwitchProfileMutation();

  const [selectedPlanId, setSelectedPlanId] = useState<string>("");

  const plansData: Plan[] = useMemo(
    () => [
      {
        amount: "0.00",
        id: "4d67207b-2f8d-426b-859a1-4f33v130sdbe",
        interval: "forever",
        name: "free",
      },
      ...(data?.data || []),
    ],
    [isSuccess]
  );

  useEffect(() => {
    if (isSuccess) {
      setSelectedPlanId(plansData.find((item) => item.name === plan)?.id || "");
    }
  }, [isSuccess]);

  const handleSwitchProfile = async (id: number) => {
    onClose();
    try {
      setLoading(true);
      const response = await switchProfile(id).unwrap();
      setLoading(false);
      console.log(response);
      // show({ message: "ldsldkld", variant: "success" });
      dispatch(setProfileTokens(response.token));
      dispatch(updateUserRole("child"));
      dispatch(saveProfileId(id));
      show({ message: `Profile successfully created`, variant: "success" });
      navigate("/app/learn", { replace: true });
    } catch (e: any) {
      setLoading(false);
      console.log(e);
      const error: ErrorResponse = e;
      show({ message: `${error.data.message}`, variant: "error" });
    }
  };

  const onSubmit = async () => {
    const submitValues: CreateProfileBody = {
      firstName: info?.firstName || "",
      lastName: info?.lastName || "",
      grade: info?.grade,
    };
    try {
      const response = await createProfile(submitValues).unwrap();
      console.log(response, "create profile");

      handleSwitchProfile(Number(response?.data?.id));
    } catch (e: any) {
      setLoading(false);
      console.log(e);
      const error: ErrorResponse = e;
      show({ message: `${error.data.message}`, variant: "error" });
    }
  };

  const handleContinueToPayment = () => {
    navigate({
      pathname: "/app/guardian/payment",
      search: createSearchParams({
        plan: selectedPlanId,
        firstName: `${info?.firstName}`,
        lastName: `${info?.lastName}`,
        grade: `${info?.grade}`,
        type,
        profileId: `${info?.profileId || ""}`,
      }).toString(),
    });
  };

  const isPlanFree: boolean =
    selectedPlanId === "4d67207b-2f8d-426b-859a1-4f33v130sdbe";

  console.log(isPlanFree, selectedPlanId);

  const isSelected = (id: string): boolean => {
    return id === selectedPlanId;
  };

  const handleSelect = (id: string) => {
    setSelectedPlanId(id);
  };

  console.log(data);

  const planName: SubPlansTypes = useMemo(
    () => plansData.find((item) => item.id === selectedPlanId)?.name || "free",
    [selectedPlanId]
  );

  let plansContent: React.ReactNode;

  if (planName !== "free") {
    plansContent = (
      <>
        <div className={styles.ContentItem}>
          <img src={SubPlanBook} alt="" />
          <div className={styles.TextWrapper}>
            <Text variant="heading 5" style={{ marginBottom: "4px" }}>
              Schedule Personal Study Time
            </Text>
            <Text variant="caption" color="#111">
              Student can set UBELA study time reminders and schedules.
            </Text>
          </div>
        </div>
        <div className={styles.ContentItem}>
          <img src={SubPlanVideo} alt="" />
          <div className={styles.TextWrapper}>
            <Text variant="heading 5" style={{ marginBottom: "4px" }}>
              Unlimited Access To Videos
            </Text>
            <Text variant="caption" color="#111">
              Student can watch and learn from as many lesson videos as they
              want anytime.
            </Text>
          </div>
        </div>
        <div className={styles.ContentItem}>
          <img src={SubPlanChampionship} alt="" />
          <div className={styles.TextWrapper}>
            <Text variant="heading 5" style={{ marginBottom: "4px" }}>
              Access the Championships Leaderboard
            </Text>
            <Text variant="caption" color="#111">
              When students complete specific tasks, they can earn badges.
            </Text>
          </div>
        </div>
        <div className={styles.ContentItem}>
          <img src={SubPlanAward} alt="" />
          <div className={styles.TextWrapper}>
            <Text variant="heading 5" style={{ marginBottom: "4px" }}>
              Win Badges and Streaks
            </Text>
            <Text variant="caption" color="#111">
              Accumulate badges and points from quizzes to win cool prizes.
            </Text>
          </div>
        </div>
      </>
    );
  } else {
    plansContent = (
      <>
        <div className={styles.ContentItem}>
          <img src={SubPlanBook} alt="" />
          <div className={styles.TextWrapper}>
            <Text variant="heading 5" style={{ marginBottom: "4px" }}>
              Schedule Personal Study Time
            </Text>
            <Text variant="caption" color="#111">
              Student can set UBELA study time reminders and schedules.
            </Text>
          </div>
        </div>
        <div className={styles.ContentItem}>
          <img src={SubPlanVideo} alt="" />
          <div className={styles.TextWrapper}>
            <Text variant="heading 5" style={{ marginBottom: "4px" }}>
              Playback up to 3 minutes for each video
            </Text>
            <Text variant="caption" color="#111">
              Student can watch and learn from as many lesson videos as they
              want anytime.
            </Text>
          </div>
        </div>
        <div className={styles.ContentItem}>
          <img src={SubPlanChampionship} alt="" />
          <div className={styles.TextWrapper}>
            <Text variant="heading 5" style={{ marginBottom: "4px" }}>
              Access to 5 championship questions
            </Text>
            <Text variant="caption" color="#111">
              When students complete specific tasks, they can earn badges.
            </Text>
          </div>
        </div>
      </>
    );
  }

  useEffect(() => {
    if (plan === "free") {
      goToSlide(0);
    } else if (plan === "3 months plan") {
      goToSlide(2);
    } else if (plan === "6 months plan") {
      goToSlide(3);
    } else if (plan === "12 months plan") {
      goToSlide(4);
    }
  }, []);

  return (
    <div className={styles.SubscriptionPlan}>
      <PageHeader
        handleGoBack={onClose}
        pageTitle="Get Your Subscription Plan"
      />
      <div className={styles.Container}>
        <img src={WelcomeImge} alt="" />
        <div className={styles.Intro}>
          <Text>Get a learning plan for {info?.firstName}</Text>
          <Tag>{`Primary ${info?.grade}`}</Tag>
        </div>
        <Swiper
          ref={swiperRef}
          id={styles.SwiperWrapper}
          pagination={true}
          modules={[Pagination]}
          className="mySwiper"
        >
          <SwiperButtonPrevious />
          <SwiperButtonNext />
          {data?.data
            ? plansData.map((plan) => (
                <SwiperSlide id={styles.SwiperItem} key={plan.id}>
                  <button onClick={() => handleSelect(plan.id)}>
                    <SubscriptionPlanItem
                      plan={plan}
                      isSelected={isSelected(plan.id)}
                    />
                  </button>
                </SwiperSlide>
              ))
            : undefined}
        </Swiper>
        <div className={styles.Wrapper}>
          {plansContent}
          {isPlanFree ? (
            <Text
              style={{
                width: "70%",
                margin: "0 auto",
                textAlign: "center",
                marginBottom: "33px",
              }}
              variant="heading 6"
            >
              Free forever, To access full features, move to a paid plan
              starting from 8750 NGN, Quaterly. You can cancel anytime
            </Text>
          ) : undefined}
        </div>
        <div className={styles.ButtonsWrapper}>
          <Button
            disabled={!selectedPlanId}
            onClick={() => {
              if (isPlanFree) {
                if (type === "profile-creation") {
                  onSubmit();
                } else {
                  onClose();
                }
              } else {
                handleContinueToPayment();
              }
            }}
            isLoading={isCreateProfileLoading}
          >
            {isPlanFree
              ? "Continue with free plan"
              : `Pay ${StringHelper.stringToMoneyFormat(
                  data?.data.find((item) => item.id === selectedPlanId)
                    ?.amount || "",
                  true
                )}`}
          </Button>
          <button
            onClick={onClose}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "20px",
              width: "100%",
            }}
          >
            <Text fontWeight="medium" color={colors.grey3}>
              No Thanks
            </Text>
          </button>
        </div>
      </div>
    </div>
  );
}

const SwiperButtonPrevious = () => {
  const swiper = useSwiper();
  return (
    <button onClick={() => swiper.slidePrev()} id={styles.PrevButton}>
      <img src={PrevButton} alt="" />
    </button>
  );
};
const SwiperButtonNext = () => {
  const swiper = useSwiper();
  return (
    <button onClick={() => swiper.slideNext()} id={styles.NextButton}>
      <img src={NextButton} alt="" />
    </button>
  );
};

export default SubscriptionPlan;
