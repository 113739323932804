import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { openDB } from "idb";
import PageHeader from "../../components/UI/molecules/PageHeader";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import styles from "./Topic.module.scss";
import ReactPlayer from "react-player";
import {
  useFinishLessonMutation,
  useGetLessonQuery,
  useStartLessonMutation,
  useStartTopicMutation,
} from "../../redux/features/lessons/lessonsApiSlice";
import { ReactComponent as PlayIcon } from "../../assets/icons/playIcon.svg";
import { ErrorResponse } from "../../models/utilityTypes";
import Text from "../../components/UI/atoms/Text";
import { colors } from "../../sdk/colors";
import { ReactComponent as DownloadIcon } from "../../assets/icons/downloadIcon.svg";
import Button from "../../components/UI/atoms/Button";
import {
  CircularProgress,
  Modal,
  ModalContent,
  ModalOverlay,
  SkeletonText,
  Spinner,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import useVideoStorage from "../../hooks/useVideoStorage";
import { Question } from "../../models/championship";
import useFetchVideos from "../../hooks/useFetchVideos";
import { ReactComponent as CheckMark } from "../../assets/icons/checkMark.svg";
import { ReactComponent as RestrictAccessImage } from "../../assets/images/restrictAccessImage.svg";
import { useGetUserQuery } from "../../redux/features/user/userApiSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { Lesson as LessonType } from "../../models/lessons";
import {
  handleFinishedLesson,
  handleUnFinishedLesson,
} from "../../redux/features/lessons/lessonsSlice";
import { RootState } from "../../redux/store";
import VideoPlayer from "../../components/organisms/VideoPlayer";
import { useTitle } from "react-use";
import { removeItemIfExists } from "../../utils/removeCopies";

function Lesson() {
  const [searchParams] = useSearchParams();

  const {
    isLoading: videoLoading,
    videos,
    isSuccess: isFetchVideoSuccess,
  } = useFetchVideos();

  const dispatch = useAppDispatch();

  const { data: user } = useGetUserQuery(undefined);

  const finishedLessons = useAppSelector(
    (state: RootState) => state.lessons.finishedLessons
  );

  const profileId = useAppSelector(
    (state: RootState) => state.auth.activeProfileId
  );

  const [
    startLesson,
    { isError: isStartLessonError, isSuccess: isStartLessonSuccess },
  ] = useStartLessonMutation();

  const [
    finishLesson,
    {
      isError: isFinishLessonError,
      isSuccess: isFinishLessonSuccess,
      isUninitialized,
    },
  ] = useFinishLessonMutation();

  const [
    startTopic,
    { isError: isStartTopicError, isSuccess: isStartTopicSuccess },
  ] = useStartTopicMutation();

  const navigate = useNavigate();
  const params = useParams<{ lessonId: string }>();

  console.log(params.lessonId);

  const { isLoading, data, error, isError, isSuccess, refetch } =
    useGetLessonQuery(Number(params.lessonId));
  console.log(data, error);

  const show = useToast();

  const downloadedLesson = videos.find(
    (item) => item.videoId === `${Number(params.lessonId) + Number(profileId)}`
  );

  const hasBeenDownloaded: boolean = !!downloadedLesson;

  // useEffect(() => {
  //   console.log("triggered");
  //   console.log();
  //   if (isError && !videoLoading && !hasBeenDownloaded) {
  //     if (
  //       (
  //         (error as ErrorResponse)?.data?.message ||
  //         (error as ErrorResponse)?.data?.error?.toLowerCase()
  //       )?.toLowerCase() === "forbidden resource"
  //     ) {
  //       window.location.reload();
  //     } else {
  //       show({
  //         message: `${
  //           (error as ErrorResponse)?.data?.message ||
  //           (error as ErrorResponse)?.data?.error
  //         }`,
  //         variant: "error",
  //       });
  //     }
  //   }
  // }, [isError, videoLoading]);

  const handleStartLesson = useCallback(async () => {
    console.log(data?.data, "from Lesson");
    const submitValues: { lessonId: number; topicId: number } = {
      lessonId: data?.data.id || 0,
      topicId: data?.data.lessonId || 0,
    };
    console.log(submitValues, "submitValuesLessonStart");
    try {
      const response = await startLesson(submitValues).unwrap();
      console.log(response, "started");
    } catch (e) {
      console.log(e);
    }
  }, [isStartLessonSuccess, data?.data]);

  const [lessonFinished, setLessonFinished] = useState(false);

  const handleFinishLesson = useCallback(async () => {
    console.log(data?.data, "from Lesson");
    const submitValues: { lessonId: number; topicId: number } = {
      lessonId: data?.data.id || 0,
      topicId: data?.data.lessonId || 0,
    };
    console.log(submitValues, "submitValuesLessonStart");
    try {
      const response = await finishLesson(submitValues).unwrap();
      console.log(response, "finished");
    } catch (e) {
      console.log(e);
    }
  }, [isFinishLessonError, data?.data]);

  const handleStartTopic = useCallback(async () => {
    try {
      const response = await startTopic(data?.data.lessonId || 0).unwrap();
      console.log(response);
    } catch (e) {
      console.log(e);
    }
  }, [data?.data]);

  useEffect(() => {
    console.log("triggered");
    if (data?.data) {
      if (!isStartTopicSuccess) {
        handleStartTopic();
      }
      if (!isStartLessonSuccess && !isStartLessonError) {
        handleStartLesson();
      }
    }
  }, [data?.data]);

  const [downloaded, setDownloaded] = useState(false);

  const [progress, setProgress] = useState(0);

  const initializeDB = async () => {
    return openDB("video-download", 11, {
      upgrade(db) {
        if (!db.objectStoreNames.contains("videos")) {
          db.createObjectStore("videos", { keyPath: "videoId" });
        }
      },
    });
  };

  const downloadVideo = async (
    videoUrl: string,
    videoName: string,
    videoId: string,
    thumbnailUrl: string,
    description: string,
    quizzes: Question[] | undefined,
    profileId: number,
    subjectId: number
  ): Promise<void> => {
    try {
      const response: Response = await fetch(videoUrl);
      if (!response.ok) throw new Error("Network response was not ok");

      const totalSize: number = parseInt(
        response.headers.get("Content-Length") || "0",
        10
      );
      if (!totalSize) throw new Error("Failed to get content length");

      let downloadedSize: number = 0;
      const chunks: Uint8Array[] = [];
      const reader: ReadableStreamDefaultReader<Uint8Array> | undefined =
        response.body?.getReader();
      if (!reader) throw new Error("Failed to get reader");

      show({
        title:
          "Download started. Please don't leave this page till it is finished.",
        status: "info",
        position: "bottom-left",
        isClosable: true,
      });

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        if (value) {
          downloadedSize += value.length;
          setProgress(Math.round((downloadedSize / totalSize) * 100));
          chunks.push(value);
        }
      }

      const videoBlob: Blob = new Blob(chunks, {
        type: response.headers.get("Content-Type") || "video/mp4",
      });

      const db = await initializeDB();
      const transaction = db.transaction("videos", "readwrite");
      const store = transaction.objectStore("videos");

      await store.put({
        videoId,
        data: videoBlob,
        name: videoName,
        thumbnailUrl,
        description,
        quizzes,
        profileId,
        subjectId,
      });

      await transaction.done;

      show({
        title: "Downloaded successfully",
        status: "success",
        position: "bottom-left",
        isClosable: true,
      });
      setDownloaded(true);
    } catch (error: any) {
      console.error("Error downloading video:", error);
      show({
        title: `Error: ${(error as { message: string })?.message}`,
        status: "error",
        position: "bottom-left",
      });
    }
  };

  useTitle(data?.data.title || "");

  console.log(hasBeenDownloaded, "video fetch");

  const videoPlayerRef = useRef<ReactPlayer>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const restrictAccess = useCallback(() => {
    if (videoPlayerRef?.current) {
      videoPlayerRef.current.getInternalPlayer().pause();
    }
    onOpen();
  }, [isOpen]);

  const handleLessonUnfinished = (item: LessonType | undefined) => {
    if (!!item) {
      dispatch(handleUnFinishedLesson(item));
      // dispatch(handleFinishedLesson(removeItemIfExists(finishedLessons, item)))
    }
  };

  const handleLessonFinished = useCallback(
    (item: LessonType | undefined) => {
      if (!!item) {
        dispatch(handleFinishedLesson(item));
      }
    },
    [isFinishLessonSuccess, isFinishLessonError]
  );

  const hasQuizzes = (): boolean => {
    if (hasBeenDownloaded && isError) {
      if (downloadedLesson?.quizzes && downloadedLesson.quizzes.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      if (data?.data?.quizzes && data.data.quizzes.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  console.log(hasBeenDownloaded, videos);

  useEffect(() => {
    if (lessonFinished && !isFinishLessonSuccess) {
      handleFinishLesson();
    }
  }, [lessonFinished, isFinishLessonSuccess]);

  const handleEndedVideo = () => {
    if (hasQuizzes()) {
      return;
    }
    console.log("shiiit");
    setLessonFinished(true);

    handleLessonFinished(
      data?.data
        ? {
            id: data.data.id,
            lessonId: data.data.id + Number(user?.id || profileId),
            title: data.data.title,
            thumbnail: data.data.thumbnail,
            profileId: user?.id || profileId,
          }
        : hasBeenDownloaded
        ? {
            id: Number(downloadedLesson?.videoId) - Number(profileId),
            lessonId: Number(downloadedLesson?.videoId),
            title: `${downloadedLesson?.name}`,
            thumbnail: `${downloadedLesson?.thumbnailUrl}`,
            profileId: profileId,
            progress: progress,
            subjectId: Number(searchParams.get("subjectId")),
          }
        : undefined
    );
  };

  console.log(profileId, "profile id");

  const handleStartedVideo = (progress?: number) => {
    handleLessonUnfinished(
      data?.data
        ? {
            id: data.data.id,
            lessonId: data.data.id + Number(user?.id || profileId),
            title: data.data.title,
            thumbnail: data.data.thumbnail,
            profileId: user?.id || profileId,
            progress: progress,
            subjectId: Number(searchParams.get("subjectId")),
          }
        : hasBeenDownloaded
        ? {
            id: Number(downloadedLesson?.videoId) - Number(profileId),
            lessonId: Number(downloadedLesson?.videoId),
            title: `${downloadedLesson?.name}`,
            thumbnail: `${downloadedLesson?.thumbnailUrl}`,
            profileId: profileId,
            progress: progress,
            subjectId: Number(searchParams.get("subjectId")),
          }
        : undefined
    );
  };

  const handleRestrictAccess = ({ playedSeconds, played }) => {
    if (played < 0.9) {
      console.log(played);
      handleStartedVideo(played);
    }
    if (playedSeconds > 60 && !hasBeenDownloaded && !user?.subscription) {
      restrictAccess();
    }
  };

  const handleNavigateToQuiz = () => {
    navigate({
      pathname: `/app/learn/subjects/lessons/${params.lessonId}/quiz`,
      search: createSearchParams({
        id: `${data?.data?.id || 0}`,
      }).toString(),
    });
  };

  return (
    <>
      <div className={styles.Lesson}>
        <div className={styles.Header}>
          <PageHeader pageTitle="" handleGoBack={() => navigate(-1)} />
        </div>
        <VideoPlayer
          isError={isError}
          isFetchVideoSuccess={isFetchVideoSuccess}
          isLoading={isLoading}
          isVideoLoading={videoLoading}
          handleEndedVideo={handleEndedVideo}
          handleStartedVideo={handleStartedVideo}
          hasBeenDownloaded={hasBeenDownloaded}
          lessonId={data?.data.id}
          downloadedUrl={useMemo(
            () =>
              downloadedLesson?.data
                ? URL.createObjectURL(downloadedLesson?.data)
                : undefined,
            [isFetchVideoSuccess]
          )}
          videoPlayerRef={videoPlayerRef}
          url={useMemo(() => data?.data?.video?.url, [isSuccess])}
          handleRestrictAccess={handleRestrictAccess}
        />
        <div className={styles.Container}>
          <div className={styles.ContentHeader}>
            <SkeletonText noOfLines={2} isLoaded={!isLoading}>
              <div className={styles.TextWrapper}>
                <Text style={{ fontSize: "12px", color: colors.grey3 }}>
                  Lesson
                </Text>
                <Text variant="heading 3" fontWeight="medium">
                  {hasBeenDownloaded
                    ? downloadedLesson?.name
                    : data?.data.title}
                </Text>
              </div>
            </SkeletonText>
            {isSuccess && (
              <button
                style={{
                  position: "relative",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
                disabled={hasBeenDownloaded || downloaded || progress > 0}
                onClick={() => {
                  if (!user?.subscription) {
                    onOpen();
                  } else {
                    downloadVideo(
                      `${data?.data?.video?.url}`,
                      `${data?.data?.title}`,
                      `${data.data.id + Number(user.id)}`,
                      `${data.data.thumbnail}`,
                      `${data?.data?.description || ""}`,
                      data?.data?.quizzes,
                      user.id,
                      Number(searchParams.get("subjectId"))
                    );
                  }
                }}
              >
                {progress > 0 && (
                  <CircularProgress
                    style={{ position: "absolute" }}
                    value={progress}
                    size={12}
                    color={colors.primary}
                  />
                )}
                {hasBeenDownloaded || downloaded ? (
                  <CheckMark />
                ) : (
                  <DownloadIcon />
                )}
              </button>
            )}
          </div>
          <Text
            style={{ textAlign: "start" }}
            variant="heading 5"
            color={colors.grey2}
          >
            {hasBeenDownloaded
              ? downloadedLesson?.description
              : data?.data.description}
          </Text>
          {hasQuizzes() && (
            <Button
              onClick={handleNavigateToQuiz}
              buttonStyles={{ marginTop: "50px" }}
            >
              Start Quiz
            </Button>
          )}
        </div>
      </div>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderRadius="10px" marginLeft="20px" marginRight="20px">
          <div className={styles.RestrictAccessModal}>
            <RestrictAccessImage width={"70%"} />
            <div className={styles.RestrictAccessModalContentWrapper}>
              <Text style={{ textAlign: "center" }}>
                Pay for a subscription to continue
              </Text>
              <Button
                onClick={() => {
                  navigate(
                    `/app/guardian/manage-profiles/${user?.id || profileId}`
                  );
                }}
                variant="white"
              >
                Pay for subscription
              </Button>
            </div>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Lesson;
