import React, { useEffect, useState } from "react";
import styles from "./Landing.module.scss";
import Header from "../../components/UI/molecules/LandingPageHeader";
import HeroBackground from "../../assets/images/HeroBackground.svg";
import MobileHeroBackground from "../../assets/images/MobileHeroBackground.svg";
import BlackAppleLogo from "../../assets/images/blackAppleLogo.svg";
import WhitePlaystore from "../../assets/images/whitePlaystoreLogo.svg";
import WhiteAppleLogo from "../../assets/images/whiteAppleLogo.svg";
import BlackPlaystore from "../../assets/images/blackPlaystoreLogo.svg";
import HeroDecor1 from "../../assets/images/heroDecor1.svg";
import HeroImage1 from "../../assets/images/heroImage1.svg";
import HeroImage2 from "../../assets/images/heroImage2.svg";
import HeroImage3 from "../../assets/images/heroImage3.svg";
import { motion } from "framer-motion";
import AboutImage from "../../assets/images/AboutImage.svg";
import FeatureCard1 from "../../assets/images/FeatureCard1.svg";
import FeatureCard2 from "../../assets/images/FeatureCard2.svg";
import FeatureCard3 from "../../assets/images/FeatureCard3.svg";
import FeatureCardImage1 from "../../assets/images/FeatureCardImage1.svg";
import FeatureCardImage2 from "../../assets/images/FeatureCardImage2.svg";
import FeatureCardImage3 from "../../assets/images/FeatureCardImage3.svg";
import FeatureDecor from "../../assets/images/FeatureDecor.svg";
import MobileFeatureDecor from "../../assets/images/MobileFeatureDecor.svg";
import GetStartedDecor from "../../assets/images/GetStartedDecor.svg";
import LearningDecor from "../../assets/images/LearningDecor.svg";
import IntroImage from "../../assets/images/IntroImage.svg";
import IntroDecor from "../../assets/images/IntroDecor.svg";
import MobileIntroDecor from "../../assets/images/MobileIntroDecor.svg";
import Testimonials from "../../components/organisms/Testimonials";
import CTAImage from "../../assets/images/CTAImage.svg";
import MobileCTAImage from "../../assets/images/MobileCTAImage.svg";
import Footer from "../../components/UI/molecules/Footer";
import { Link, useNavigate } from "react-router-dom";
import { ourOffers } from "../../constants/values";
import offerIcon1 from "../../assets/icons/OfferIcon1.svg";
import offerIcon2 from "../../assets/icons/OfferIcon2.svg";
import offerIcon3 from "../../assets/icons/OfferIcon3.svg";
import offerIcon4 from "../../assets/icons/OfferIcon4.svg";
import offerIcon5 from "../../assets/icons/OfferIcon5.svg";
import ReactPlayer from "react-player";
import { ReactComponent as PlayIcon } from "../../assets/icons/landingPlayIcon.svg";
import videoDecor from "../../assets/icons/videoDecor.svg";
import nextIcon from "../../assets/images/nextButton.svg";

function Landing() {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const threshold = 300; // Adjust this value to the scroll position you want
      if (scrollPosition > threshold) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={styles.Container}>
      <Header />
      <motion.div
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: isVisible ? 1 : 0, scale: isVisible ? 1 : 0 }}
        style={{
          position: "fixed",
          bottom: "30px",
          right: "30px",
          zIndex: 500,
          cursor: "pointer",
        }}
      >
        <a href="#hero">
          <img src={nextIcon} style={{ transform: "rotate(-90deg)" }} />
        </a>
      </motion.div>
      <div className={styles.HeroSection} id="hero">
        <div className={styles.TextAnimation}>
          <motion.div
            className=""
            initial={{ x: 200, opacity: 0, scale: 0.5 }}
            animate={{ x: 60, opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, repeat: Infinity, repeatDelay: 1.5 }}
          >
            <p>Sing.</p>
          </motion.div>
          <motion.div
            className=""
            initial={{ x: 200, opacity: 0, scale: 0.5 }}
            animate={{ x: 70, opacity: 1, scale: 1 }}
            transition={{ duration: 1, repeat: Infinity, repeatDelay: 1 }}
          >
            <p>Laugh.</p>
          </motion.div>
          <motion.div
            className=""
            initial={{ x: 200, opacity: 0, scale: 0.5 }}
            animate={{ x: 80, opacity: 1, scale: 1 }}
            transition={{ duration: 1.5, repeat: Infinity, repeatDelay: 0.5 }}
          >
            <p>Learn </p>
          </motion.div>
        </div>
        {/* <motion.div
          animate={{ rotate: 360 }}
          transition={{
            repeat: Infinity,
            repeatType: "loop",
            duration: 10,
            ease: "linear",
          }}
          id={styles.HeroDecor1}
        >
          <img src={HeroDecor1} alt="" />
        </motion.div> */}

        <img
          src={HeroBackground}
          alt=""
          style={{ position: "absolute", opacity: 0.1, zIndex: 0, top: 0 }}
          id={styles.HeroBackground}
        />
        <img
          src={HeroBackground}
          alt=""
          style={{
            position: "absolute",
            opacity: 0.1,
            zIndex: 0,
            left: 1500,
            top: 0,
          }}
          id={styles.HeroBackground}
        />
        <img
          src={MobileHeroBackground}
          alt=""
          style={{ position: "absolute", opacity: 0.1, zIndex: 0, top: 0 }}
          id={styles.MobileHeroBackground}
        />
        <div className={styles.HeroContent}>
          <h4>Welcome To UBELA</h4>
          <h1>Where Learning Becomes an Adventure</h1>
          <p>
            UBELA is designed to engage and educate primary school children in
            an immersive and exciting manner.
          </p>
          <div className={styles.ButtonsWrapper}>
            <a
              target="_blank"
              href="https://apps.apple.com/ng/app/ubela/id6465692054"
            >
              <button id={styles.AppleButton}>
                <img src={BlackAppleLogo} alt="" />
                <div>
                  <p> Download on the</p>
                  <h3>Apple Store</h3>
                </div>
              </button>
            </a>
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.ubela.ubelalearn&pli=1"
            >
              <button id={styles.GoogleButton}>
                <img src={WhitePlaystore} alt="" />
                <div>
                  <p> Download on the</p>
                  <h3>Google Play</h3>
                </div>
              </button>
            </a>
          </div>
        </div>
        <motion.div
          id={styles.HeroImage1}
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: "easeOut", delay: 0.4 }}
        >
          <img src={HeroImage1} alt="" />
        </motion.div>
        <motion.div
          id={styles.HeroImage2}
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: "easeOut", delay: 0.1 }}
        >
          <img src={HeroImage2} alt="" />
        </motion.div>
        <motion.div
          id={styles.HeroImage3}
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: "easeOut", delay: 0.4 }}
        >
          <img src={HeroImage3} alt="" />
        </motion.div>
      </div>
      <div className={styles.AboutSection}>
        <div className={styles.AboutContainer}>
          <img src={AboutImage} alt="" id={styles.AboutImage} />
          <div className={styles.AboutContent}>
            <div>
              <h4>nEVER A dull moment WITH UBE AND ELA</h4>
              <h1>Fostering a Love for Learning!</h1>
            </div>
            <p>
              UBELA's approach to learning is to make education accessible,
              enjoyable and engaging; fostering a positive attitude towards
              learning from a young age.
              <br />
              <br />
              UBELA employs gamification elements, rewards, and achievements,
              which further motivate children to continue learning and
              progressing through the lessons.
            </p>
            <div className={styles.ButtonsWrapper}>
              <a
                href="https://apps.apple.com/ng/app/ubela/id6465692054"
                target="_blank"
              >
                <button id={styles.AppleButton}>
                  <img src={WhiteAppleLogo} alt="" />
                  <div>
                    <p> Download on the</p>
                    <h3>Apple Store</h3>
                  </div>
                </button>
              </a>
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.ubela.ubelalearn&pli=1"
              >
                <button id={styles.GoogleButton}>
                  <img src={BlackPlaystore} alt="" />
                  <div>
                    <p> Download on the</p>
                    <h3>Google Play</h3>
                  </div>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.FeatureSection}>
        <div className={styles.FeatureContent}>
          <div className={styles.TitleWrap}>
            <h4>Deepen YOUR Child&apos;s Understanding</h4>
            <h1>Elevate Performance with UBELA Compact Modules</h1>
          </div>
          <p className={styles.FeatureMainContent}>
            Our bite-sized lessons accelerate learning, enhance retention, and
            guarantee stellar performance in school. Covering essential
            subjects, each meticulously crafted lesson equips children with the
            knowledge and skills they need to excel. Whether preparing for exams
            or deepening understanding, UBELA is the pathway to success.
          </p>
        </div>
        <img src={FeatureDecor} alt="" id={styles.FeatureDecor} />
        <img src={MobileFeatureDecor} alt="" id={styles.MobileFeatureDecor} />
        <div className={styles.FeatureCardsWrapper}>
          <motion.div
            initial={{ opacity: 0, y: 40 }}
            viewport={{ once: true }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, ease: "easeOut", delay: 0.3 }}
            className={styles.FeatureCard}
          >
            <div className={styles.FeatureCardContent}>
              <div className={styles.CardNumbering}>
                <span className={styles.NumberCircle}>1</span>
                <h4>Science</h4>
              </div>
              <div className={styles.CardContentWrapper}>
                <img src={FeatureCardImage1} alt="" />
                <p>
                  Master difficult concepts with simplified science lessons{" "}
                </p>
              </div>
            </div>
            <img src={FeatureCard1} alt="" />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 40 }}
            viewport={{ once: true }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, ease: "easeOut", delay: 0.6 }}
            className={styles.FeatureCard}
          >
            <div className={styles.FeatureCardContent}>
              <div className={styles.CardNumbering}>
                <span className={styles.NumberCircle}>2</span>
                <h4>English</h4>
              </div>
              <div className={styles.CardContentWrapper}>
                <img src={FeatureCardImage2} alt="" />
                <p>Engaging and enjoyable lesson covering English language </p>
              </div>
            </div>
            <img src={FeatureCard2} alt="" />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 40 }}
            viewport={{ once: true }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, ease: "easeOut", delay: 0.9 }}
            className={styles.FeatureCard}
          >
            <div className={styles.FeatureCardContent}>
              <div className={styles.CardNumbering}>
                <span className={styles.NumberCircle}>3</span>
                <h4>Mathematics</h4>
              </div>
              <div className={styles.CardContentWrapper}>
                <img src={FeatureCardImage3} alt="" />
                <p>Easy-to-understand lessons in Mathematics </p>
              </div>
            </div>
            <img src={FeatureCard3} alt="" />
          </motion.div>
        </div>
      </div>
      <div className={styles.OurOfferSection}>
        <h1>What We Offer</h1>
        <div className={styles.OfferWrapper}>
          {ourOffers.map((offer, index) => {
            let icon;
            switch (index) {
              case 0:
                icon = offerIcon1;
                break;
              case 1:
                icon = offerIcon2;
                break;
              case 2:
                icon = offerIcon3;
                break;
              case 3:
                icon = offerIcon4;
                break;
              case 4:
                icon = offerIcon5;
                break;
              default:
                icon = offerIcon1;
            }
            return (
              <div key={index} className={styles.OfferItem}>
                <img src={icon} alt="" />
                <div className={styles.OfferItemContent}>
                  <h4>{offer.title}</h4>
                  <p>{offer.content}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.InfoSection}>
        <div className={styles.GetStartedContainer}>
          <div className={styles.GetStarted}>
            <h2>
              Engaging Education for Every Child with <span>UBELA</span>
            </h2>
            <p>
              We have created a vibrant and immersive learning environment where
              children everywhere can explore Mathematics, English Language, and
              Science in a way that sparks curiosity and ignites a passion for
              learning. Dive into our visually captivating lessons that bring
              subjects to life with vibrant graphics and engaging activities.
              Say goodbye to boring textbooks!
            </p>
            <button onClick={() => navigate("/app/login")}>Get Started</button>
            <img
              src={GetStartedDecor}
              alt=""
              style={{ position: "absolute", bottom: 0, right: 0 }}
              id={styles.InfoCardDecor}
            />
          </div>
          <div className={styles.Learning}>
            <h3>An Interactive Learning Experience</h3>
            <p>
              UBELA is designed to engage and educate children in an immersive
              and exciting manner. Our app provides practice exercises and
              quizzes that are tailored to each child's learning level, ensuring
              that they can reinforce their understanding of the concepts
              covered in the lessons.
            </p>
            <img
              src={LearningDecor}
              alt=""
              style={{ position: "absolute", bottom: -10, right: -10 }}
              id={styles.InfoCardDecor}
            />
          </div>
        </div>
        <div className={styles.Intro}>
          <img src={IntroDecor} alt="" id={styles.IntroDecor} />
          <img src={MobileIntroDecor} alt="" id={styles.MobileIntroDecor} />
          <img src={IntroImage} alt="" id={styles.IntroImage} />
          <div className={styles.IntroContent}>
            <h2>Meet UBE & ELA</h2>
            <p>
              Ube and Ela are your child's best study buddies! These lovable
              characters are on a mission to make learning an absolute blast
              using the "play way" model. With their fun and engaging approach,
              Ube and Ela ensure that every lesson is an adventure, sparking
              curiosity and creativity in kids while keeping them excited about
              learning. Join them on this exciting journey and watch as your
              child discovers the magic of knowledge through wholesome
              entertainment.
            </p>
          </div>
        </div>

        <div className={styles.VideoLibrarySection}>
          <h1>Vast library of video lessons and quizzes </h1>
          <div className={styles.VideosContainer}>
            <div className={styles.VideoItemWrapper}>
              <div className={styles.PlayerWrapper}>
                <ReactPlayer
                  style={{ zIndex: 10 }}
                  url={require("../../assets/images/video1.MP4")}
                  playIcon={<PlayIcon />}
                  light={require("../../assets/images/thumbnail2.webp")}
                  controls
                  width="100%"
                  height="100%"
                  playing
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload",
                      },
                    },
                  }}
                />
              </div>
              <img src={videoDecor} alt="" id={styles.VideoDecor1} />
              <img src={videoDecor} alt="" id={styles.VideoDecor2} />
              <img src={videoDecor} alt="" id={styles.VideoDecor3} />
            </div>
            <div className={styles.VideoItemWrapper}>
              <div className={styles.PlayerWrapper}>
                <ReactPlayer
                  url={require("../../assets/images/video2.MP4")}
                  playing
                  playIcon={<PlayIcon />}
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload",
                      },
                    },
                  }}
                  light={require("../../assets/images/thumbnail1.webp")}
                  controls
                  width="100%"
                  height="100%"
                  previewTabIndex={0}
                />
              </div>
              <img src={videoDecor} alt="" id={styles.VideoDecor1} />
              <img src={videoDecor} alt="" id={styles.VideoDecor2} />
              <img src={videoDecor} alt="" id={styles.VideoDecor3} />
            </div>
          </div>
        </div>
        <div className={styles.Discover}>
          <div className={styles.TitleWrap}>
            <h4>Discover the UBELA Advantage</h4>
            <h1>Credibility You Can Count On</h1>
          </div>
          <p className={styles.DiscoverMainContent}>
            UBELA is dedicated to providing top-tier learning content. Our
            carefully crafted lesson videos, created by experienced educators,
            reflect our commitment to excellence. Aligned with the curriculum,
            they cover topics thoroughly. Each piece undergoes rigorous review
            by education experts, ensuring impeccable quality.
          </p>
        </div>
      </div>
      <Testimonials />
      <div className={styles.CTA}>
        <img src={CTAImage} alt="" id={styles.CTAImage} />
        <img src={MobileCTAImage} alt="" id={styles.MobileCTAImage} />
        <div className={styles.CTAWrapper}>
          <h1>Join the Fun Learning Ride!</h1>
          <p>
            Let the Singing, Laughing and Learning adventure begin. Give your
            child the academic edge they deserve while treating them to a
            visually enjoyable educational experience. With UBELA, learning is
            not just a journey—it's an adventure!
            <br />
            <br />
            Join us and discover the future of education, now with UBELA. Let's
            unlock your child's full potential together!
          </p>
          <div className={styles.ButtonsWrapper}>
            <a
              target="_blank"
              href="https://apps.apple.com/ng/app/ubela/id6465692054"
            >
              <button id={styles.AppleButton}>
                <img src={WhiteAppleLogo} alt="" />
                <div>
                  <p> Download on the</p>
                  <h3>Apple Store</h3>
                </div>
              </button>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.ubela.ubelalearn&pli=1"
              target="_blank"
            >
              <button id={styles.GoogleButton}>
                <img src={BlackPlaystore} alt="" />
                <div>
                  <p> Download on the</p>
                  <h3>Google Play</h3>
                </div>
              </button>
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Landing;
