import React from "react";
import styles from "./MyLessonsCard.module.scss";
import { Subject } from "../../../../models/lessons";
import Text from "../../atoms/Text";
import Button from "../../atoms/Button";
import { useNavigate } from "react-router-dom";

function MyLessonsCard({ subject }: { subject: Subject }) {
  const navigate = useNavigate();
  return (
    <div className={styles.MyLessonsCard}>
      <Text
        color="#fff"
        fontWeight="medium"
        variant="heading 3"
        style={{ textTransform: "capitalize", marginBottom: "20px" }}
      >
        {subject.name}
      </Text>
      <Text
        fontWeight="regular"
        color="#fff"
        variant="heading 5"
        style={{ marginBottom: "36px", textTransform: "capitalize" }}
      >
        {subject.description}
      </Text>
      <div>
        <Button
          onClick={() => navigate(`/app/learn/subjects/${subject.id}`)}
          variant="white"
          buttonStyles={{padding: "20px 48px"}}
        >
          Start
        </Button>
      </div>
    </div>
  );
}

export default MyLessonsCard;
