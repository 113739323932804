import React from "react";
import styles from "./Button.module.scss";
import { colors } from "../../../../sdk/colors";
import Text from "../Text";
import NextButtonIcon from "../../../../assets/icons/NextButtonIcon";
import SmallNextButtonIcon from "../../../../assets/icons/SmallNextButtonIcon";

type ButtonProps = {
  children: string;
  isLoading?: boolean;
  disabled?: boolean;
  buttonStyles?: React.CSSProperties;
  textStyles?: React.CSSProperties;
  variant?: "default" | "outlined" | "white";
  size?: "small" | "large";
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
  textColor?: React.CSSProperties["color"];
  outlineColor?: React.CSSProperties["color"];
};

export const NextButton = (props: ButtonProps) => {
  return (
    <button
      className={styles.PrevButton}
      onClick={props.onClick}
      type="button"
      disabled={props.disabled || props.isLoading}
    >
      <NextButtonIcon disabled={props.disabled} />
      <Text
        color={colors.white}
        style={{ fontSize: "18.23px", zIndex: 10, position: "absolute" }}
      >
        {props.isLoading ? "loading" : props.children}
      </Text>
    </button>
  );
};
export const SmallNextButton = (props: ButtonProps) => {
  return (
    <button
      className={styles.PrevButton}
      onClick={props.onClick}
      type="button"
      disabled={props.disabled || props.isLoading}
    >
      <SmallNextButtonIcon disabled={props.disabled} />
      <Text
        color={colors.white}
        style={{ fontSize: "14px", zIndex: 10, position: "absolute" }}
      >
        {props.isLoading ? "loading" : props.children}
      </Text>
    </button>
  );
};

export const PrevButton = (props: ButtonProps) => {
  return (
    <button
      className={styles.PrevButton}
      onClick={props.onClick}
      type="button"
      disabled={props.disabled || props.isLoading}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="108"
        height="46"
        viewBox="0 0 108 46"
        fill="none"
      >
        <path
          d="M0.652778 19.8955C0.652778 13.443 5.60016 8.06839 12.0306 7.53514L92.975 0.822674C100.204 0.22317 106.403 5.92889 106.403 13.183V32.0903C106.403 39.249 100.359 44.9213 93.2151 44.4682L12.2706 39.3352C5.73865 38.9209 0.652778 33.5023 0.652778 26.9572V19.8955Z"
          fill="white"
          stroke="#BDBDBD"
          strokeWidth="1.30556"
        />
      </svg>
      <Text
        color={colors.grey4}
        style={{ fontSize: "18.23px", zIndex: 10, position: "absolute" }}
      >
        {props.isLoading ? "loading" : props.children}
      </Text>
    </button>
  );
};

function Button(props: ButtonProps) {
  const {
    isLoading,
    disabled,
    variant = "default",
    onClick,
    textColor,
  } = props;

  return (
    <button
      type={props.type || "button"}
      style={{
        ...props.buttonStyles,
        borderColor: props.outlineColor || "#a1d5c8",
        color: textColor
          ? textColor
          : variant === "default"
          ? "#fff"
          : colors.primary,
      }}
      onClick={onClick}
      className={`${styles.Button} ${styles[variant]}`}
      disabled={isLoading || disabled}
    >
      {isLoading ? "loading" : props.children}
    </button>
  );
}

export default Button;
