/* eslint-disable @typescript-eslint/no-shadow */
import {
    configureStore,
    ThunkAction,
    Action,
    combineReducers,
  } from "@reduxjs/toolkit";
  import { apiSlice } from "./apiSlice";
  import authReducer from "./features/auth/authSlice";
  import lessonsReducer from "./features/lessons/lessonsSlice";
  import userReducer from "./features/user/userSlice";
  import profileReducer from "./features/profile/profileSlice";
  import thunk from "redux-thunk";
  
 
  
  const rootReducer = combineReducers({
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    lessons: lessonsReducer,
    user: userReducer,
    profile: profileReducer
  });

  
  export const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }).concat([apiSlice.middleware]),
  });
  
  export type AppDispatch = typeof store.dispatch;
  export type RootState = ReturnType<typeof store.getState>;
  export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
  >;
  