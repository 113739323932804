import React from "react";
import { fonts } from "../../../../sdk/fonts";
import { colors } from "../../../../sdk/colors";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { mobileBreakPoint } from "../../../../constants/values";

type TextVariantProps =
  | "heading 1"
  | "heading 2"
  | "heading 3"
  | "heading 4"
  | "heading 5"
  | "heading 6"
  | "body"
  | "caption";

function Text({
  variant = "body",
  children,
  style,
  fontWeight = "regular",
  color = colors.black1,
  id,
}: {
  variant?: TextVariantProps;
  children: React.ReactNode;
  style?: React.CSSProperties;
  fontWeight?: "medium" | "regular" | "bold" | "semibold" | "light";
  color?: string;
  id?: React.HTMLAttributes<HTMLDivElement>["id"];
}) {
  let text: JSX.Element;

  const { width } = useWindowDimensions();
  const isMobileBreakpoint = width < mobileBreakPoint;
  switch (variant) {
    case "heading 1":
      text = (
        <h1
          id={id}
          style={{
            fontFamily: "ComicSans",
            fontSize: "50px",
            color: color,
            lineHeight: "55px",
            fontWeight: fontWeight ? fonts[fontWeight] : 700,
            ...style,
          }}
        >
          {children}
        </h1>
      );
      break;
    case "heading 2":
      text = (
        <h2
          id={id}
          style={{
            fontSize: "30px",
            color: color,
            lineHeight: "52.8px",
            fontWeight: fontWeight ? fonts[fontWeight] : 300,
            ...style,
          }}
        >
          {children}
        </h2>
      );
      break;
    case "heading 3":
      text = (
        <h3
          id={id}
          style={{
            fontSize: "24px",
            color: color,
            lineHeight: "33px",
            fontWeight: fontWeight ? fonts[fontWeight] : 400,
            ...style,
          }}
        >
          {children}
        </h3>
      );
      break;
    case "heading 4":
      text = (
        <h4
          id={id}
          style={{
            fontSize: "20px",
            color: color,
            lineHeight: "26.4px",
            fontWeight: fontWeight ? fonts[fontWeight] : 300,
            ...style,
          }}
        >
          {children}
        </h4>
      );
      break;
    case "heading 5":
      text = (
        <h4
          id={id}
          style={{
            fontSize: isMobileBreakpoint ? "14px" : "16px",
            color: color,
            fontWeight: fontWeight ? fonts[fontWeight] : 300,
            ...style,
          }}
        >
          {children}
        </h4>
      );
      break;
    case "heading 6":
      text = (
        <h4
          id={id}
          style={{
            fontSize: isMobileBreakpoint ? "12px" : "14px",
            color: color,
            fontWeight: fontWeight ? fonts[fontWeight] : 400,
            ...style,
          }}
        >
          {children}
        </h4>
      );
      break;
    case "body":
      text = (
        <p
          id={id}
          style={{
            fontSize: isMobileBreakpoint ? "16px" : "19px",
            color: color,
            fontWeight: fontWeight ? fonts[fontWeight] : 300,
            ...style,
          }}
        >
          {children}
        </p>
      );
      break;
    case "caption":
      text = (
        <p
          id={id}
          style={{
            fontSize: isMobileBreakpoint ? "10px" : "15px",
            color: color,
            fontWeight: fontWeight ? fonts[fontWeight] : 400,
            ...style,
          }}
        >
          {children}
        </p>
      );
      break;
    default:
      text = (
        <p
          id={id}
          style={{
            fontSize: "20px",
            color: color,
            lineHeight: "22px",
            fontWeight: fontWeight ? fonts[fontWeight] : 300,
            ...style,
          }}
        >
          {children}
        </p>
      );
  }
  return text;
}

export default Text;
