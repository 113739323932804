import React from "react";
import { Lesson } from "../../../../models/lessons";
import styles from "./LessonItem.module.scss";
import LessonImage1 from "../../../../assets/icons/LessonImage1.svg";
import LessonImage2 from "../../../../assets/icons/LessonImage2.svg";
import LessonImage3 from "../../../../assets/icons/LessonImage3.svg";
import LessonImage4 from "../../../../assets/icons/LessonImage4.svg";
import LessonImage5 from "../../../../assets/icons/LessonImage5.svg";
import Text from "../../atoms/Text";
import { createSearchParams, useNavigate } from "react-router-dom";
import { CircularProgress, Spinner } from "@chakra-ui/react";
import { colors } from "../../../../sdk/colors";

function LessonItem({
  lesson,
  index,
  isDownload = false,
  progress,
  subjectId,
  color,
  onClick,
  isLoading,
}: {
  lesson: Lesson;
  index: number;
  isDownload?: boolean;
  progress?: number;
  subjectId?: number;
  color?: string;
  onClick?: () => void;
  isLoading?: boolean;
}) {
  const navigate = useNavigate();
  let image: JSX.Element;
  if (index === 1) {
    image = (
      <img
        id={styles.Media}
        style={{ height: "100%", width: "100%", objectFit: "cover" }}
        src={LessonImage1}
        alt={lesson.title}
      />
    );
  } else if (index % 5 === 0) {
    image = (
      <img
        id={styles.Media}
        style={{ height: "100%", width: "100%", objectFit: "cover" }}
        src={LessonImage2}
        alt={lesson.title}
      />
    );
  } else if (index % 4 === 0) {
    image = (
      <img
        id={styles.Media}
        style={{ height: "100%", width: "100%", objectFit: "cover" }}
        src={LessonImage3}
        alt={lesson.title}
      />
    );
  } else if (index % 3 === 0) {
    image = (
      <img
        id={styles.Media}
        style={{ height: "100%", width: "100%", objectFit: "cover" }}
        src={LessonImage4}
        alt={lesson.title}
      />
    );
  } else if (index % 2 === 0) {
    image = (
      <img
        id={styles.Media}
        style={{ height: "100%", width: "100%", objectFit: "cover" }}
        src={LessonImage5}
        alt={lesson.title}
      />
    );
  } else {
    image = (
      <img
        id={styles.Media}
        style={{ height: "100%", width: "100%", objectFit: "cover" }}
        src={LessonImage1}
        alt={lesson.title}
      />
    );
  }
  console.log(subjectId);
  return (
    <button
      onClick={() =>
        navigate({
          pathname: `/app/learn/lessons/${lesson.id}`,
          search: createSearchParams({ subjectId: `${subjectId}` }).toString(),
        })
      }
      className={styles.LessonItem}
    >
      <div className={styles.Media}>{image}</div>
      <div className={styles.Content}>
        <div className={styles.TextWrapper}>
          <Text fontWeight="light">{lesson.title}</Text>
        </div>
        {isDownload ? (
          isLoading ? (
            <Spinner />
          ) : (
            <span
              onClick={(e) => {
                e.stopPropagation();
                onClick && onClick();
              }}
            >
              Delete
            </span>
          )
        ) : progress ? (
          <CircularProgress
            value={progress * 100}
            size={10}
            color={color || colors.primary}
          />
        ) : undefined}
      </div>
    </button>
  );
}

export default LessonItem;
