

import React from 'react'

function DashboardChampionshipIcon({color}: {color: React.CSSProperties["color"]}) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M6 3.5C6 3.36739 6.05268 3.24021 6.14645 3.14645C6.24021 3.05268 6.36739 3 6.5 3H17.5C17.6326 3 17.7598 3.05268 17.8536 3.14645C17.9473 3.24021 18 3.36739 18 3.5V4H20.5C20.6326 4 20.7598 4.05268 20.8536 4.14645C20.9473 4.24021 21 4.36739 21 4.5V7.5C21 8.16304 20.7366 8.79893 20.2678 9.26777C19.7989 9.73661 19.163 10 18.5 10H17.6585C17.3011 11.0086 16.6809 11.9034 15.8619 12.592C15.0429 13.2806 14.0549 13.738 13 13.917V17H16C16.1326 17 16.2598 17.0527 16.3536 17.1464C16.4473 17.2402 16.5 17.3674 16.5 17.5V20.5C16.5 20.6326 16.4473 20.7598 16.3536 20.8536C16.2598 20.9473 16.1326 21 16 21H8C7.86739 21 7.74021 20.9473 7.64645 20.8536C7.55268 20.7598 7.5 20.6326 7.5 20.5V17.5C7.5 17.3674 7.55268 17.2402 7.64645 17.1464C7.74021 17.0527 7.86739 17 8 17H11V13.917C9.94507 13.738 8.95706 13.2806 8.13809 12.592C7.31911 11.9034 6.69889 11.0086 6.3415 10H5.5C4.83696 10 4.20107 9.73661 3.73223 9.26777C3.26339 8.79893 3 8.16304 3 7.5V4.5C3 4.36739 3.05268 4.24021 3.14645 4.14645C3.24021 4.05268 3.36739 4 3.5 4H6V3.5ZM18 8V5H20V7.5C20 7.89782 19.842 8.27936 19.5607 8.56066C19.2794 8.84196 18.8978 9 18.5 9H18V8ZM6 5H4V7.5C4 7.89782 4.15804 8.27936 4.43934 8.56066C4.72064 8.84196 5.10218 9 5.5 9H6V5Z" fill={color}/>
</svg>
  )
}

export default DashboardChampionshipIcon