import React from "react";
import styles from "./PrivacyPolicy.module.scss";
import HeroBackground from "../../assets/images/HeroBackground.svg";
import MobileHeroBackground from "../../assets/images/MobileHeroBackground.svg";
import HeroDecor1 from "../../assets/images/heroDecor1.svg";
import HeroImage1 from "../../assets/images/heroImage1.svg";
import HeroImage2 from "../../assets/images/heroImage2.svg";
import HeroImage3 from "../../assets/images/heroImage3.svg";
import { motion } from "framer-motion";
import Header from "../../components/UI/molecules/LandingPageHeader";
import Footer from "../../components/UI/molecules/Footer";
import { Helmet } from "react-helmet";

function PrivacyPolicy() {
  return (
    <div className={styles.Container}>
      <Helmet>
        <title>UBELA - Privacy Policy</title>
      </Helmet>
      <Header />
      <div className={styles.HeroSection}>
        <img
          src={HeroBackground}
          alt=""
          style={{ position: "absolute", opacity: 0.1, zIndex: 0, top: 0 }}
          id={styles.HeroBackground}
        />
        <img
          src={HeroBackground}
          alt=""
          style={{
            position: "absolute",
            opacity: 0.1,
            zIndex: 0,
            left: 1500,
            top: 0,
          }}
          id={styles.HeroBackground}
        />
        <img
          src={MobileHeroBackground}
          alt=""
          style={{ position: "absolute", opacity: 0.1, zIndex: 0, top: 0 }}
          id={styles.MobileHeroBackground}
        />
        <motion.div
          id={styles.HeroImage1}
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: "easeOut", delay: 0.4 }}
        >
          <img src={HeroImage1} alt="" />
        </motion.div>
        <motion.div
          id={styles.HeroImage2}
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: "easeOut", delay: 0.1 }}
        >
          <img src={HeroImage2} alt="" />
        </motion.div>
        <motion.div
          id={styles.HeroImage3}
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: "easeOut", delay: 0.4 }}
        >
          <img src={HeroImage3} alt="" />
        </motion.div>
        <div className={styles.PrivacySection}>
          <div className={styles.ContentWrapper}>
            <h1>Privacy Policy</h1>
            <div>
              <p>
                At UBELA, we are committed to protecting the privacy of our
                users, especially children in Primary 1 to 6, who use our
                e-learning app. This Privacy Policy outlines how we collect,
                use, and safeguard the information provided by users of our app.
                Please read this policy carefully to understand our practices
                regarding your personal data and how we handle it. By using the
                UBELA App, you consent to the practices described herein.
              </p>
              <ol>
                <li>
                  <div>
                    <h3>Information we collect</h3>
                    <ul>
                      <li>
                        <span>Personal Information:</span>
                        We collect minimal personal information necessary to
                        provide the best e-learning experience for your child.
                        This may include the child's name (or nickname), age,
                        class, and email address (for parental consent and
                        communication purposes only).
                      </li>
                      <li>
                        <span>Non-Personal Information:</span>
                        We may also collect non-personal information, such as
                        device information, app usage data, and interactions
                        within the app, which help us improve the app's
                        performance and user experience.
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div>
                    <h3>How We Use the Information</h3>
                    <ul>
                      <li>
                        <span>Providing and Improving the App:</span>
                        We use the collected information to deliver educational
                        content tailored to the child's class and to enhance the
                        functionality of the UBELA App.
                      </li>
                      <li>
                        <span>Communication:</span> The provided email address
                        will only be used to communicate with parents/legal
                        guardians regarding app-related updates, important
                        notices, or to obtain parental consent if required.
                      </li>
                      <li>
                        <span>Analytics:</span>
                        Non-personal information may be used for analytical
                        purposes to identify trends, monitor app usage, and
                        improve the app's features and content.
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div>
                    <h3>Data Sharing and Disclosure</h3>
                    <ul>
                      <li>
                        <span>Third-Party Service Providers:</span>
                        We may share non-personal information with trusted
                        third-party service providers who assist us in analyzing
                        app usage, maintaining app infrastructure, and providing
                        customer support. These third parties are obligated to
                        handle the data securely and are not allowed to use it
                        for any other purpose.
                      </li>
                      <li>
                        <span>Legal Requirements:</span>
                        We may disclose personal information if required to do
                        so by law or in response to a valid legal request, such
                        as a court order or a subpoena.
                      </li>
                      <li>
                        <span>Children's Safety:</span>
                        The safety of children is of utmost importance to us. In
                        case of any concerns related to a child's safety, we may
                        share relevant information with parents/legal guardians
                        or appropriate authorities.
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div>
                    <h3>Data Security</h3>
                    <p>
                      We implement appropriate technical and organizational
                      measures to safeguard the data collected via the UBELA
                      App. However, no data transmission over the internet or
                      electronic storage method is completely secure. While we
                      strive to protect your child's information, we cannot
                      guarantee absolute security.
                    </p>
                  </div>
                </li>
                <li>
                  <div>
                    <h3>Parental Rights</h3>
                    <p>
                      Parents/legal guardians have the right to review the
                      personal information we have collected about their child,
                      request its deletion, and revoke consent for further
                      collection and use of data. You can exercise these rights
                      by contacting us at{" "}
                      <a href="mailto:ubela.app@gmail.com">
                        ubela.app@gmail.com
                      </a>
                    </p>
                  </div>
                </li>
                <li>
                  <div>
                    <h3>COPPA Compliance</h3>
                    <p>
                      The UBELA App complies with the Children's Online Privacy
                      Protection Act (COPPA). We do not knowingly collect
                      personal information from children under the age of 13
                      without verifiable parental consent. If we discover that
                      we have inadvertently collected personal information from
                      a child without proper consent, we will take prompt steps
                      to delete that information from our records.
                    </p>
                  </div>
                </li>
                <li>
                  <div>
                    <h3>Updates to the Privacy Policy</h3>
                    <p>
                      We may update this Privacy Policy from time to time to
                      reflect changes in our practices or for regulatory
                      compliance. The latest version will be posted on our
                      website, and any significant changes will be communicated
                      to parents/legal guardians via email.
                    </p>
                  </div>
                </li>
                <li>
                  <div>
                    <h3>Contact Us</h3>
                    <p>
                      If you have any questions, concerns, or requests related
                      to this Privacy Policy or our data practices, please
                      contact us at:
                      <p>
                        <span>Email: </span>
                        <a href="mailto:ubela.app@gmail.com">
                          ubela.app@gmail.com
                        </a>
                      </p>
                      <p>
                        <span>Phone: </span>
                        <a href="tel:+2348023223768"> +2348023223768</a>
                      </p>
                    </p>
                  </div>
                </li>
              </ol>
              <p>
                By using the UBELA App, you agree to the terms outlined in this
                Privacy Policy. Thank you for entrusting us with your child's
                education, and we assure you that we will make every effort to
                protect their privacy and provide a safe learning environment.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
