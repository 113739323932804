import React, { useContext, useEffect, useMemo, useState } from "react";
import styles from "./SubjectPreview.module.scss";
import PageHeader from "../../components/UI/molecules/PageHeader";
import { useGetUserQuery } from "../../redux/features/user/userApiSlice";
import { useGetNumberOfTopicsQuery } from "../../redux/features/lessons/lessonsApiSlice";
import { StringHelper } from "../../utils/stringHelper";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import Star from "../../assets/icons/Star";
import Text from "../../components/UI/atoms/Text";
import { grades, subjects } from "../../constants/values";
import { ReactComponent as EnglishIcon } from "../../assets/icons/englishIcon.svg";
import { ReactComponent as MathIcon } from "../../assets/icons/mathIcon.svg";
import { ReactComponent as ScienceIcon } from "../../assets/icons/scienceIcon.svg";
import Tag from "../../components/UI/atoms/Tag";
import { ReactComponent as Dropdown } from "../../assets/icons/dropdown.svg";
import { ReactComponent as CardDecor1 } from "../../assets/icons/subjectCardDecor1.svg";
import { ReactComponent as CardDecor2 } from "../../assets/icons/subjectCardDecor2.svg";
import { ReactComponent as CardDecor3 } from "../../assets/icons/subjectCard3.svg";
import { ReactComponent as Icon1 } from "../../assets/icons/subjectCardIcon1.svg";
import { ReactComponent as Icon2 } from "../../assets/icons/subjectCardIcon2.svg";
import { ReactComponent as Icon3 } from "../../assets/icons/subjectCardIcon3.svg";
import Button from "../../components/UI/atoms/Button";
import { useGetProfilesQuery } from "../../redux/features/profile/profileApiSlice";
import LoaderContext from "../../context/loader.context";
import ViewTopics from "../../components/organisms/ViewTopics";

function SubjectPreview() {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { data: user } = useGetUserQuery(undefined);

  const { setLoader: setLoading } = useContext(LoaderContext);

  const navigate = useNavigate();

  const [gradeValue, setGradeValue] = useState<{
    value: number;
    label: string;
  }>({ value: 1, label: "Primary 1" });

  const { data: profiles } = useGetProfilesQuery(undefined);

  const { data: topicsNum, isLoading: isTopicsNumLoading } =
    useGetNumberOfTopicsQuery({
      subjectId: Number(params.subjectId),
      grade: !!user
        ? StringHelper.gradeNumberToString(Number(gradeValue.value))
        : "first",
    });

  useEffect(() => {
    const gradeParam = searchParams.get("grade");
    if (gradeParam) {
      setGradeValue({
        value: Number(gradeParam),
        label: `Primary ${Number(gradeParam)}`,
      });
    }
  }, [searchParams]);

  useEffect(() => {
    setLoading(isTopicsNumLoading);

    return () => {
      setLoading(false);
    };
  }, [isTopicsNumLoading]);

  console.log(topicsNum);

  const {
    isOpen: isProfileLimitModalOpen,
    onOpen: onProfileLimitOpen,
    onClose: onProfileLimitClose,
  } = useDisclosure();

  const {
    isOpen: isGradeOpen,
    onOpen: onGradeOpen,
    onClose: onGradeClose,
  } = useDisclosure();

  const {
    isOpen: isViewTopicsOpen,
    onOpen: onViewTopicsOpen,
    onClose: onViewTopicsClose,
  } = useDisclosure();

  let subjectIcon;

  const subjectName = useMemo(
    () =>
      subjects.find((subject) => subject.id === Number(params.subjectId))
        ?.name || "english",
    [params.subjectId]
  );

  switch (subjectName) {
    case "english":
      subjectIcon = <EnglishIcon />;
      break;
    case "mathematics":
      subjectIcon = <MathIcon />;
      break;
    case "science":
      subjectIcon = <ScienceIcon />;
      break;
    default:
      subjectIcon = <EnglishIcon />;
  }

  const handleCreateProfile = () => {
    if (!!profiles) {
      if (profiles?.length < 3) {
        navigate("/app/guardian/create-profile");
      } else {
        onProfileLimitOpen();
      }
    }
  };

  return (
    <>
      <div className={styles.SubjectPreview}>
        <PageHeader pageTitle="" />
        <div className={styles.Container}>
          <div>
            <div className={styles.SubjectHeader}>
              <div className={styles.HeaderWrapper}>
                {subjectIcon}
                <Text
                  variant="heading 3"
                  fontWeight="light"
                  style={{ textTransform: "capitalize" }}
                >
                  {subjectName}
                </Text>
              </div>
              <Tag onClick={onGradeOpen}>
                <Text
                  style={{
                    fontSize: "12px",
                    fontWeight: 500,
                    textAlign: "center",
                  }}
                >
                  {gradeValue.label}
                </Text>
                <Dropdown />
              </Tag>
            </div>
            <div className={styles.Content}>
              <Text>This Course Incudes</Text>
              <div className={styles.ContentCard}>
                <CardDecor1 id={styles.cardDecor1} />
                <CardDecor2 id={styles.cardDecor2} />
                <CardDecor3 id={styles.cardDecor3} />
                <div className={styles.ContentItem}>
                  <Icon1 />
                  <div className={styles.ContentTextWrapper}>
                    <Text fontWeight="medium" style={{ marginBottom: "4px" }}>
                      {StringHelper.pluralize(
                        topicsNum?.data.number_of_lessons || 0,
                        "Topic"
                      )}
                    </Text>
                    <Text variant="caption">
                      Unlock a wealth of learning through our extensive lesson
                      library
                    </Text>
                  </div>
                </div>
                <div className={styles.ContentItem}>
                  <Icon2 />
                  <div className={styles.ContentTextWrapper}>
                    <Text fontWeight="medium" style={{ marginBottom: "4px" }}>
                      {StringHelper.pluralize(
                        topicsNum?.data.number_of_topics || 0,
                        "Lesson"
                      )}
                    </Text>
                    <Text variant="caption">
                      Access a range of educational videos to deepen your
                      child&apos;s understanding
                    </Text>
                  </div>
                </div>
                <div className={styles.ContentItem}>
                  <Icon3 />
                  <div className={styles.ContentTextWrapper}>
                    <Text fontWeight="medium" style={{ marginBottom: "4px" }}>
                      After Lesson Quiz
                    </Text>
                    <Text variant="caption">
                      Knowledge-check quizzes after each lesson. to reinforce
                      concepts.
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Button disabled={!profiles} onClick={handleCreateProfile}>
              Subscribe
            </Button>
            <Button
              variant="outlined"
              onClick={onViewTopicsOpen}
              buttonStyles={{ marginTop: "20px" }}
            >
              View Topics
            </Button>
          </div>
        </div>
      </div>
      <Modal isOpen={isProfileLimitModalOpen} onClose={onProfileLimitClose}>
        <ModalOverlay />
        <ModalContent borderRadius="0px" marginLeft="20px" marginRight="20px">
          <div className={styles.ProfileLimitModal}>
            <Text style={{ textAlign: "center" }}>
              Your profile creation limit has been reached.
            </Text>
          </div>
        </ModalContent>
      </Modal>
      <Modal isOpen={isGradeOpen} onClose={onGradeClose}>
        <ModalOverlay />
        <ModalContent
          style={{ maxWidth: "500px", marginLeft: "30px", marginRight: "30px" }}
        >
          <div className={styles.GradeWrapper}>
            {grades.map((grade, index) => (
              <button
                onClick={() => {
                  setGradeValue({ value: grade.value, label: grade.label });
                  onGradeClose();
                }}
                key={index}
                className={styles.GradeItem}
              >
                <Star color={grade.color} />
                <Text>{grade.label}</Text>
              </button>
            ))}
          </div>
        </ModalContent>
      </Modal>
      <Drawer
        isOpen={isViewTopicsOpen}
        onClose={onViewTopicsClose}
        placement="bottom"
        size="lg"
      >
        <DrawerOverlay />
        <DrawerContent>
          <ViewTopics
            info={{
              subjectId: Number(params.subjectId),
              grade: gradeValue.value,
            }}
            onClose={onViewTopicsClose}
          />
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default SubjectPreview;
