import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

// Define the type for the deferredPrompt
interface InstallPromptContextType {
  deferredPrompt: Event | null;
  setDeferredPrompt: (prompt: Event | null) => void;
}

// Create context with default value as null
const InstallPromptContext = createContext<InstallPromptContextType | null>(null);

export const InstallPromptProvider = ({ children }: { children: ReactNode }) => {
  const [deferredPrompt, setDeferredPrompt] = useState<Event | null>(null);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e: Event) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  return (
    <InstallPromptContext.Provider value={{ deferredPrompt, setDeferredPrompt }}>
      {children}
    </InstallPromptContext.Provider>
  );
};

export const useInstallPrompt = () => {
  const context = useContext(InstallPromptContext);
  if (!context) {
    throw new Error('useInstallPrompt must be used within an InstallPromptProvider');
  }
  return context;
};
