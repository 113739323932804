import React from "react";
import { Plan } from "../../../../../models/subscription";
import styles from "./SubscriptionPlanItem.module.scss";
import Text from "../../../atoms/Text";
import { StringHelper } from "../../../../../utils/stringHelper";

function SubscriptionPlanItem({
  plan,
  isSelected,
}: {
  plan: Plan;
  isSelected: boolean;
}) {
  return (
    <div
      style={{ backgroundColor: isSelected ? "#DBF5EA" : "#fff" }}
      className={styles.SubscriptionPlanItem}
    >
      <div className={styles.Wrapper}>
        <Text style={{ textTransform: "capitalize" }} fontWeight="medium">
          {plan.name}
        </Text>
        <Text fontWeight="medium" variant="heading 4">
          {StringHelper.stringToMoneyFormat(plan.amount, true)}
        </Text>
      </div>
      <Text variant="caption">{plan.interval}</Text>
    </div>
  );
}

export default SubscriptionPlanItem;
