import React from "react";
import styles from "./Help.module.scss";
import PageHeader from "../../components/UI/molecules/PageHeader";
import Text from "../../components/UI/atoms/Text";
import HelpImage from "../../assets/images/helpImage.svg";
import ProfileItem from "../../components/UI/molecules/ProfileItem";
import { ReactComponent as ProfilePrivacyIcon } from "../../assets/icons/profilePrivacyIcon.svg";
import { ReactComponent as ProfileTermsAndConditionsIcon } from "../../assets/icons/profileTermsAndConditionsIcon.svg";
import { ReactComponent as ProfileFAQIcon } from "../../assets/icons/profileFAQIcon.svg";
import { ReactComponent as WhatsappIcon } from "../../assets/icons/whatsappIcon.svg";
import { ReactComponent as EmailIcon } from "../../assets/icons/emailIcon.svg";

function Help() {
  return (
    <div className={styles.Help}>
      <PageHeader canGoBack={false} pageTitle="Help" />
      <div className={styles.Container}>
        <div className={styles.HelpMessage}>
          <div className={styles.HelpMessageWrapper}>
            <Text fontWeight="medium">We’re here for you.</Text>
            <Text variant="caption">
              Gain access to quick assistance. Can't find answers to your query?
              Contact Us
            </Text>
          </div>
          <img src={HelpImage} alt="" />
        </div>
        <div className={styles.Wrapper}>
          <a href="https://ubelalearn.com/faq" target="__blank">
            <ProfileItem icon={<ProfileFAQIcon />} title="FAQ" />
          </a>
          <a href="https://ubelalearn.com/terms-of-use" target="__blank">
            <ProfileItem
              icon={<ProfileTermsAndConditionsIcon />}
              title="Terms and Conditions"
            />
          </a>
          <a href="https://ubelalearn.com/privacy-policy" target="__blank">
            <ProfileItem icon={<ProfilePrivacyIcon />} title="Privacy policy" />
          </a>
        </div>
        <div className={styles.Wrapper}>
          <Text>Contact UBELA Team</Text>
          <div className={styles.ContactWrapper}>
            <a
              target="__blank"
              href="https://api.whatsapp.com/send?phone=2348132015005"
            >
              <div className={styles.ContactItem}>
                <WhatsappIcon />
              </div>
            </a>
            <a target="__blank" href="mailto:ubela.app@gmail.com">
              <div
                style={{ borderColor: "#9747FF" }}
                className={styles.ContactItem}
              >
                <EmailIcon />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Help;
