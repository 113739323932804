import React, { useContext, useState } from "react";
import AuthContainer from "../../containers/AuthContainer";
import styles from "./VerifyEmail.module.scss";
import Text from "../../components/UI/atoms/Text";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import OTPInput from "../../components/UI/molecules/OTPInput";
import { colors } from "../../sdk/colors";
import { fonts } from "../../sdk/fonts";
import {
  useConfirmPhoneNumberMutation,
  useResendCodeMutation,
} from "../../redux/features/auth/authApiSlice";
import { ConfirmPhoneBody } from "../../models/auth";
import { useToast } from "../../context/toast.context";
import LoaderContext from "../../context/loader.context";
import { useNavigate } from "react-router-dom";
import { handleUserLogin, tokenReceived } from "../../redux/features/auth/authSlice";

function VerifyEmail() {
  const navigate = useNavigate();
  const {setLoader: setLoading} = useContext(LoaderContext);
  const { show } = useToast();
  const [confirmPhone, { isLoading }] = useConfirmPhoneNumberMutation();
  const [resendCode, { isLoading: resendLoading }] = useResendCodeMutation();
  const message = useAppSelector(
    (state: RootState) => state.auth.confirmEmailMessage
  );
  const dispatch = useAppDispatch();
  const phoneNumber = useAppSelector(
    (state: RootState) => state.auth.phoneNumber
  );
  const [otp, setOtp] = useState<string>("");

  const onSubmit = async () => {
    const submitValues: ConfirmPhoneBody = {
      code: otp,
      phoneNumber,
    };
    try {
      setLoading(true)
      const response = await confirmPhone(submitValues).unwrap();
      setLoading(false);
      show({ message: `${response?.message}`, variant: "success" });
      dispatch(tokenReceived(response.token));
      navigate("/app/guardian");
      console.log(response);
    } catch (e: any) {
      setLoading(false);
      console.log(e);
      show({
        message: `${(e as { data: { message: string; error: string } }).data.error}`,
        variant: "error",
      });
    }
  };

  const handleResendCode = async () => {
    const submitValues: { phoneNumber: string } = {
      phoneNumber,
    };
    try {
      const response = await resendCode(submitValues).unwrap();
      console.log(response);
      show({ message: `${response?.message}`, variant: "info" });
    } catch (e) {
      console.log(e);
      show({
        message: `${(e as { data: { message: string; error: string } }).data.message}`,
        variant: "error",
      });
    }
  };

  return (
    <AuthContainer>
      <div className={styles.VerifyEmail}>
        <div>
          <div className={styles.AuthHeader}>
            <Text variant="heading 4" fontWeight="light">
              Verify Phone Number
            </Text>
            <Text
              fontWeight="light"
              style={{ fontSize: 14, marginTop: "10px" }}
            >
              {message}
            </Text>
          </div>
          <div className={styles.Wrapper}>
            <Text variant="heading 4" fontWeight="regular">
              Enter Code
            </Text>
            <div style={{ width: "100%", marginTop: "30px" }}>
              <OTPInput
                onCodeComplete={onSubmit}
                otpCode={otp}
                setOTPCode={(value) => setOtp(value)}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "70px",
            }}
          >
            <Text
              color={colors.grey2}
              style={{ marginRight: "5px", fontStyle: "italic" }}
              fontWeight="regular"
            >
              Don’t Have An Account?
            </Text>
            <button disabled={resendLoading} onClick={handleResendCode}>
              <Text
                color={resendLoading ? colors.grey1 : colors.primary}
                fontWeight="medium"
              >
                Resend
              </Text>
            </button>
          </div>
        </div>
        <div
          style={{
            fontSize: "16px",
            fontWeight: fonts.light,
            color: colors.grey4,
            textAlign: "center",
          }}
        >
          Tap to read UBELA{" "}
          <button style={{ color: colors.primary }}>Terms and condition</button>{" "}
          and <button style={{ color: colors.black1 }}>privacy policy</button>.
        </div>
      </div>
    </AuthContainer>
  );
}

export default VerifyEmail;
