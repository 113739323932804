import { createContext, useCallback, useEffect, useState } from "react";
import LoadingScreen from "../components/UI/molecules/LoadingScreen";

const LoaderContext = createContext<{ setLoader: (e: boolean) => void }>({
  setLoader: () => {},
});

export default LoaderContext;

export function LoaderContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const setLoader = useCallback(
    (value: boolean) => {
      setLoading(value);
    },
    [loading]
  );
  return (
    <LoaderContext.Provider value={{ setLoader }}>
      <LoadingScreen animating={loading} />
      {children}
    </LoaderContext.Provider>
  );
}
