export class StringHelper {
  static stringToMoneyFormat(
    input: string | number,
    withDecimals = false,
    withNairaSign = true
  ) {
    const inputValue = withDecimals ? Number(input).toFixed(2) : Number(input);
    let value = inputValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return (withNairaSign ? "NGN" : "") + " " + value;
  }
  static isValidString(data: any) {
    return (
      data !== null &&
      data !== undefined &&
      data !== "undefined" &&
      data.toString().length > 0
    );
  }
  static toTitleCase(str: any) {
    if (str) {
      return str
        .toLowerCase()
        .split(" ")
        .map(function (word: any) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ");
    }
    return str;
  }

  static toUrlString(obj: any): string {
    let urlString = "";
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        if (obj[prop] !== null && Array.isArray(obj[prop])) {
          obj[prop].forEach((item: any) => {
            urlString += `${prop}=${item}&`;
          });
        } else {
          if (obj[prop] !== null) {
            urlString += `${prop}=${obj[prop]}&`;
          }
        }
      }
    }
    return urlString.slice(0, -1);
  }

  static hasNestedEntries(obj: any) {
    for (const key in obj) {
      if (
        typeof obj[key] === "object" &&
        obj[key] !== null &&
        !Array.isArray(obj[key])
      ) {
        return true;
      }
    }
    return false;
  }
  static hasEntries(obj: any) {
    return Object.entries(obj).length > 0;
  }
  static hasArrayEntries(obj: any) {
    for (const [key, value] of Object.entries(obj)) {
      if (Array.isArray(value)) {
        return true;
      }
    }
    return false;
  }
  static isJSON(data: any) {
    if (typeof data !== "string") {
      return false;
    }

    try {
      JSON.parse(data);
      return true;
    } catch (error) {
      return false;
    }
  }

  //Errors
  static getErrorMessage(
    error: any,
    defaultMessage = "Unable to complete request."
  ) {
    if (typeof error === "string") {
      return error;
    }
    if (error?.status_code === 401) {
      return "Session Expired, Please Log back in to continue";
    }
    if (error?.status_code === 500) {
      return defaultMessage;
    }
    // console.log(error, "errror");
    if (!error && !error?.hasOwnProperty("response")) {
      return defaultMessage;
    }
    if (typeof error?.response === "string") {
      return error?.response;
    }
    if (
      StringHelper.hasEntries(error?.response) &&
      StringHelper?.hasArrayEntries(error?.response)
    ) {
      const firstKey = Object.keys(error.response)[0];
      const firstError = error.response[firstKey];
      return firstError;
    } else if (
      StringHelper.hasEntries(error?.response) &&
      !StringHelper?.hasArrayEntries(error?.response)
    ) {
      return defaultMessage;
    } else {
      return defaultMessage;
    }
  }

  static formatPrice(price: any) {
    if (price) {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return price;
  }

  static pluralize(count: number, word: string) {
    if (count === 1) {
      return `${count} ${word}`;
    } else {
      return `${count} ${word}s`;
    }
  }
  static gradeNumberToString(grade: number): string {
    switch (grade) {
      case 1:
        return "first";
      case 2:
        return "second";
      case 3:
        return "third";
      case 4:
        return "forth";
      case 5:
        return "fifth";
      case 6:
        return "sixth";
      case 7:
        return "seventh";
      case 8:
        return "eighth";
      case 9:
        return "ninth";
      case 10:
        return "tenth";
      default:
        return "Invalid number";
    }
  }

  /**
   * It removes empty properties from an object
   * @param obj - The object to remove empty properties from.
   * @param {string[]} keys - The keys to check for empty values. If not provided, all keys will be
   * checked.
   * @param [removeNestedProperty=true] - If true, it will remove the nested properties as well.
   */
  static removeEmptyProperties(
    obj: any,
    keys: any,
    removeNestedProperty = true
  ) {
    const objClone = JSON.parse(JSON.stringify(obj));
    keys = keys?.length > 0 ? keys : Object.keys(objClone);
    for (const k of keys) {
      if (Array.isArray(objClone[k])) continue;
      const value = objClone[k];
      if (!(value || value === 0 || value === false) || value === "")
        delete objClone[k];
      if (typeof objClone[k] === "object") {
        if (removeNestedProperty)
          objClone[k] = StringHelper.removeEmptyProperties(
            objClone[k],
            Object.keys(objClone[k])
          );
        if (JSON.stringify(objClone[k]) === JSON.stringify({}))
          delete objClone[k];
      }
    }
    return objClone;
  }
}
