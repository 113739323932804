import React, { useState } from "react";
import styles from "./Header.module.scss";
import UbelaLogo from "../../../../assets/images/UbelaLogo.svg";
import UbelaMobileLogo from "../../../../assets/images/UbelaMobileLogo.svg";
import BurgerIcon from "../../../../assets/images/MenuBurger.svg";
import { motion } from "framer-motion";
import { Link, NavLink, useNavigate } from "react-router-dom";
import CloseIcon from "../../../../assets/images/closeIcon.svg";

function Header() {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  console.log(showMenu);
  const navigate = useNavigate();
  return (
    <>
      <div className={styles.Header}>
        <Link to="/">
          <img src={UbelaLogo} className={styles.Logo} />
          <img src={UbelaMobileLogo} className={styles.MobileLogo} />
        </Link>
        <ul>
          <NavLink to="/">
            {({ isActive }) => (
              <li style={{ fontWeight: isActive ? 500 : 300 }}>Home</li>
            )}
          </NavLink>

          <NavLink to="/blog">
            {({ isActive }) => (
              <li style={{ fontWeight: isActive ? 500 : 300 }}>Blog</li>
            )}
          </NavLink>
          <NavLink to="/faq">
            {({ isActive }) => (
              <li style={{ fontWeight: isActive ? 500 : 300 }}>FAQ</li>
            )}
          </NavLink>
          <button
            onClick={() => navigate("/app")}
            className={styles.HeaderButton}
          >
            Get Started
          </button>
        </ul>
        <img
          onClick={() => setShowMenu(true)}
          src={BurgerIcon}
          alt=""
          className={styles.BurgerIcon}
        />
      </div>
      {showMenu ? (
        <motion.div
          initial={{ transform: "scale(0)" }}
          animate={{ transform: "scale(1)" }}
          className={styles.MobileMenu}
        >
          <button
            style={{
              position: "absolute",
              top: 26,
              right: 24,
              border: "none",
              background: "transparent",
            }}
            onClick={() => setShowMenu(false)}
          >
            <img src={CloseIcon} alt="" />
          </button>
          <ul>
            <NavLink onClick={() => setShowMenu(false)} to="/">
              <li>Home</li>
            </NavLink>
            <NavLink onClick={() => setShowMenu(false)} to="/blog">
              <li>Blog</li>
            </NavLink>
            <NavLink onClick={() => setShowMenu(false)} to="/faq">
              <li>FAQ</li>
            </NavLink>
            <button
              onClick={() => navigate("/app")}
              className={styles.HeaderButton}
            >
              Get Started
            </button>
          </ul>
        </motion.div>
      ) : undefined}
    </>
  );
}

export default Header;
