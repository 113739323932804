import React, { useState, useEffect } from "react";
import styles from "./Toast.module.scss";
import { ReactComponent as Smiley } from "../../../../assets/icons/smile.svg";
import { ReactComponent as Frowny } from "../../../../assets/icons/frown.svg";

interface ToastProps {
  show: boolean;
  message: string;
  variant: "success" | "error" | "info";
}

const Toast: React.FC<ToastProps> = ({ show, message, variant }) => {
  const [isVisible, setIsVisible] = useState(show);

  console.log("showing", isVisible);

  useEffect(() => {
    if (show) {
      setIsVisible(true);
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 5000); // Adjust the duration in milliseconds (e.g., 3000 for 3 seconds)
      return () => clearTimeout(timer);
    }
  }, [show]);

  return (
    <>
      {isVisible && (
        <div
          className={`${styles.Toast} ${show && styles.show}`}
          id={styles[variant]}
          onClick={() => setIsVisible(false)}
        >
          {variant === "error" ? <Frowny /> : <Smiley />}
          <p>{message}</p>
        </div>
      )}
    </>
  );
};

export default Toast;
