import { NavLink } from "react-router-dom";
import HomeIcon from "../../../assets/icons/DashboardHome";
import ChampionshipIcon from "../../../assets/icons/DashboardChampionshipIcon";
import LessonsIcon from "../../../assets/icons/DashboardLessonsIcon";
import ProfileIcon from "../../../assets/icons/DashboardProfileIcon";
import styles from "./NavItems.module.scss";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { colors } from "../../../sdk/colors";
import { mobileBreakPoint } from "../../../constants/values";

function NavItems() {
  const { width } = useWindowDimensions();
  const isMobileBreakpoint = width < mobileBreakPoint;
  const focusedColor = isMobileBreakpoint ? colors.primary : colors.secondary;
  const defaultColor = isMobileBreakpoint ? colors.gray : colors.white;
  const getTextColor = (isActive: boolean): React.CSSProperties["color"] => {
    if (isActive) {
      return focusedColor;
    } else {
      if (isMobileBreakpoint) {
        return "transparent";
      } else {
        return defaultColor;
      }
    }
  };
  return (
    <>
      <nav className={styles.Nav}>
        <ul>
          <NavLink to="/app/learn" end>
            {({ isActive }) => (
              <li
                style={{
                  border:
                    isMobileBreakpoint && isActive
                      ? `1px solid ${colors.primary}`
                      : "none",
                }}
              >
                <HomeIcon color={isActive ? focusedColor : defaultColor} />
                <span
                  id="navtext"
                  style={{
                    color: getTextColor(isActive),
                  }}
                >
                  Home
                </span>
              </li>
            )}
          </NavLink>
          <NavLink to="/app/learn/championship">
            {({ isActive }) => (
              <li
                style={{
                  border:
                    isMobileBreakpoint && isActive
                      ? `1px solid ${colors.primary}`
                      : "none",
                }}
              >
                <ChampionshipIcon
                  color={isActive ? focusedColor : defaultColor}
                />
                <span id="navtext" style={{ color: getTextColor(isActive) }}>
                  Championship
                </span>
              </li>
            )}
          </NavLink>

          <NavLink to="/app/learn/lessons">
            {({ isActive }) => (
              <li
                style={{
                  border:
                    isMobileBreakpoint && isActive
                      ? `1px solid ${colors.primary}`
                      : "none",
                }}
              >
                <LessonsIcon color={isActive ? focusedColor : defaultColor} />
                <span id="navtext" style={{ color: getTextColor(isActive) }}>
                  Lessons
                </span>
              </li>
            )}
          </NavLink>

          <NavLink to="/app/learn/profile">
            {({ isActive }) => (
              <li
                style={{
                  border:
                    isMobileBreakpoint && isActive
                      ? `1px solid ${colors.primary}`
                      : "none",
                }}
              >
                <ProfileIcon color={isActive ? focusedColor : defaultColor} />
                <span id="navtext" style={{ color: getTextColor(isActive) }}>
                  Profile
                </span>
              </li>
            )}
          </NavLink>
        </ul>
      </nav>
    </>
  );
}

export default NavItems;
