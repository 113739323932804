import React, { useCallback } from "react";
import styles from "./ChampionshipResult.module.scss";
import successResult1 from "../../../assets/images/successResult1.webp";
import successResult2 from "../../../assets/images/successResult2.svg";
import successResult3 from "../../../assets/images/successResult3.webp";
import tryingResult1 from "../../../assets/images/tryingResult1.svg";
import { generateRandom } from "../../../constants/values";
import { colors } from "../../../sdk/colors";
import { CircularProgress, CircularProgressLabel } from "@chakra-ui/react";
import Text from "../../UI/atoms/Text";
import { motion } from "framer-motion";
import Button from "../../UI/atoms/Button";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useFinishLessonMutation } from "../../../redux/features/lessons/lessonsApiSlice";

function ChampionshipResult({
  results,
  variant,
  lessonId,
  topicId,
}: {
  results: number[];
  variant?: "championship" | "lesson";
  lessonId?: number;
  topicId?: number;
}) {
  const params = useParams<{ topicId: string }>();
  const [searchParams] = useSearchParams();
  const [
    finishLesson,
    {
      isError: isFinishLessonError,
      isSuccess: isFinishLessonSuccess,
      isLoading: isFinishLessonLoading,
      isUninitialized,
    },
  ] = useFinishLessonMutation();

  const handleFinishLesson = useCallback(async () => {
    const submitValues: { lessonId: number; topicId: number } = {
      lessonId: Number(params.topicId) || 0,
      topicId: Number(searchParams.get("id")) || 0,
    };
    console.log(submitValues, "submitValuesLessonStart");
    try {
      const response = await finishLesson(submitValues).unwrap();
      console.log(response, "finished");
      navigate(-2);
    } catch (e) {
      console.log(e);
    }
  }, [isFinishLessonError]);

  const navigate = useNavigate();
  const successImages = [successResult1, successResult2, successResult3];

  const correctValues: number[] = results.filter((value) => value === 1);
  const percentage = Number(
    ((correctValues.length / results.length) * 100).toFixed(0)
  );

  let resultStatus: "excellent" | "good" | "bad" = "good";

  if (percentage >= 50 && percentage <= 75) {
    resultStatus = "good";
  } else if (percentage > 75 && percentage <= 100) {
    resultStatus = "excellent";
  } else if (percentage < 50) {
    resultStatus = "bad";
  }

  let image: string;
  let color: React.CSSProperties["color"];

  switch (resultStatus) {
    case "excellent":
      image = successResult2;
      color = colors.success;
      break;
    case "good":
      image = successResult2;
      color = colors.warning;
      break;
    case "bad":
      image = tryingResult1;
      color = colors.error;
      break;
    default:
      image = tryingResult1;
      color = colors.warning;
  }

  return (
    <motion.div
      initial={{ transform: "scale(0)" }}
      animate={{ transform: "scale(1)" }}
      className={styles.ChampionshipResult}
    >
      <img src={image} alt="" />
      <CircularProgress
        size="149px"
        thickness="5px"
        value={percentage}
        color={color}
        marginTop="31px"
      >
        <CircularProgressLabel>
          <Text fontWeight="regular" variant="heading 2">{`${
            results.length > 0 ? percentage : 0
          }%`}</Text>
          <Text
            style={{ textAlign: "center", fontSize: 12, color: colors.gray }}
          >
            {correctValues.length} of {results.length}
          </Text>
        </CircularProgressLabel>
      </CircularProgress>
      {variant === "championship" ? (
        <Button
          onClick={() => navigate("/app/learn/championship")}
          buttonStyles={{ marginTop: "20px", width: "250px" }}
          variant="white"
        >
          End Quest
        </Button>
      ) : (
        <Button
          onClick={handleFinishLesson}
          buttonStyles={{ marginTop: "20px", width: "250px" }}
          variant="white"
          isLoading={isFinishLessonLoading}
        >
          Complete Lesson
        </Button>
      )}
    </motion.div>
  );
}

export default ChampionshipResult;
