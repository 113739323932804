import React from "react";
import { useGetLessonsActivityQuery } from "../../redux/features/analytics/analyticsApiSlice";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import { useGetUserQuery } from "../../redux/features/user/userApiSlice";
import { Skeleton, SkeletonText } from "@chakra-ui/react";
import styles from "./ContinueLearning.module.scss";
import { ReactComponent as EmptyActivityImage } from "../../assets/images/EmptyActivityImage.svg";
import Text from "../../components/UI/atoms/Text";
import { mergeAndFilterArrays } from "../../utils/removeCopies";
import { colors } from "../../sdk/colors";
import LessonItem from "../../components/UI/molecules/LessonItem";
import { subjects } from "../../constants/values";
import PageHeader from "../../components/UI/molecules/PageHeader";

function ContinueLearning() {
  const profileId = useAppSelector(
    (state: RootState) => state.auth.activeProfileId
  );

  const { data: user, isLoading } = useGetUserQuery(undefined, {
    refetchOnReconnect: true,
  });
  const { data: analytics } = useGetLessonsActivityQuery(
    Number(user?.id || profileId),
    { refetchOnMountOrArgChange: true }
  );

  console.log(analytics, "analytics");

  const unfinishedLessons = useAppSelector(
    (state: RootState) => state.lessons.unfinishedLessons
  );

  const finishedLessons = useAppSelector(
    (state: RootState) => state.lessons.finishedLessons
  );
  const continueLearningLessons = unfinishedLessons.filter(
    (lessons) => lessons.profileId === (user?.id || profileId)
  );

  const finishedContinueLearningLessons = finishedLessons.filter(
    (lessons) => lessons.profileId === (user?.id || profileId)
  );

  console.log(continueLearningLessons, "cll");
  return (
    <div className={styles.ContinueLearning}>
      <PageHeader pageTitle="Continue Learning" />
      {!isLoading &&
      mergeAndFilterArrays(
        analytics?.data,
        continueLearningLessons,
        finishedContinueLearningLessons
      ).length > 0 ? (
        <div className={styles.LessonsWrapper}>
          {mergeAndFilterArrays(
            analytics?.data,
            continueLearningLessons,
            finishedContinueLearningLessons
          ).map((lesson, index) => (
            <LessonItem
              progress={lesson.progress}
              key={lesson.id + index}
              index={index}
              lesson={lesson}
              subjectId={lesson.subjectId}
              color={
                subjects.find((subject) => subject.id === lesson.subjectId)
                  ?.color
              }
            />
          ))}
        </div>
      ) : (
        !isLoading && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "20px 0px",
              width: "100%",
            }}
          >
            <EmptyActivityImage />
            <Text>You can access unfinished lessons here </Text>
          </div>
        )
      )}
      {isLoading && (
        <>
          <Skeleton
            isLoaded={false}
            borderRadius="10px"
            className={styles.TopicSkeleton}
            startColor={colors.grey5}
            endColor={colors.grey4}
          />
          <Skeleton
            isLoaded={false}
            borderRadius="10px"
            className={styles.TopicSkeleton}
            startColor={colors.grey5}
            endColor={colors.grey4}
          />
          <Skeleton
            isLoaded={false}
            borderRadius="10px"
            className={styles.TopicSkeleton}
            startColor={colors.grey5}
            endColor={colors.grey4}
          />
          <Skeleton
            isLoaded={false}
            borderRadius="10px"
            className={styles.TopicSkeleton}
            startColor={colors.grey5}
            endColor={colors.grey4}
          />
          <Skeleton
            isLoaded={false}
            borderRadius="10px"
            className={styles.TopicSkeleton}
            startColor={colors.grey5}
            endColor={colors.grey4}
          />
          <Skeleton
            isLoaded={false}
            borderRadius="10px"
            className={styles.TopicSkeleton}
            startColor={colors.grey5}
            endColor={colors.grey4}
          />
        </>
      )}
    </div>
  );
}

export default ContinueLearning;
