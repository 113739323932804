import React from "react";
import { colors } from "../sdk/colors";
import { SubPlansTypes } from "../models/subscription";

export const mobileBreakPoint = 900;

export const subjects: {
  id: number;
  name: "english" | "mathematics" | "science";
  color: string;
}[] = [
  { id: 1, name: "english", color: colors.primary },
  { id: 2, name: "mathematics", color: colors.math },
  { id: 3, name: "science", color: colors.sci },
];

export const alphabet = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

export function generateRandom(min = 0, max = 100): number {
  // find diff
  let difference = max - min;

  // generate random number
  let rand = Math.random();

  // multiply with difference
  rand = Math.floor(rand * difference);

  // add with min value
  rand = rand + min;

  return rand;
}

export const grades: {
  value: number;
  label: string;
  color: React.CSSProperties["color"];
}[] = [
  {
    value: 1,
    label: "Primary 1",
    color: "#636363",
  },
  {
    value: 2,
    label: "Primary 2",
    color: "#9747FF",
  },
  {
    value: 3,
    label: "Primary 3",
    color: "#2C49E2",
  },
  {
    value: 4,
    label: "Primary 4",
    color: "#22B14C",
  },
  {
    value: 5,
    label: "Primary 5",
    color: "#FF2F75",
  },
  {
    value: 6,
    label: "Primary 6",
    color: "#00CA97",
  },
];

export const subscriptionPlans: SubPlansTypes[] = [
  "free",
  "3 months plan",
  "6 months plan",
  "12 months plan",
];

export const faqs = [
  {
    question: "What's the difference between an account and a profile?",
    answer:
      "An account is created by a guardian while profiles are created for students.",
  },
  {
    question: "How many profiles can be opened on one account?",
    answer:
      "Guardians can create up to 3 profiles for 3 students. These profiles are not restricted by class.",
  },
  {
    question:
      "Can I switch classes on profiles after payment for subscription?",
    answer:
      "Yes, you can switch profiles after payment for subscriptions, but you won’t be able to switch back to continue learning in the previous class.",
  },
  {
    question: "Can I move my account across devices?",
    answer:
      "For every account created by a guardian, it can be accessed from only 3 devices. This gives flexibility for students to learn from different profiles on different devices.",
  },
  {
    question: "What subjects are covered by the UBELA app?",
    answer:
      "The UBELA app currently covers primary mathematics, English, and science topics.",
  },
  {
    question: "What's the meaning of UBELA?",
    answer:
      "UBELA is an acronym for UNIVERSAL BASIC EDUCATION LEARNING APPLICATION; An education intervention for the development of children from primary one to primary 6. Its also a dramatic marsh-up of the names of our brilliant and friendly egghead teachers, Ube and Ela.",
  },
  {
    question: "What curriculum are the lessons based on?",
    answer:
      "The Universal Basic Education curriculum. (UBE) this is the gold standard curriculum for children between the age bracket of 5 to 14.",
  },
  {
    question: "How much does paid subscription cost?",
    answer:
      "3 months plan NGN8,750.\n 6 months plan NGN14,600.\n 12 months plan NGN24,500.",
  },
  {
    question: "How do I recover my account if I forget my login 🔑?",
    answer:
      "To recover an account, you can easily click on 'forgot password' to create a new password. You would be required to provide information like the email/phone number used to create the account.",
  },
  {
    question: "Can I download the lessons videos./watch Offline?",
    answer: "Yes, you can download lessons to watch offline.",
  },
  {
    question: "How do I qualify for the championship 🏆?",
    answer:
      "Paid subscribers automatically have the opportunity of friendly competition with othe children from allover the world. Just continue to answer as many questions as you can and you will see yourself rise on the leaderbord with shiny stars and badges to go with it. Leaders on the board will be picked periodically after winning cut off point is set.",
  },
  {
    question:
      "How do I reach out to get technical support from customer service?",
    answer:
      "Send Whatsapp messages to number: +2348132015005 Send e-mails to: ubela.app@gmail.com",
  },
  {
    question: "Do I get gifts and rewards for sharing and recommending UBELA?",
    answer:
      "Absolutely! You’ll be glad you did because amazing and cool prizes are up to be won when you spread the UBELA love. Easy Terms and Conditions apply though",
  },
];

export const ourOffers: { title: string; content: string }[] = [
  {
    title: "Colorful, Interactive Lessons",
    content:
      "Dive into our visually captivating lessons that bring subjects to life with vibrant graphics and engaging activities. Say goodbye to boring textbooks!",
  },
  {
    title: "Tailored Practice Exercises",
    content:
      "No two children learn alike. UBELA provides personalized practice exercises and quizzes designed to meet each child at their unique learning level, ensuring they can reinforce their understanding of key concepts covered in the lessons.",
  },
  {
    title: "Interactive Video Lessons",
    content:
      "Our interactive video lessons go beyond traditional teaching methods, encouraging active participation and hands-on learning. Watch as your child absorbs, retains, and applies knowledge more effectively through our dynamic multimedia content.",
  },
  {
    title: "Leaderboard Championship",
    content:
      "Let your child unleash their competitive spirit in the UBELA Leaderboard Championship! Engage in friendly head-to-head competitions with kids from all around the world, motivating them to strive for excellence and reach new heights.",
  },
  {
    title: "Professional Credibility",
    content:
      "Rest assured, UBELA's lessons and videos are meticulously designed and checked by seasoned educational professionals, ensuring the highest standards of quality and accuracy in every learning experience.",
  },
];
