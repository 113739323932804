import React, { useEffect } from "react";
import styles from "./Share.module.scss";
import PageHeader from "../../components/UI/molecules/PageHeader";
import {
  useGetReferralQuery,
  useGetUserQuery,
} from "../../redux/features/user/userApiSlice";
import Text from "../../components/UI/atoms/Text";
import { useCopyToClipboard } from "react-use";
import { useToast } from "../../context/toast.context";
import Button from "../../components/UI/atoms/Button";
import { ReactComponent as Phone } from "../../assets/images/Devices/Apple/iPhone 11 Pro.svg";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";

function Share() {
  const { show } = useToast();
  const { data: user } = useGetUserQuery(undefined);
  console.log(user);
  const { data, error } = useGetReferralQuery(undefined);
  const referralCode = useAppSelector(
    (state: RootState) => state.auth.userReferralCode
  );

  const [state, copyToClipboard] = useCopyToClipboard();

  console.log(data);

  console.log(data, error);

  useEffect(() => {
    if (state.value)
      if (state.error) {
        show({ message: `${state.error.message}`, variant: "error" });
      } else {
        show({ message: "Copied", variant: "info" });
      }
  }, [state]);

  const referralCodeValue = !!data?.data ? data.data : referralCode;

  return (
    <div className={styles.Share}>
      <PageHeader pageTitle="Share App" />
      <div className={styles.Container}>
        <div className={styles.Wrapper}>
          <Text
            variant="heading 4"
            fontWeight="medium"
            style={{ marginBottom: "10px" }}
          >
            Refer A Friend
          </Text>
          <Text variant="heading 5" fontWeight="regular">
            Share the UBELA App with friends and you get to win cool gifts and
            massive discounts on your next subscription.
          </Text>
        </div>
        <div className={styles.CopyWrapper}>
          <Text variant="heading 5" fontWeight="regular">
            {referralCodeValue}
          </Text>
          <button onClick={() => copyToClipboard(referralCodeValue)}>
            Copy
          </button>
        </div>
        <div className={styles.DownloadApp}>
          <div className={styles.DownloadContent}>
            <Text
              style={{ lineHeight: "normal", marginBottom: "6px" }}
              fontWeight="medium"
            >
              Share The UBELA App
            </Text>
            <Text
              style={{ marginBottom: "23px" }}
              variant="caption"
              fontWeight="light"
            >
              You love what you're getting?? Try the mobile.
            </Text>

            <Button
              onClick={() =>
                copyToClipboard(
                  `Learning is so much better with UBELA. It's primary school education at your finger tips. Every child needs this. Hurry! Download UBELA now! Use the code: ${referralCodeValue} to sign up. Click on the link: https://ubelalearn.com/`
                )
              }
              variant="white"
            >
              Copy Link
            </Button>
          </div>
          <Phone />
        </div>
      </div>
    </div>
  );
}

export default Share;
