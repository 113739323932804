import styles from "./Sidebar.module.scss";
import NavItems from "../NavItems";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";

import Button from "../../UI/atoms/Button";
import {
  handleLogout,
  updateUserRole,
} from "../../../redux/features/auth/authSlice";
import { ReactComponent as Logo } from "../../../assets/images/logo-primary.svg";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { mobileBreakPoint } from "../../../constants/values";
import {
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import logoutImage from "../../../assets/images/signOutImage.svg";
import Text from "../../UI/atoms/Text";
import { colors } from "../../../sdk/colors";
import { useLogoutMutation } from "../../../redux/features/auth/authApiSlice";
import { osName } from "react-device-detect";
import { MetaData } from "../../../models/auth";

function Sidebar() {
  const [logout, { isLoading }] = useLogoutMutation();
  const token = useAppSelector((state: RootState) => state.auth.authToken);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { width } = useWindowDimensions();
  const isMobileBreakpoint = width < mobileBreakPoint;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleUserLogout = () => {
    onOpen();
  };
  const handleLogoutUser = async () => {
    const submitValues: { hash: string; meta: MetaData } = {
      hash: `${token}`,
      meta: {
        osname: osName,
        deviceId: "sdsd",
        ipaddress: "127.0.0",
        brand: "mac",
      },
    };
    try {
      const response = await logout(submitValues).unwrap();
      console.log(response);
      onClose();
      dispatch(handleLogout());
    } catch (e) {
      console.log(e);
      onClose();
      dispatch(handleLogout());
    }
  };
  return (
    <>
      <aside className={styles.Sidebar}>
        <div id={styles.wrapper}>
          {!isMobileBreakpoint && (
            <div className={styles.UserDetails}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Logo onClick={() => navigate("/app")} id={styles.Logo} />
                {/* <h3>{name}</h3> */}
              </div>
            </div>
          )}
          <NavItems />
        </div>
        {!isMobileBreakpoint && (
          <div>
            <Button
              onClick={() => {
                navigate("/app/guardian", { replace: true });
                dispatch(updateUserRole("guardian"));
              }}
              variant="outlined"
              textColor="#fff"
            >
              Go to Guardian
            </Button>
          </div>
        )}
      </aside>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderRadius="20px" marginLeft="20px" marginRight="20px">
          <div className={styles.Logout}>
            <img src={logoutImage} alt="" />
            <Text variant="heading 5" style={{ color: colors.error }}>
              Are you sure you want to sign out from this device?
            </Text>
            <div className={styles.LogoutButtons}>
              <Button
                onClick={handleLogoutUser}
                isLoading={isLoading}
                variant="outlined"
                buttonStyles={{ marginBottom: "13px" }}
              >
                Sign out
              </Button>
              <button id={styles.TextButton} onClick={onClose}>
                No, cancel
              </button>
            </div>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Sidebar;
