import { useEffect } from "react";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { RootState } from "../redux/store";
import { handleLogout } from "../redux/features/auth/authSlice";

function LogOut() {
  const token = useAppSelector((state: RootState) => state.auth.authToken);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (!token) {
      dispatch(handleLogout());
      navigate("/app/login");
    }
  }, []);
  return null;
}

export default LogOut;
