import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const storedUserId = localStorage.getItem("userId");
const storedName = localStorage.getItem("name");
const storedAvatar = localStorage.getItem("avatar");
const storedReferralCode = localStorage.getItem("referralCode");

export interface UserState {
  hasSetDefaultLocation: boolean;
  selectedLocationId: number | undefined;
  selectedCity: string;
  selectedState: string;
  userToken: string;
  name: string;
  avatar: string;
  userId: number | undefined;
  userReferralCode: string;
}

const initialState: UserState = {
  hasSetDefaultLocation: false,
  selectedLocationId: undefined,
  selectedCity: "",
  selectedState: "",
  userToken: "",
  name: storedName ? JSON.parse(storedName) : "",
  userReferralCode: storedReferralCode ? JSON.parse(storedReferralCode) : "",
  avatar: storedAvatar ? JSON.parse(storedAvatar) : "",
  userId: storedUserId && JSON.parse(storedUserId),
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // handleHasSetDefaultLocation: (
    //   state,
    //   action: PayloadAction<{
    //     id: number | undefined;
    //     city: string;
    //     state: string;
    //   }>
    // ) => {
    //   state.hasSetDefaultLocation = true;
    //   state.selectedLocationId = action.payload.id;
    //   state.selectedCity = action.payload.city;
    //   state.selectedState = action.payload.state;
    // },
    // savePushToken: (state, action: PayloadAction<string>) => {
    //   state.userToken = action.payload;
    // },
    handleSaveUser: (
      state,
      action: PayloadAction<{
        name?: string;
        avatar?: string;
        id?: number | undefined;
      }>
    ) => {
      if (!!action?.payload?.name) {
        state.name = action?.payload?.name;
        localStorage.setItem("name", JSON.stringify(action.payload.name));
      }
      if (!!action?.payload?.avatar) {
        state.avatar = action.payload.avatar;
        localStorage.setItem("avatar", JSON.stringify(action.payload.avatar));
      }
      if (!!action?.payload?.id) {
        state.userId = action.payload.id;
        localStorage.setItem("userId", JSON.stringify(action.payload.id));
      }
    },
  },
});

export const { handleSaveUser } = userSlice.actions;

export default userSlice.reducer;
