import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Lesson } from "../../../models/lessons";
import {
  addItemIfNotExists,
  removeItemIfExists,
} from "../../../utils/removeCopies";

const storedUnFinishedLessons = localStorage.getItem("unfinishedLessons");
const storedFinishedLessons = localStorage.getItem("finishedLessons");

export interface LessonsState {
  unfinishedLessons: Lesson[] | [];
  finishedLessons: { id: number; lessonId: number; profileId: number }[];
  videoDB: any;
}

const initialState: LessonsState = {
  unfinishedLessons: storedUnFinishedLessons
    ? JSON.parse(storedUnFinishedLessons)
    : [],
  finishedLessons: storedFinishedLessons
    ? JSON.parse(storedFinishedLessons)
    : [],
  videoDB: undefined,
};

const lessonsSlice = createSlice({
  name: "lessons",
  initialState,
  reducers: {
    handleUnFinishedLesson: (state, action: PayloadAction<Lesson>) => {
      state.unfinishedLessons = addItemIfNotExists(
        state.unfinishedLessons,
        action.payload
      );
      localStorage.setItem(
        "unfinishedLessons",
        JSON.stringify(
          addItemIfNotExists(state.unfinishedLessons, action.payload)
        )
      );

      state.finishedLessons = removeItemIfExists(state.finishedLessons, {
        id: action.payload.id,
        profileId: action.payload.profileId,
        lessonId: action.payload.lessonId,
      });
      localStorage.setItem(
        "finishedLessons",
        JSON.stringify(
          removeItemIfExists(state.finishedLessons, {
            id: action.payload.id,
            profileId: action.payload.profileId,
            lessonId: action.payload.lessonId,
          })
        )
      );
    },
    handleFinishedLesson: (state, action: PayloadAction<Lesson>) => {
      state.unfinishedLessons = removeItemIfExists(
        state.unfinishedLessons,
        action.payload
      );
      localStorage.setItem(
        "unfinishedLessons",
        JSON.stringify(
          removeItemIfExists(state.unfinishedLessons, action.payload)
        )
      );

      state.finishedLessons = addItemIfNotExists(state.finishedLessons, {
        id: action.payload.id,
        profileId: action.payload.profileId,
        lessonId: action.payload.lessonId,
      });
      localStorage.setItem(
        "finishedLessons",
        JSON.stringify(
          addItemIfNotExists(state.finishedLessons, {
            id: action.payload.id,
            profileId: action.payload.profileId,
            lessonId: action.payload.lessonId,
          })
        )
      );
    },
    handleClearStoredLessons: (state) => {
      state.finishedLessons = [];
      state.unfinishedLessons = [];
      localStorage.removeItem("finishedLessons");
      localStorage.removeItem("unfinishedLessons");
    }
  },
});

export const { handleUnFinishedLesson, handleFinishedLesson, handleClearStoredLessons } =
  lessonsSlice.actions;

export default lessonsSlice.reducer;
