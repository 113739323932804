import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import styles from "./ChangePassword.module.scss";
import PageHeader from "../../components/UI/molecules/PageHeader";
import {
  useGetProfilesQuery,
  useUpdateProfileAvatarMutation,
} from "../../redux/features/profile/profileApiSlice";
import ProfileAvatar from "../../components/UI/atoms/ProfileAvatar";
import { ReactComponent as EditIcon } from "../../assets/icons/manageProfileEditIcon.svg";
import Text from "../../components/UI/atoms/Text";
import { ProfileHelper } from "../../utils/profileHelper";
import { colors } from "../../sdk/colors";
import {
  useGetUserQuery,
  useUploadImageMutation,
} from "../../redux/features/user/userApiSlice";
import Input from "../../components/UI/atoms/Input";
import { UpdateProfileAvatarBody } from "../../models/profile";
import Button from "../../components/UI/atoms/Button";
import { ChangePasswordBody } from "../../models/user";
import { useChangePasswordMutation } from "../../redux/features/auth/authApiSlice";
import { useToast } from "../../context/toast.context";
import { ErrorResponse } from "../../models/utilityTypes";
import LoaderContext from "../../context/loader.context";
import { useAppDispatch } from "../../redux/hooks";
import { handleSaveUser } from "../../redux/features/user/userSlice";

function ChangePassword() {
  const [changePassword, { isLoading: isChangePasswordLoading }] =
    useChangePasswordMutation();

  const dispatch = useAppDispatch();

  const { show } = useToast();
  const { setLoader: setLoading } = useContext(LoaderContext);

  const [uploadImageAsync, { isLoading: isUploadImageLoading }] =
    useUploadImageMutation();

  const [updateAvatar, { isLoading: isUpdateAvatarLoading }] =
    useUpdateProfileAvatarMutation();

  const [image, setImage] = useState<File>();
  const [imageUrl, setImageUrl] = useState<string>("");
  const { profileId } = useParams<{ profileId: string }>();
  console.log(profileId);
  const { data: user, isLoading } = useGetUserQuery(undefined);

  const initialValues: ChangePasswordBody = {
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };

  const validationSchema = Yup.object({
    oldPassword: Yup.string().required("This is a required filed."),
    newPassword: Yup.string()
      .required("No password provided.")
      .min(10, "must be at least 8 characters")
      .matches(/[A-Z]/, "must contain at least one uppercase letter")
      .matches(/[a-z]/, "must contain at least one lowercase letter")
      .matches(/[0-9]/, "must contain at least one number")
      .matches(/[@$!%*#?&]/, "must contain a special character"),
    confirmNewPassword: Yup.string()
      .required("This is a required field")
      .oneOf([Yup.ref("newPassword"), ""], "Passwords must match"),
  });

  const onSubmit = async (
    values: ChangePasswordBody,
    { resetForm }: { resetForm: any }
  ) => {
    try {
      const response = await changePassword(values).unwrap();
      console.log(response);
      show({ message: `${response?.message}`, variant: "success" });
      resetForm();
    } catch (e: any) {
      console.log(e);
      const error: ErrorResponse = e;
      show({ message: `${error.data.message}`, variant: "error" });
    }
  };

  const fileInputRef = useRef<null | HTMLInputElement>(null);

  const clickInput = () => {
    if (fileInputRef.current === null) return;
    fileInputRef.current.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0];
    if (!file) return;
    setImage(file);
  };

  const uploadImage = async () => {
    const formData = new FormData();
    if (image) {
      formData.append("image", image);
    }
    try {
      setLoading(true);
      const response = await uploadImageAsync(formData).unwrap();
      setLoading(false);
      show({
        message: "Profile image updated successfully.",
        variant: "success",
      });
      dispatch(handleSaveUser({ avatar: response?.data?.url || "" }));
      console.log(response, "upload successful");
    } catch (e) {
      setLoading(false);
      console.log(e);
      show({
        message:
          (e as ErrorResponse)?.data?.message ||
          (e as ErrorResponse)?.data?.error ||
          "Something went wrong",
        variant: "success",
      });
    }
  };

  useEffect(() => {
    if (image) {
      uploadImage();
    }
  }, [image]);

  console.log(user, "user");
  return (
    <div className={styles.ChangePassword}>
      <PageHeader pageTitle="Change Password" />
      <div className={styles.Container}>
        <div className={styles.AvatarWrapper}>
          <button
            onClick={clickInput}
            type="button"
            className={styles.EditAvatar}
          >
            <input
              onChange={handleFileChange}
              ref={fileInputRef}
              accept="image/jpeg"
              type="file"
              style={{ display: "none" }}
            />
            <img
              src={user?.avatar?.url}
              style={{
                borderRadius: "22px",
                height: "76px",
                width: "76px",
                border: `2px solid ${colors.primary}`,
                objectFit: "cover",
                backgroundColor: "#fff",
              }}
            />
            <EditIcon style={{ position: "absolute", right: -26, top: 20 }} />
          </button>
          <Text fontWeight="medium">{`${user?.firstName || ""} ${
            user?.lastName || ""
          }`}</Text>
          <Text
            style={{ marginTop: "6px" }}
            color={colors.primary}
            variant="heading 6"
          >
            Free Account
          </Text>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form style={{ width: "100%" }}>
              <div className={styles.Form}>
                <div className={styles.InputWrapper}>
                  <Input
                    type="password"
                    label="Old Password"
                    id="oldPassword"
                    name="oldPassword"
                    value={values.oldPassword}
                    error={!!errors.oldPassword && touched.oldPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.oldPassword && errors.oldPassword && (
                    <Text variant="caption" color={colors.error}>
                      {errors.oldPassword}
                    </Text>
                  )}
                </div>
                <div className={styles.InputWrapper}>
                  <Input
                    type="password"
                    label="New Password"
                    id="newPassword"
                    name="newPassword"
                    value={values.newPassword}
                    error={!!errors.newPassword && touched.newPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.newPassword && errors.newPassword && (
                    <Text variant="caption" color={colors.error}>
                      {errors.newPassword}
                    </Text>
                  )}
                </div>
                <div className={styles.InputWrapper}>
                  <Input
                    type="password"
                    label="Confirm New Password"
                    id="confirmNewPassword"
                    name="confirmNewPassword"
                    value={values.confirmNewPassword}
                    error={
                      !!errors.confirmNewPassword && touched.confirmNewPassword
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.confirmNewPassword && errors.confirmNewPassword && (
                    <Text variant="caption" color={colors.error}>
                      {errors.confirmNewPassword}
                    </Text>
                  )}
                </div>
                <Button
                  isLoading={isChangePasswordLoading}
                  onClick={handleSubmit}
                  buttonStyles={{ marginBottom: "30px", marginTop: "50px" }}
                >
                  Change Password
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default ChangePassword;
