import React from "react";
import LeaderBoard from "../../components/organisms/LeaderBoard";
import styles from "./FullLeaderboard.module.scss";
import PageHeader from "../../components/UI/molecules/PageHeader";
import { useGetChampionshipsQuery, useGetLeaderBoardQuery } from "../../redux/features/championship/championshipApiSlice";
import { useGetUserQuery } from "../../redux/features/user/userApiSlice";
import Text from "../../components/UI/atoms/Text";
import LeaderBoardItem from "../../components/UI/molecules/LeaderBoardItem";
import { colors } from "../../sdk/colors";
import { Skeleton } from "@chakra-ui/react";
import { championshipHelper } from "../../utils/championshipHelper";

function FullLeaderboard() {
  const { data: user } = useGetUserQuery(undefined);
  const { isLoading: isLeaderboardLoading, data: leaderboard } =
    useGetLeaderBoardQuery(undefined);
    const { data: championship } = useGetChampionshipsQuery(undefined);


  const championshipLeague = championshipHelper.getUserType(
    Number(user?.grade),
    championship?.data
  );

  console.log(championshipLeague, "league");

  const filteredLeaderboard = leaderboard?.data.filter(
    (item) =>
      championshipHelper.getUserType(
        Number(item?.profile?.grade),
        championship?.data
      ) === championshipLeague
  );

  const userLeaderBoardData = filteredLeaderboard?.find(
    (profile) => profile.profile.id === user?.id
  );
  const userLeaderBoardPosition = filteredLeaderboard?.findIndex(
    (profile) => profile.profile.id === user?.id
  );

  console.log(userLeaderBoardPosition, "position");

  return (
    <div className={styles.FullLeaderboard}>
      <PageHeader pageTitle="" />
      <div className={styles.Container}>
        <div className={styles.AvatarWrapper}>
          <div className={styles.Avatar}>
            <img src={user?.avatar?.url} />
          </div>
          <Text
            variant="heading 4"
            style={{ marginBottom: "10px" }}
            fontWeight="light"
          >
            {user?.firstName}
          </Text>
          {userLeaderBoardData?.score ? (
            <>
              <Text
                color={colors.grey2}
                variant="heading 5"
                fontWeight="regular"
              >
                Overall Position:{" "}
                {Number(Number(userLeaderBoardPosition) + 1) ||
                  "Can't find your position"}
              </Text>
              <Text
                color={colors.grey2}
                variant="heading 5"
                fontWeight="regular"
              >
                Overall Points: {userLeaderBoardData?.score || "0"}
              </Text>
            </>
          ) : (
            <Text color={colors.grey2} variant="heading 5" fontWeight="regular">
              You don't have a score
            </Text>
          )}
        </div>
        <div className={styles.Header}>
          <Text variant="heading 4" fontWeight="light">
            Leaderboard
          </Text>
        </div>
        <div className={styles.Wrapper}>
          {isLeaderboardLoading && (
            <>
              <Skeleton
                isLoaded={false}
                borderRadius="10px"
                className={styles.ItemSkeleton}
                startColor={colors.grey5}
                endColor={colors.grey4}
              />
              <Skeleton
                isLoaded={false}
                borderRadius="10px"
                className={styles.ItemSkeleton}
                startColor={colors.grey5}
                endColor={colors.grey4}
              />
              <Skeleton
                isLoaded={false}
                borderRadius="10px"
                className={styles.ItemSkeleton}
                startColor={colors.grey5}
                endColor={colors.grey4}
              />
              <Skeleton
                isLoaded={false}
                borderRadius="10px"
                className={styles.ItemSkeleton}
                startColor={colors.grey5}
                endColor={colors.grey4}
              />
              <Skeleton
                isLoaded={false}
                borderRadius="10px"
                className={styles.ItemSkeleton}
                startColor={colors.grey5}
                endColor={colors.grey4}
              />
              <Skeleton
                isLoaded={false}
                borderRadius="10px"
                className={styles.ItemSkeleton}
                startColor={colors.grey5}
                endColor={colors.grey4}
              />
              <Skeleton
                isLoaded={false}
                borderRadius="10px"
                className={styles.ItemSkeleton}
                startColor={colors.grey5}
                endColor={colors.grey4}
              />
              <Skeleton
                isLoaded={false}
                borderRadius="10px"
                className={styles.ItemSkeleton}
                startColor={colors.grey5}
                endColor={colors.grey4}
              />
            </>
          )}
          {filteredLeaderboard?.map((item, index) => (
            <LeaderBoardItem
              isUser={item.profile.id === user?.id}
              key={item.id}
              index={index + 1}
              score={`${item.score}`}
              profile={item.profile}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default FullLeaderboard;
