import React, { useState } from "react";
import AuthContainer from "../../containers/AuthContainer";
import Text from "../../components/UI/atoms/Text";
import styles from "./ResetPassword.module.scss";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { colors } from "../../sdk/colors";
import Button from "../../components/UI/atoms/Button";
import Input from "../../components/UI/atoms/Input";
import { ResetPasswordBody } from "../../models/auth";
import { ReactComponent as EyeOpen } from "../../assets/images/eyeOpen.svg";
import { ReactComponent as EyeClosed } from "../../assets/images/eyeClosed.svg";
import { useResetPasswordMutation } from "../../redux/features/auth/authApiSlice";
import OTPInput from "../../components/UI/molecules/OTPInput";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import { useToast } from "../../context/toast.context";
import { ErrorResponse } from "../../models/utilityTypes";
import { useNavigate } from "react-router-dom";

function ResetPassword() {
  const [resetPassword, { isLoading }] = useResetPasswordMutation();
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState<boolean>(false);

  const { show } = useToast();
  const navigate = useNavigate();

  const phoneNumber = useAppSelector(
    (state: RootState) => state.auth.phoneNumber
  );

  const [otp, setOtp] = useState<string>("");

  const initialValues: ResetPasswordBody = {
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .required("No password provided.")
      .min(10, "must be at least 7 characters")
      .matches(/[A-Z]/, "must contain at least one uppercase letter")
      .matches(/[a-z]/, "must contain at least one lowercase letter")
      .matches(/[0-9]/, "must contain at least one number")
      .matches(/[@$!%*#?&]/, "must contain a special character"),
    confirmPassword: Yup.string()
      .required("This is a required field")
      .oneOf([Yup.ref("password"), ""], "Passwords must match"),
  });

  const onSubmit = async (values: ResetPasswordBody) => {
    const submitValues: ResetPasswordBody = {
      hash: otp,
      phoneNumber,
      password: values.password,
    };
    try {
      const response = await resetPassword(submitValues).unwrap();
      console.log(response);
      show({ message: `${response.message}`, variant: "success" });
      navigate("/app/login")
      
    } catch (e: any) {
      console.log(e);
      const error: ErrorResponse = e;
      show({ message: `${error.data.message}`, variant: "error" });
    }
  };

  return (
    <AuthContainer canGoBack>
      <div className={styles.ResetPassword}>
        <div className={styles.AuthHeader}>
          <Text variant="heading 4" fontWeight="light">
            Reset Password
          </Text>
        </div>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <Form>
              <div className={styles.Form}>
                <div className={styles.InputWrapper}>
                  <Text variant="body" style={{ marginBottom: "10px" }}>
                    Enter Code
                  </Text>
                  <OTPInput
                    otpCode={otp}
                    setOTPCode={(value) => setOtp(value)}
                  />
                </div>
                <div className={styles.InputWrapper}>
                  <Input
                    label="Enter New Password"
                    type={isPasswordVisible ? "text" : "password"}
                    inputAdornment={
                      isPasswordVisible ? <EyeClosed /> : <EyeOpen />
                    }
                    onClickAdornment={() =>
                      setIsPasswordVisible(!isPasswordVisible)
                    }
                    name="password"
                    id="password"
                    value={values.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={!!(touched.password && errors.password)}
                  />
                  {touched.password && errors.password && (
                    <Text variant="caption" color={colors.error}>
                      {errors.password}
                    </Text>
                  )}
                </div>
                <div className={styles.InputWrapper}>
                  <Input
                    label="Confirm Password"
                    type={isConfirmPasswordVisible ? "text" : "password"}
                    inputAdornment={
                      isConfirmPasswordVisible ? <EyeClosed /> : <EyeOpen />
                    }
                    onClickAdornment={() =>
                      setIsConfirmPasswordVisible(!isConfirmPasswordVisible)
                    }
                    name="confirmPassword"
                    id="confirmPassword"
                    value={values.confirmPassword}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={
                      !!(touched.confirmPassword && errors.confirmPassword)
                    }
                  />
                  {touched.confirmPassword && errors.confirmPassword && (
                    <Text variant="caption" color={colors.error}>
                      {errors.confirmPassword}
                    </Text>
                  )}
                </div>
                <Button
                  disabled={otp.length < 6}
                  buttonStyles={{ marginTop: "80px" }}
                  isLoading={isLoading}
                  onClick={handleSubmit}
                >
                  Continue
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </AuthContainer>
  );
}

export default ResetPassword;
