import React from "react";

function NextButtonIcon({ disabled = false }: { disabled?: boolean }) {
  return (
    <svg
      width="116"
      height="54"
      viewBox="0 0 116 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_di_735_1179)">
        <path
          d="M111.111 21.8955C111.111 15.1034 105.903 9.44591 99.1345 8.8846L18.19 2.17213C10.5802 1.54107 4.05553 7.5471 4.05553 15.183V34.0903C4.05553 41.6257 10.417 47.5966 17.9373 47.1197L98.8818 41.9866C105.758 41.5506 111.111 35.8468 111.111 28.9572V21.8955Z"
          fill="url(#paint0_linear_735_1179)"
          shapeRendering="crispEdges"
        />
        <path
          d="M110.458 21.8955C110.458 15.443 105.511 10.0684 99.0805 9.53514L18.1361 2.82267C10.9068 2.22317 4.70831 7.92889 4.70831 15.183V34.0903C4.70831 41.249 10.7517 46.9213 17.896 46.4682L98.8405 41.3352C105.372 40.9209 110.458 35.5023 110.458 28.9572V21.8955Z"
          stroke="url(#paint1_linear_735_1179)"
          strokeWidth="1.30556"
          shapeRendering="crispEdges"
        />
      </g>
      <defs>
        <filter
          id="filter0_di_735_1179"
          x="0.138753"
          y="0.821398"
          width="114.889"
          height="52.8529"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.61111" />
          <feGaussianBlur stdDeviation="1.95833" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_735_1179"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_735_1179"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5.22222" />
          <feGaussianBlur stdDeviation="2.61111" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_735_1179"
          />
        </filter>
        <linearGradient
          id="paint0_linear_735_1179"
          x1="105.911"
          y1="1.00004"
          x2="-6.10882"
          y2="13.3579"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={disabled ? "#00000040" : "#1CEB59"} />
          <stop
            offset="1"
            stopColor={disabled ? "#00000040" : "#4EE279"}
            stopOpacity="0.73"
          />
        </linearGradient>
        <linearGradient
          id="paint1_linear_735_1179"
          x1="127.628"
          y1="99.1471"
          x2="-9.97911"
          y2="78.3799"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={disabled ? "#00000040" : "#22B14C"} />
          <stop
            offset="1"
            stopColor={disabled ? "#00000040" : "#69C217"}
            stopOpacity="0.3"
          />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default NextButtonIcon;
