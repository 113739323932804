import {
  ConfirmPhoneResponse,
  ConfirmPhoneBody,
  ForgotPasswordBody,
  ForgotPasswordResponse,
  LoginBody,
  LoginResponse,
  RegistrationBody,
  RegistrationResponse,
  ResetPasswordBody,
  RolePropTypes,
  ResetPasswordResponse,
  MetaData,
} from "../../../models/auth";
import { ChangePasswordBody } from "../../../models/user";
import { apiSlice } from "../../apiSlice";

const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    register: builder.mutation<RegistrationResponse, RegistrationBody>({
      query: (body) => ({
        url: "/account/user/register",
        method: "post",
        body: { ...body },
      }),
      invalidatesTags: ["User", "Profile", "Lesson"],
    }),
    confirmPhoneNumber: builder.mutation<
      ConfirmPhoneResponse,
      ConfirmPhoneBody
    >({
      query: (body) => ({
        url: "/account/phone/verify",
        method: "post",
        body: { ...body },
      }),
    }),
    resendCode: builder.mutation<any, { phoneNumber: string }>({
      query: (body) => ({
        url: "/account/resend-phone-verify-code",
        method: "post",
        body: { ...body },
      }),
    }),
    forgotPassword: builder.mutation<
      ForgotPasswordResponse,
      ForgotPasswordBody
    >({
      query: (body) => ({
        url: "/account/forgot-password",
        method: "post",
        body: { ...body },
      }),
    }),
    login: builder.mutation<LoginResponse, LoginBody>({
      query: (body) => ({
        url: `/account/user/login`,
        method: "post",
        body: { ...body },
      }),
      invalidatesTags: ["User", "Profile", "Lesson"],
    }),
    resetPassword: builder.mutation<ResetPasswordResponse, ResetPasswordBody>({
      query: (body) => ({
        url: "/account/reset-password",
        method: "post",
        body: { ...body },
      }),
    }),
    logout: builder.mutation<any, {hash: string; meta:MetaData}>({
      query: (body) => ({
        url: "/account/logout",
        method: "post",
        body: { ...body },
      }),
    }),
    changePassword: builder.mutation<{message: string}, ChangePasswordBody>({
      query: (body) => ({
        url: "/account/change-password",
        method: "post",
        body: { ...body },
      }),
    }),
  }),
});

export const {
  useRegisterMutation,
  useConfirmPhoneNumberMutation,
  useLoginMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useResendCodeMutation,
  useLogoutMutation,
  useChangePasswordMutation
} = authApiSlice;
