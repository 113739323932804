import React from "react";
import styles from "./PageHeader.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as GoBackIcon } from "../../../../assets/icons/chevron-left.svg";
import Text from "../../atoms/Text";
import { ReactComponent as NotificationIcon } from "../../../../assets/icons/notificationIcon.svg";
import { ReactComponent as ContactSupportIcon } from "../../../../assets/icons/contactSupportIcon.svg";
import { colors } from "../../../../sdk/colors";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { mobileBreakPoint } from "../../../../constants/values";

function PageHeader({
  pageTitle,
  handleGoBack,
  rightComponent,
  style,
  textColor = colors.black1,
  canGoBack = true,
}: {
  pageTitle: string;
  handleGoBack?: () => void;
  rightComponent?: JSX.Element;
  style?: React.CSSProperties;
  textColor?: React.CSSProperties["color"];
  canGoBack?: boolean;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const { width } = useWindowDimensions();
  const isMobile: boolean = width < mobileBreakPoint;
  return (
    <div style={style} className={styles.Header}>
      {location.key && canGoBack ? (
        <button
          type="button"
          onClick={handleGoBack ? handleGoBack : () => navigate(-1)}
          className={styles.GoBackButton}
        >
          <GoBackIcon />
        </button>
      ) : (
        <div></div>
      )}
      <div className={styles.TextWrapper}>
        <Text
          style={{ textAlign: "center" }}
          color={textColor}
          variant={isMobile ? "body" : "heading 4"}
        >
          {pageTitle}
        </Text>
      </div>
      <div style={{ zIndex: 10 }}>
        {!rightComponent ? (
          <ContactSupportIcon
            style={{ cursor: "pointer", zIndex: 10 }}
            onClick={() => navigate("/help")}
          />
        ) : (
          rightComponent
        )}
      </div>
    </div>
  );
}

export default PageHeader;
