import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import styles from "./GuardianDashboard.module.scss";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import Decor1 from "../../assets/icons/dashboardDecor1.svg";
import Decor2 from "../../assets/icons/dashboardDecor2.svg";
import Decor3 from "../../assets/icons/dashboardDecor3.svg";
import { updateUserRole } from "../../redux/features/auth/authSlice";
import { RootState } from "../../redux/store";
import { useGetUserQuery } from "../../redux/features/user/userApiSlice";

function GuardianDashboard() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const userRole = useAppSelector((state: RootState) => state.auth.userRole);
  const { data: user, error } = useGetUserQuery(undefined);

  const handleSwitchToGuardianFailSafe = () => {
    console.log("fail safe");
    if (user) {
      if (userRole !== user?.role) {
        console.log("fail safe", userRole, user.role);
        dispatch(updateUserRole("guardian"));
        window.location.reload();
      }
    }
  };
  useEffect(() => {
    console.log("mounting guardian");
    dispatch(updateUserRole("guardian"));
    handleSwitchToGuardianFailSafe();
  }, [location]);
  return (
    <div className={styles.GuardianDashboard}>
      <img src={Decor1} id={styles.decor1} />
      <img src={Decor2} id={styles.decor2} />
      <img src={Decor3} id={styles.decor3} />
      <div className={styles.PageWrapper}>
        <Outlet />
      </div>
    </div>
  );
}

export default GuardianDashboard;
