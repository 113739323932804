import {
  Outlet,
  useNavigate,
  // Navigate,
} from "react-router-dom";
import { useAppSelector } from "./redux/hooks";
import { RootState } from "./redux/store";
import LogOut from "./hoc/LogOut";
import { useEffect, useLayoutEffect } from "react";

function PrivateRoutes() {
  const navigate = useNavigate();
  
  const token = useAppSelector((state: RootState) => state.auth.authToken);

  return token ? <Outlet /> : <LogOut />;
}

export default PrivateRoutes;
