import { StylesProvider } from "@chakra-ui/react";
import React from "react";
import styles from "./Blog.module.scss";
import Header from "../../components/UI/molecules/LandingPageHeader";
import Footer from "../../components/UI/molecules/Footer";
import ComingSoonImage from "../../assets/images/welcomeImage.svg";

function Blog() {
  return (
    <div className={styles.Container}>
      <Header />
      <div className={styles.HeroSection}>
        <img src={ComingSoonImage} alt="" />
        <h1>Coming Soon...</h1>
      </div>
      <Footer />
    </div>
  );
}

export default Blog;
