import { Spinner } from "@chakra-ui/react";
import React, { Ref, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import styles from "./VideoPlayer.module.scss";
import { ReactComponent as PlayIcon } from "../../../assets/icons/playIcon.svg";
import { useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";

function VideoPlayer({
  isLoading,
  hasBeenDownloaded,
  handleEndedVideo,
  handleRestrictAccess,
  handleStartedVideo,
  downloadedUrl,
  url,
  isFetchVideoSuccess,
  isError,
  isVideoLoading,
  videoPlayerRef,
  lessonId,
}: {
  isLoading: boolean;
  isFetchVideoSuccess: boolean;
  hasBeenDownloaded;
  isError: boolean;
  handleStartedVideo: () => void;
  handleEndedVideo: () => void;
  lessonId: number | undefined;
  handleRestrictAccess: ({
    playedSeconds,
    played,
  }: {
    playedSeconds: number;
    played: number;
  }) => void;
  downloadedUrl: string | undefined;
  url: string | undefined;
  isVideoLoading: boolean;
  videoPlayerRef: any;
}) {
  // const [totalDuration, setTotalDuration] = useState<number>(0);

  // const unfinishedLessons = useAppSelector((state: RootState) => state.lessons.unfinishedLessons);

  // const lessonUnfinished = unfinishedLessons.find((lesson) => lesson.id === Number(lessonId))

  // const isUnfinished = !!lessonUnfinished;

  // const timeToSeek = (lessonUnfinished?.progress || 0) * totalDuration;

  // useEffect(() => {
  //   if (isUnfinished) {
  //     videoPlayerRef?.current?.seekTo(timeToSeek, 'seconds');
  //   }
  // }, [isUnfinished]);

  console.log(hasBeenDownloaded, "video download status", downloadedUrl);

  return (
    <div className={styles.PlayerWrapper}>
      {!hasBeenDownloaded && isLoading && (
        <Spinner
          color="#fff"
          style={{ position: "absolute", height: "58px", width: "58px" }}
        />
      )}
      {isFetchVideoSuccess && hasBeenDownloaded ? (
        <ReactPlayer
          light={false}
          previewTabIndex={0}
          playIcon={<PlayIcon />}
          width="100%"
          height="100%"
          config={{
            file: {
              attributes: {
                controlsList: "nodownload",
              },
            },
          }}
          onEnded={() => handleEndedVideo()}
          controls
          onProgress={handleRestrictAccess}
          url={downloadedUrl}
          playing
        />
      ) : (
        <ReactPlayer
          light={false}
          ref={videoPlayerRef}
          config={{
            file: {
              attributes: {
                controlsList: "nodownload",
              },
            },
          }}
          onEnded={() => handleEndedVideo()}
          previewTabIndex={0}
          onProgress={handleRestrictAccess}
          playIcon={<PlayIcon />}
          width="100%"
          height="100%"
          controls
          url={url}
          playing
        />
      )}
    </div>
  );
}

export default React.memo(VideoPlayer);
