import React, { useState } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import AuthContainer from "../../containers/AuthContainer";
import styles from "./Signup.module.scss";
import Text from "../../components/UI/atoms/Text";
import Input from "../../components/UI/atoms/Input";
import { ReactComponent as EyeOpen } from "../../assets/images/eyeOpen.svg";
import { ReactComponent as EyeClosed } from "../../assets/images/eyeClosed.svg";
import Button from "../../components/UI/atoms/Button";
import { colors } from "../../sdk/colors";
import { useNavigate } from "react-router-dom";
import OTPInput from "../../components/UI/molecules/OTPInput";
import { RegistrationBody } from "../../models/auth";
import { useRegisterMutation } from "../../redux/features/auth/authApiSlice";
import { useAppDispatch } from "../../redux/hooks";
import {
  updateConfirmEmailMessage,
  updateNumberToReceiveCode,
} from "../../redux/features/auth/authSlice";
import { ErrorResponse } from "../../models/utilityTypes";
import { useToast } from "../../context/toast.context";

function Signup() {
  const dispatch = useAppDispatch();
  const {show} = useToast();

  const navigate = useNavigate();
  const [register, { isLoading }] = useRegisterMutation();
  const initialValues: RegistrationBody = {
    email: "",
    phoneNumber: "",
    firstName: "",
    lastName: "",
    password: "",
  };
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("This is a required field"),
    firstName: Yup.string().required("This is a required field"),
    lastName: Yup.string().required("This is a required field"),
    phoneNumber: Yup.string()
      .matches(
        /^(\+234|0)(701|702|703|704|705|706|707|708|709|802|803|804|805|806|807|808|809|810|811|812|813|814|815|816|817|818|819|909|908|901|902|903|904|905|906|907|910|911|912|913|914|915|916|917|918|919)([0-9]{7})$/,
        "Invalid phone number"
      )
      .required("This is a required field"),
    password: Yup.string()
      .required("No password provided.")
      .min(8, "must be at least 8 characters")
      .matches(/[A-Z]/, "must contain at least one uppercase letter")
      .matches(/[a-z]/, "must contain at least one lowercase letter")
      .matches(/[0-9]/, "must contain at least one number")
      .matches(/[@$!%*#?&]/, "must contain a special character"),
    referralCode: Yup.string(),
  });

  const onSubmit = async (values: RegistrationBody) => {
    const phone = values.phoneNumber.match(/^([0]{1})[0-9]{10}$/)
    ? values.phoneNumber.replace(/\D|^0+/g, "+234")
    : values.phoneNumber;
    const submitValues: RegistrationBody = {
      ...values,
      phoneNumber: phone,
    };
    try {
      const response = await register(submitValues).unwrap();
      console.log(response);
      dispatch(updateNumberToReceiveCode(phone));
      dispatch(updateConfirmEmailMessage(response.message));
      navigate("/app/verify-email");
    } catch (e: any) {
      console.log(e);
      const error: ErrorResponse = e;
      show({ message: `${error.data.message.join(", and ")}`, variant: "error" });
    }
  };

  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  return (
    <AuthContainer>
      <div className={styles.Signup}>
        <div className={styles.AuthHeader}>
          <Text
            fontWeight="medium"
            variant="body"
            style={{ marginBottom: "6px" }}
          >
            By parents
          </Text>
          <Text variant="heading 4" fontWeight="light">
            Create Your UBELA Account
          </Text>
        </div>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <Form>
              <div className={styles.Form}>
                <div className={styles.Row}>
                  <div className={styles.InputWrapper}>
                    <Input
                      name="firstName"
                      placeholder="First Name"
                      label="First Name"
                      id="firstName"
                      value={values.firstName}
                      error={!!(touched.firstName && errors.firstName)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.firstName && errors.firstName && (
                      <Text variant="caption" color={colors.error}>
                        {errors.firstName}
                      </Text>
                    )}
                  </div>
                  <div className={styles.InputWrapper}>
                    <Input
                      name="lastName"
                      placeholder="Last Name"
                      label="Last Name"
                      id="lastName"
                      value={values.lastName}
                      error={!!(touched.lastName && errors.lastName)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.lastName && errors.lastName && (
                      <Text variant="caption" color={colors.error}>
                        {errors.lastName}
                      </Text>
                    )}
                  </div>
                </div>
                <div className={styles.InputWrapper}>
                  <Input
                    name="email"
                    type="email"
                    placeholder="Your Email"
                    label="Enter Email"
                    id="email"
                    value={values.email}
                    error={!!(touched.email && errors.email)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {touched.email && errors.email && (
                    <Text variant="caption" color={colors.error}>
                      {errors.email}
                    </Text>
                  )}
                </div>
                <div className={styles.InputWrapper}>
                  <Input
                    name="phoneNumber"
                    placeholder="Your Phone Number"
                    label="Enter Phone Number"
                    id="phoneNumber"
                    value={values.phoneNumber}
                    error={!!(touched.phoneNumber && errors.phoneNumber)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {touched.phoneNumber && errors.phoneNumber && (
                    <Text variant="caption" color={colors.error}>
                      {errors.phoneNumber}
                    </Text>
                  )}
                </div>

                <div className={styles.InputWrapper}>
                  <Input
                    name="password"
                    placeholder="Your Password"
                    label="Enter Password"
                    type={isPasswordVisible ? "text" : "password"}
                    inputAdornment={
                      isPasswordVisible ? <EyeClosed /> : <EyeOpen />
                    }
                    onClickAdornment={() =>
                      setIsPasswordVisible(!isPasswordVisible)
                    }
                    id="password"
                    value={values.password}
                    error={!!(touched.password && errors.password)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {touched.password && errors.password && (
                    <Text variant="caption" color={colors.error}>
                      {errors.password}
                    </Text>
                  )}
                </div>
                {/* <div style={{ marginBottom: "24px" }} className={styles.InputWrapper}>
            <Input
              placeholder="Password"
              label="Confirm Password"
              type={isPasswordVisible ? "text" : "password"}
              inputAdornment={isPasswordVisible ? <EyeClosed /> : <EyeOpen />}
              onClickAdornment={() => setIsPasswordVisible(!isPasswordVisible)}
            />
          </div> */}

                <div
                  style={{ marginBottom: "24px" }}
                  className={styles.InputWrapper}
                >
                  <Input
                    name="referralCode"
                    label="Enter Referral Code (optional)"
                    id="referralCode"
                    value={values.referralCode}
                    error={!!(touched.referralCode && errors.referralCode)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {touched.referralCode && errors.referralCode && (
                    <Text variant="caption" color={colors.error}>
                      {errors.referralCode}
                    </Text>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    marginBottom: "40px",
                  }}
                >
                </div>
                <Button isLoading={isLoading} onClick={handleSubmit}>
                  Sign up
                </Button>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "30px",
                    width: "100%",
                    background: colors.primary + "95",
                  }}
                >
                  <Text
                    color={colors.grey1}
                    style={{ fontSize: "22px", marginRight: "5px" }}
                  >
                    Already Have A UBELA Account?
                  </Text>
                  <button onClick={() => navigate("/app/login")}>
                    <Text
                      color={colors.white}
                      fontWeight="medium"
                      style={{ fontSize: "22px" }}
                    >
                      Log in
                    </Text>
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </AuthContainer>
  );
}

export default Signup;
