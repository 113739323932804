import React, { useState } from "react";
import styles from "./ViewTopics.module.scss";
import PageHeader from "../../UI/molecules/PageHeader";
import { useGetTopicsByGradeQuery } from "../../../redux/features/lessons/lessonsApiSlice";
import { StringHelper } from "../../../utils/stringHelper";
import { grades, subjects } from "../../../constants/values";
import EnglishIcon from "../../../assets/icons/englishIcon.svg";
import MathIcon from "../../../assets/icons/mathIcon.svg";
import ScienceIcon from "../../../assets/icons/scienceIcon.svg";
import { ReactComponent as Dropdown } from "../../../assets/icons/dropdown.svg";
import Text from "../../UI/atoms/Text";
import {
  Modal,
  ModalContent,
  ModalOverlay,
  Skeleton,
  useDisclosure,
} from "@chakra-ui/react";
import Star from "../../../assets/icons/Star";
import Tag from "../../UI/atoms/Tag";
import { colors } from "../../../sdk/colors";
import TopicItem from "../../UI/molecules/TopicItem";
import { ReactComponent as EmptyImage } from "../../../assets/images/emptyImage.svg";
import Button from "../../UI/atoms/Button";
import { useNavigate } from "react-router-dom";
import { useGetProfilesQuery } from "../../../redux/features/profile/profileApiSlice";

function ViewTopics({
  onClose,
  info,
}: {
  onClose: () => void;
  info: { subjectId: number; grade: number };
}) {
  const navigate = useNavigate();

  const { data: profiles } = useGetProfilesQuery(undefined);
  const [gradeValue, setGradeValue] = useState<number>(info.grade);
  const {
    data: topics,
    error: errorTbg,
    isLoading: isTopicsLoading,
    isSuccess: isGetTopicsSuccess,
  } = useGetTopicsByGradeQuery(
    {
      subjectId: Number(info.subjectId),
      grade: StringHelper.gradeNumberToString(gradeValue),
    },
    { refetchOnMountOrArgChange: true }
  );

  const {
    isOpen: isGradeOpen,
    onOpen: onGradeOpen,
    onClose: onGradeClose,
  } = useDisclosure();

  const {
    isOpen: isProfileLimitModalOpen,
    onOpen: onProfileLimitOpen,
    onClose: onProfileLimitClose,
  } = useDisclosure();

  let subjectIcon: JSX.Element | undefined;

  const subjectName = subjects.find(
    (subject) => subject.id === info.subjectId
  )?.name;

  switch (subjectName) {
    case "english":
      subjectIcon = <img src={EnglishIcon} alt="" />;
      break;
    case "mathematics":
      subjectIcon = <img src={MathIcon} alt="" />;
      break;
    case "science":
      subjectIcon = <img src={ScienceIcon} alt="" />;
      break;
    default:
      subjectIcon = <img src={EnglishIcon} alt="" />;
  }

  const handleCreateProfile = () => {
    if (!!profiles) {
      if (profiles?.length < 3) {
        navigate("/app/guardian/create-profile");
      } else {
        onProfileLimitOpen();
      }
    }
  };
  return (
    <>
      <div className={styles.ViewTopics}>
        <div>
          <PageHeader
            handleGoBack={onClose}
            pageTitle=""
            rightComponent={<div />}
          />
          <div className={styles.Container}>
            <div className={styles.SubjectHeader}>
              <div className={styles.HeaderWrapper}>
                {subjectIcon}
                <Text
                  variant="heading 3"
                  fontWeight="light"
                  style={{ textTransform: "capitalize" }}
                >
                  {subjectName}
                </Text>
              </div>
              <Tag onClick={onGradeOpen}>
                <Text
                  style={{
                    fontSize: "12px",
                    fontWeight: 500,
                    textAlign: "center",
                  }}
                >
                  {grades[gradeValue - 1].label}
                </Text>
                <Dropdown />
              </Tag>
            </div>
            <div className={styles.TopicsWrapper}>
              {topics?.data.map((topic, index) => (
                <TopicItem
                  disabled
                  index={index + 1}
                  key={topic.id}
                  topic={topic}
                  count={0}
                />
              ))}
              {isTopicsLoading && (
                <>
                  <Skeleton
                    isLoaded={false}
                    borderRadius="10px"
                    className={styles.TopicSkeleton}
                    startColor={colors.grey5}
                    endColor={colors.grey4}
                  />
                  <Skeleton
                    isLoaded={false}
                    borderRadius="10px"
                    className={styles.TopicSkeleton}
                    startColor={colors.grey5}
                    endColor={colors.grey4}
                  />
                  <Skeleton
                    isLoaded={false}
                    borderRadius="10px"
                    className={styles.TopicSkeleton}
                    startColor={colors.grey5}
                    endColor={colors.grey4}
                  />
                  <Skeleton
                    isLoaded={false}
                    borderRadius="10px"
                    className={styles.TopicSkeleton}
                    startColor={colors.grey5}
                    endColor={colors.grey4}
                  />
                  <Skeleton
                    isLoaded={false}
                    borderRadius="10px"
                    className={styles.TopicSkeleton}
                    startColor={colors.grey5}
                    endColor={colors.grey4}
                  />
                  <Skeleton
                    isLoaded={false}
                    borderRadius="10px"
                    className={styles.TopicSkeleton}
                    startColor={colors.grey5}
                    endColor={colors.grey4}
                  />
                </>
              )}
            </div>
            {isGetTopicsSuccess && topics?.data?.length === 0 ? (
              <div className={styles.Empty}>
                <EmptyImage style={{ height: "250px", width: "250px" }} />
                <Text variant="heading 2">Nothing to see here</Text>
              </div>
            ) : undefined}
          </div>
        </div>
        <Button
          onClick={handleCreateProfile}
          buttonStyles={{ margin: "0 auto", maxWidth: "1000px" }}
        >
          Subscribe
        </Button>
      </div>
      <Modal isOpen={isGradeOpen} onClose={onGradeClose}>
        <ModalOverlay />
        <ModalContent
          style={{ maxWidth: "500px", marginLeft: "30px", marginRight: "30px" }}
        >
          <div className={styles.GradeWrapper}>
            {grades.map((grade, index) => (
              <button
                onClick={() => {
                  setGradeValue(grade.value);
                  onGradeClose();
                }}
                key={index}
                className={styles.GradeItem}
              >
                <Star color={grade.color} />
                <Text>{grade.label}</Text>
              </button>
            ))}
          </div>
        </ModalContent>
      </Modal>
      <Modal isOpen={isProfileLimitModalOpen} onClose={onProfileLimitClose}>
        <ModalOverlay />
        <ModalContent borderRadius="0px" marginLeft="20px" marginRight="20px">
          <div className={styles.ProfileLimitModal}>
            <Text style={{ textAlign: "center" }}>
              Your profile creation limit has been reached.
            </Text>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ViewTopics;
