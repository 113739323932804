import React from "react";
import styles from "./TopicItem.module.scss";
import { Topic } from "../../../../models/lessons";
import Text from "../../atoms/Text";
import { colors } from "../../../../sdk/colors";
import Tag from "../../atoms/Tag";
import { StringHelper } from "../../../../utils/stringHelper";
import { createSearchParams, useNavigate } from "react-router-dom";
import TopicImage1 from "../../../../assets/images/topImage.webp";
import TopicImage2 from "../../../../assets/images/topicImage2.webp";

function TopicItem({
  index,
  topic,
  count,
  disabled = false,
  subjectId,
}: {
  index: number;
  topic: Topic;
  count: number;
  disabled?: boolean;
  subjectId?: number;
}) {
  const navigate = useNavigate();
  let image: JSX.Element;
  if (index === 1) {
    image = <img id={styles.Media} src={TopicImage1} />;
  } else if (index === 2) {
    image = <img id={styles.Media} src={TopicImage2} />;
  } else if (index % 2) {
    image = <img id={styles.Media} src={TopicImage2} />;
  } else {
    image = <img id={styles.Media} src={TopicImage1} />;
  }
  return (
    <button
      disabled={disabled}
      onClick={() =>
        navigate({
          pathname: `/app/learn/topics/${topic.id}`,
          search: createSearchParams({ subjectId: `${subjectId}` }).toString(),
        })
      }
      className={styles.TopicItem}
    >
      <div className={styles.Wrapper}>
        {image}
        <div className={styles.TextWrapper}>
          <Text
            fontWeight="light"
            style={{ color: colors.grey3, marginBottom: "10px " }}
            variant="heading 5"
          >{`Topic ${index}`}</Text>
          <Text>{topic.name}</Text>
        </div>
      </div>

      {/* <Tag>{`${StringHelper.pluralize(count, "video")}`}</Tag> */}
    </button>
  );
}

export default TopicItem;
