import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { StoredProfile } from "../../../models/profile";
import { mergeProfiles } from "../../../utils/removeCopies";

const storedProfiles = localStorage.getItem("profiles");

export interface ProfileState {
  selectedPlan: string;
  profiles: StoredProfile[];
}

const initialState: ProfileState = {
  selectedPlan: "",
  profiles: storedProfiles ? JSON.parse(storedProfiles) : [],
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    handleSelectedPlans: (state, action: PayloadAction<string>) => {
      state.selectedPlan = action.payload;
    },
    handleStoreProfiles: (state, action: PayloadAction<StoredProfile[]>) => {
      state.profiles = action.payload;
      localStorage.setItem("profiles", JSON.stringify(action.payload));
    },
  },
});

export const { handleSelectedPlans, handleStoreProfiles } =
  profileSlice.actions;

export default profileSlice.reducer;
