import React, { useLayoutEffect } from "react";
import styles from "./Terms.module.scss";
import HeroBackground from "../../assets/images/HeroBackground.svg";
import MobileHeroBackground from "../../assets/images/MobileHeroBackground.svg";
import HeroDecor1 from "../../assets/images/heroDecor1.svg";
import HeroImage1 from "../../assets/images/heroImage1.svg";
import HeroImage2 from "../../assets/images/heroImage2.svg";
import HeroImage3 from "../../assets/images/heroImage3.svg";
import { motion } from "framer-motion";
import Header from "../../components/UI/molecules/LandingPageHeader";
import Footer from "../../components/UI/molecules/Footer";
import { Helmet } from "react-helmet";

function Terms() {
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className={styles.Container}>
      <Helmet>
        <title>UBELA - Terms of Use</title>
      </Helmet>
      <Header />
      <div className={styles.HeroSection}>
        <img
          src={HeroBackground}
          alt=""
          style={{ position: "absolute", opacity: 0.1, zIndex: 0, top: 0 }}
          id={styles.HeroBackground}
        />
        <img
          src={HeroBackground}
          alt=""
          style={{
            position: "absolute",
            opacity: 0.1,
            zIndex: 0,
            left: 1500,
            top: 0,
          }}
          id={styles.HeroBackground}
        />
        <img
          src={MobileHeroBackground}
          alt=""
          style={{ position: "absolute", opacity: 0.1, zIndex: 0, top: 0 }}
          id={styles.MobileHeroBackground}
        />

        <motion.div
          id={styles.HeroImage1}
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: "easeOut", delay: 0.4 }}
        >
          <img src={HeroImage1} alt="" />
        </motion.div>
        <motion.div
          id={styles.HeroImage2}
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: "easeOut", delay: 0.1 }}
        >
          <img src={HeroImage2} alt="" />
        </motion.div>
        <motion.div
          id={styles.HeroImage3}
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: "easeOut", delay: 0.4 }}
        >
          <img src={HeroImage3} alt="" />
        </motion.div>
        <div className={styles.TermsSection}>
          <div className={styles.ContentWrapper}>
            <h1>Terms of Use</h1>
            <div>
              <div>
                <h3>INTRODUCTION</h3>
                <p>
                  Please read these Terms of Use carefully before using the
                  UBELA (“Platform”). Customers who are under 18 years of age
                  should review these terms and conditions with a parent or
                  guardian. Your access to and use of the Platform by way of any
                  Device is conditioned on your acceptance of and compliance
                  with the provisions of this Terms of Use. By your accessing or
                  using the Platform you agree to be bound by these Terms of Use
                  as amended at any time and posted on the website
                  www.ubelalearn.com If you disagree with any part hereof, do
                  not access the Platform. In addition, your use of the Platform
                  shall be subject to any applicable regulatory guidelines or
                  rules. All such guidelines or rules are hereby incorporated by
                  reference into the Terms of Use.
                </p>
              </div>
              <div>
                <h3>DESCRIPTION OF THE UBELA PLATFORM</h3>
                <p>
                  The Platform is an education service application that
                  showcases lesson videos that covers topics in some subjects at
                  primary school level. The platform allows users to watch
                  lessons online and presents users with multiple choice
                  practice questions. The Lesson videos are free but with
                  limited access when an individual signs up on the Platform.
                  Unlimited access to the lesson videos and other features would
                  require a user to be a paid subscriber.lo
                </p>
              </div>
              <div>
                <h3 className="font-poppins font-medium text-2xl">
                  SUSPENSION OF ACCESS TO PLATFORM
                </h3>
                <p>
                  We hereby reserve the right to suspend, restrict, or terminate
                  your access to the Platform (in whole or in part) without any
                  notice or liability in the following circumstances:
                  <ul>
                    <li>
                      Upon receipt of instructions from you to suspend or
                      terminate your access to the Platform
                    </li>
                    <li>
                      If we suspect the Platform is being used for fraudulent or
                      other illegal activities.
                    </li>
                    <li>
                      If we are of the opinion that you have breached a
                      provision contained in this Terms of Use.
                    </li>
                    <li>
                      If we are of the opinion that continued access to the
                      Platform will be detrimental to our business.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Terms;
