import React from "react";

function DashboardHome({ color }: { color: React.CSSProperties["color"] }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 21.0004C4.08333 21.0004 3.72933 20.8544 3.438 20.5624C3.146 20.2711 3 19.9171 3 19.5004V17.6004L7 14.0504V21.0004H4.5ZM8 21.0004V17.0004H16V21.0004H8ZM17 21.0004V12.8004L12.725 9.00039L15.75 6.32539L20.5 10.5504C20.6667 10.7004 20.7917 10.8711 20.875 11.0624C20.9583 11.2544 21 11.4587 21 11.6754V19.5004C21 19.9171 20.854 20.2711 20.562 20.5624C20.2707 20.8544 19.9167 21.0004 19.5 21.0004H17ZM3 16.2504V11.6754C3 11.4587 3.04167 11.2504 3.125 11.0504C3.20833 10.8504 3.33333 10.6837 3.5 10.5504L11 3.90039C11.1333 3.76706 11.2877 3.67106 11.463 3.61239C11.6377 3.55439 11.8167 3.52539 12 3.52539C12.1833 3.52539 12.3627 3.55439 12.538 3.61239C12.7127 3.67106 12.8667 3.76706 13 3.90039L15 5.67539L3 16.2504Z"
        fill={color}
      />
    </svg>
  );
}

export default DashboardHome;
