import React, { useState } from "react";
import PageHeader from "../../components/UI/molecules/PageHeader";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./Quiz.module.scss";
import { useGetLessonQuery } from "../../redux/features/lessons/lessonsApiSlice";
import Text from "../../components/UI/atoms/Text";
import { ReactComponent as QuestionMark } from "../../assets/icons/questionMarkIcon.svg";
import { colors } from "../../sdk/colors";
import AwardIcon from "../../assets/icons/awardIcon.svg";
import HandChat from "../../assets/icons/handChat.svg";
import TrophyAward from "../../assets/icons/trophyAward.svg";
import Button from "../../components/UI/atoms/Button";
import Questions from "../../components/organisms/Questions";

function Quiz() {
  const params = useParams();
  const navigate = useNavigate();
  const { isLoading, data, isSuccess } = useGetLessonQuery(
    Number(params.topicId)
  );
  const [quizState, setQuizState] = useState<"intro" | "quiz">("intro");
  return (
    <div className={styles.Quiz}>
      <PageHeader
        pageTitle={`${data?.data?.title || "Quiz"}`}
        handleGoBack={() => navigate(-1)}
      />
      {quizState === "intro" ? (
        <div className={styles.Container}>
          <div className={styles.Top}>
            <Text fontWeight="light" color={colors.grey2}>
              Questions
            </Text>
            <div className={styles.Wrapper}>
              <QuestionMark style={{ marginRight: "8px" }} />
              <Text variant="heading 4" fontWeight="medium">
                {data?.data?.quizzes?.length}
              </Text>
            </div>
          </div>
          <div className={styles.Info}>
            <Text fontWeight="light" variant="heading 4">
              Instruction
            </Text>
            <div className={styles.InfoWrapper}>
              <div className={styles.InfoItem}>
                <img
                  src={AwardIcon}
                  style={{ height: "24px", width: "24px", marginRight: "24px" }}
                />
                <Text style={{ fontSize: "12px" }} color={colors.gray}>
                  1.0 mark is awarded for correct answers and 0 marks for
                  incorrect answers
                </Text>
              </div>
              <div className={styles.InfoItem}>
                <img
                  src={HandChat}
                  style={{ height: "24px", width: "24px", marginRight: "24px" }}
                />
                <Text style={{ fontSize: "12px" }} color={colors.gray}>
                  If you don’t earn a badge this time, you can retake the test
                  again.
                </Text>
              </div>
              <div className={styles.InfoItem}>
                <img
                  src={TrophyAward}
                  style={{ height: "24px", width: "24px", marginRight: "24px" }}
                />
                <Text style={{ fontSize: "12px" }} color={colors.gray}>
                  The more you give the correct answer, you gat chance to win a
                  badge.
                </Text>
              </div>
            </div>
            <Button onClick={() => setQuizState("quiz")}>Start Quiz</Button>
          </div>
        </div>
      ) : isSuccess ? (
        <div style={{ marginTop: "30px" }} className={styles.Container}>
          <Questions variant="lesson" questions={data?.data?.quizzes || []} />
        </div>
      ) : undefined}
    </div>
  );
}

export default Quiz;
