import React from "react";

function DashboardLessonsIcon({
  color,
}: {
  color: React.CSSProperties["color"];
}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 6C2.45 6 2 6.45 2 7V20C2 21.1 2.9 22 4 22H17C17.55 22 18 21.55 18 21C18 20.45 17.55 20 17 20H5C4.45 20 4 19.55 4 19V7C4 6.45 3.55 6 3 6ZM20 2H8C6.9 2 6 2.9 6 4V16C6 17.1 6.9 18 8 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM12 14.5V5.5L17.47 9.6C17.74 9.8 17.74 10.2 17.47 10.4L12 14.5Z"
        fill={color}
      />
    </svg>
  );
}

export default DashboardLessonsIcon;
