import React from "react";

function SmallNextButtonIcon({ disabled = false }: { disabled?: boolean }) {
  return (
    <svg
      width="93"
      height="47"
      viewBox="0 0 93 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_di_80423_59)">
        <path
          d="M89 20.5448C89 13.7853 83.8405 8.14363 77.1079 7.54124L18.219 2.27223C10.5799 1.58873 4 7.60616 4 15.2758V27.2838C4 34.845 10.4032 40.825 17.9468 40.3089L76.8356 36.2797C83.6841 35.8111 89 30.1191 89 23.2546V20.5448Z"
          fill="url(#paint0_linear_80423_59)"
          shapeRendering="crispEdges"
        />
        <path
          d="M77.0498 8.19142L18.1609 2.92241C10.9037 2.27308 4.65278 7.98965 4.65278 15.2758V27.2838C4.65278 34.467 10.7358 40.148 17.9022 39.6576L76.7911 35.6284C83.2971 35.1833 88.3472 29.7758 88.3472 23.2546V20.5448C88.3472 14.1233 83.4457 8.76369 77.0498 8.19142Z"
          stroke="url(#paint1_linear_80423_59)"
          strokeWidth="1.30556"
          shapeRendering="crispEdges"
        />
      </g>
      <defs>
        <filter
          id="filter0_di_80423_59"
          x="0.0833333"
          y="0.923326"
          width="95.8333"
          height="47.674"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.61111" />
          <feGaussianBlur stdDeviation="1.95833" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_80423_59"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_80423_59"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5.22222" />
          <feGaussianBlur stdDeviation="2.61111" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_80423_59"
          />
        </filter>
        <linearGradient
          id="paint0_linear_80423_59"
          x1="87.7257"
          y1="1.00003"
          x2="-4.52578"
          y2="10.3614"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={disabled ? "#00000040" : "#1CEB59"} />
          <stop
            offset="1"
            stopColor={disabled ? "#00000040" : "#4EE279"}
            stopOpacity="0.73"
          />
        </linearGradient>
        <linearGradient
          id="paint1_linear_80423_59"
          x1="105.577"
          y1="88.7059"
          x2="-7.92539"
          y2="72.9493"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={disabled ? "#00000040" : "#22B14C"} />
          <stop
            offset="1"
            stopColor={disabled ? "#00000040" : "#69C217"}
            stopOpacity="0.3"
          />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SmallNextButtonIcon;
