import React from "react";
import styles from "./LeaderBoardItem.module.scss";
import { Profile } from "../../../../models/profile";
import Text from "../../atoms/Text";
import Trophy from "../../../../assets/icons/Trophy";
import { colors } from "../../../../sdk/colors";
import defaultAvatar from "../../../../assets/images/defaultAvatar.png";

function LeaderBoardItem({
  index,
  profile,
  score,
  isUser,
}: {
  index: number;
  profile: Profile;
  score: string;
  isUser?: boolean;
}) {
  let trophyColor: React.CSSProperties["color"];
  switch (index) {
    case 1:
      trophyColor = colors.warning;
      break;
    case 2:
      trophyColor = "#C0C0C0";
      break;
    case 3:
      trophyColor = "#CD7F32";
      break;
    default:
      trophyColor = "#C8B6C6";
  }
  return (
    <div
      style={{
        border: isUser ? `1px solid ${colors.primary}` : "0.5px solid #c8b6c6",
      }}
      className={styles.LeaderBoardItem}
    >
      <div className={styles.Wrapper}>
        <Text
          fontWeight="medium"
          style={{ fontSize: "20px", color: colors.primary }}
        >
          {index}
        </Text>
        <img
          src={profile?.avatar?.url || defaultAvatar}
          className={styles.Avatar}
          alt=""
        />
        <div className={styles.TextWrapper}>
          <Text
            style={{ marginBottom: "3px" }}
          >{`${profile.firstName} ${profile.lastName}`}</Text>
          <Text
            style={{ fontSize: "12px", color: colors.grey2 }}
            id={styles.SubText}
          >
            Overall points: <span id={styles.Score}>{score}</span>
          </Text>
        </div>
      </div>
      <Trophy color={trophyColor} />
    </div>
  );
}

export default LeaderBoardItem;
