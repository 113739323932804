import React, { useLayoutEffect, useState } from "react";
import styles from "./FAQ.module.scss";
import HeroBackground from "../../assets/images/HeroBackground.svg";
import MobileHeroBackground from "../../assets/images/MobileHeroBackground.svg";
import HeroDecor1 from "../../assets/images/heroDecor1.svg";
import HeroImage1 from "../../assets/images/heroImage1.svg";
import HeroImage2 from "../../assets/images/heroImage2.svg";
import HeroImage3 from "../../assets/images/heroImage3.svg";
import { motion } from "framer-motion";
import Header from "../../components/UI/molecules/LandingPageHeader";
import Footer from "../../components/UI/molecules/Footer";
import { faqs } from "../../constants/values";
import { Helmet } from "react-helmet";
import Arrow from "../../assets/images/outwardArrow.svg";
import { Link } from "react-router-dom";

const FAQItem = ({
  question,
  answer,
}: {
  question: string;
  answer: string;
}) => {
  const [showAnswer, setShowAnswer] = useState(false);

  const toggleAnswer = () => {
    setShowAnswer(!showAnswer);
  };

  return (
    <div className={styles.FAQItem}>
      <div className={styles.Question} onClick={toggleAnswer}>
        {question}
        <img
          src={Arrow}
          style={{ transform: showAnswer ? "rotate(-90deg)" : "rotate(0)" }}
          id={styles.Arrow}
        />
      </div>
      {showAnswer && (
        <motion.div
          className={styles.Answer}
          initial={{ opacity: 0, y: -40 }}
          animate={{ opacity: 1, y: 0 }}
        >
          {answer}
        </motion.div>
      )}
    </div>
  );
};

function FAQ() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.Container}>
      <Helmet>
        <title>UBELA - FAQ</title>
      </Helmet>
      <Header />
      <div className={styles.HeroSection}>
        <div className={styles.TextAnimation}>
          <motion.div
            className=""
            initial={{ x: 200, opacity: 0, scale: 0.5 }}
            animate={{ x: 60, opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, repeat: Infinity, repeatDelay: 1.5 }}
          >
            <p>Sing.</p>
          </motion.div>
          <motion.div
            className=""
            initial={{ x: 200, opacity: 0, scale: 0.5 }}
            animate={{ x: 70, opacity: 1, scale: 1 }}
            transition={{ duration: 1, repeat: Infinity, repeatDelay: 1 }}
          >
            <p>Laugh.</p>
          </motion.div>
          <motion.div
            className=""
            initial={{ x: 200, opacity: 0, scale: 0.5 }}
            animate={{ x: 80, opacity: 1, scale: 1 }}
            transition={{ duration: 1.5, repeat: Infinity, repeatDelay: 0.5 }}
          >
            <p>Learn </p>
          </motion.div>
        </div>
        <img
          src={HeroBackground}
          alt=""
          style={{ position: "absolute", opacity: 0.1, zIndex: 0, top: 0 }}
          id={styles.HeroBackground}
        />
        <img
          src={HeroBackground}
          alt=""
          style={{
            position: "absolute",
            opacity: 0.1,
            zIndex: 0,
            left: 1500,
            top: 0,
          }}
          id={styles.HeroBackground}
        />
        <img
          src={MobileHeroBackground}
          alt=""
          style={{ position: "absolute", opacity: 0.1, zIndex: 0, top: 0 }}
          id={styles.MobileHeroBackground}
        />
        <div className={styles.HeroContent}>
          <h1>FAQ</h1>
          <p>Find answers from our support team or get in touch</p>
        </div>
        <motion.div
          id={styles.HeroImage1}
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: "easeOut", delay: 0.4 }}
        >
          <img src={HeroImage1} alt="" />
        </motion.div>
        {/* <motion.div
          id={styles.HeroImage2}
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: "easeOut", delay: 0.1 }}
        >
          <img src={HeroImage2} alt="" />
        </motion.div> */}
        <motion.div
          id={styles.HeroImage3}
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: "easeOut", delay: 0.4 }}
        >
          <img src={HeroImage3} alt="" />
        </motion.div>
      </div>
      <div className={styles.FAQSection}>
        {faqs.map((item, index) => (
          <FAQItem key={index} question={item.question} answer={item.answer} />
        ))}
        <div className={styles.GetInTouch}>
          <h2>Didn’t find an answer to your question?</h2>
          <p>Get in touch with us for details on additional services</p>
          <Link to="/help">
            <button>Contact us</button>
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default FAQ;
